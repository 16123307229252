import React, { Component, useState, useEffect } from "react";
import IntlMessages from "util/intlMessages";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
  ListGroup,
  ListGroupItem
} from "reactstrap";
const BusinessWidget = props => {
  useEffect(() => {
    props.BusinessList();
  }, []);
  const [tooltipOpenBusinessList, settoolTipOpenBusinessList] = useState(false);
  const [tooltipOpen, settTooltipOpen] = useState(false);
  function onHoverBusinessStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
    document.getElementById(div).style.cursor = "pointer";
    // e.target.style.background = "#f5f5f5";
  }
  function onMouseBusinessLeave(div) {
    document.getElementById(div).style.background = "#fff";
    // e.target.style.background = "#fff";
  }
  return (
    <div className="mb-15 bold-text text-center">
      <span className="text-muted">
        <IntlMessages id={"Current business"} />
        {" : "}
      </span>

      <span
        className="align-center"
        onMouseEnter={() => onHoverBusinessStyle(`business${1}`)}
        onMouseLeave={() => onMouseBusinessLeave(`business${1}`)}
        style={{ wordWrap: "break-word" }}
      >
        {props.currentBusinessName
          ? props.currentBusinessName.name
          : "fetching"}
        <Tooltip
          placement="top"
          isOpen={tooltipOpen}
          autohide={false}
          target={`business${1}`}
          toggle={() => settTooltipOpen(!tooltipOpen)}
        >
          <IntlMessages id="click to change business" />
        </Tooltip>
      </span>
      <span onClick={props.ToggleBusinessModal} id={`business${1}`}>
        {" "}
        <i className="fa fa-edit text-muted pl-2 fs-15"></i>
      </span>
    </div>
    //   <div className="border p-3 mt-2 mlr-20">
    //     <div className="row fs-17">
    //       <div
    //         className="col-sm-6 mb-4 mb-lg-0"
    //         id={`business${1}`}
    //         onMouseEnter={() => onHoverBusinessStyle(`business${1}`)}
    //         onMouseLeave={() => onMouseBusinessLeave(`business${1}`)}
    //         onClick={props.ToggleBusinessModal}
    //       >
    //         <div className="d-lg-flex justify-content-between align-items-center">
    //           <div className="fs-18 text-muted">Current business :</div>
    //         </div>
    //         <div>
    //           {props.currentBusinessName != null &&
    //           props.currentBusinessName.name ? (
    //             <>
    //               <span className="align-center">
    //                 {" "}
    //                 {props.currentBusinessName.name}
    //                 <Tooltip
    //                   placement="top"
    //                   isOpen={tooltipOpen}
    //                   autohide={false}
    //                   target={`business${1}`}
    //                   toggle={() => settTooltipOpen(!tooltipOpen)}
    //                 >
    //                   Click to Change Business
    //                 </Tooltip>
    //               </span>
    //               <i
    //                 className="fa fa-chevron-right float-right text-muted fs-22"
    //                 style={{ lineHeight: 0 }}
    //               ></i>{" "}
    //             </>
    //           ) : (
    //             "Fetching Business"
    //           )}
    //         </div>
    //       </div>
    //       <div
    //         className="col-sm-6 mb-4 mb-lg-0"
    //         id={`business${2}`}
    //         onMouseEnter={() => onHoverBusinessStyle(`business${2}`)}
    //         onMouseLeave={() => onMouseBusinessLeave(`business${2}`)}
    //         onClick={props.ToggleBusinessModal}
    //       >
    //         <div className="d-lg-flex justify-content-between align-items-center">
    //           <div className="fs-16 text-muted">Total Business :</div>
    //         </div>
    //         <div>
    //           {props.currentBusinessName != null ? (
    //             <>
    //               <span>{props.businessListArray.length}</span>
    //               <i
    //                 className="fa fa-chevron-right float-right text-muted fs-22"
    //                 style={{
    //                   lineHeight: 0,
    //                 }}
    //               ></i>{" "}
    //             </>
    //           ) : (
    //             "Fetcing"
    //           )}
    //           <Tooltip
    //             placement="top"
    //             isOpen={tooltipOpenBusinessList}
    //             autohide={false}
    //             target={`business${2}`}
    //             toggle={() =>
    //               settoolTipOpenBusinessList(!tooltipOpenBusinessList)
    //             }
    //           >
    //             Click to View Business
    //           </Tooltip>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
  );
};
export default BusinessWidget;
