import customerListAction from "./actions";

const initState = {
  businessId: null,
  customers: null,
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case customerListAction.setCustomers:
      return {
        ...state,
        businessId: action.businessId,
        customers: action.customers,
      };
    case customerListAction.unSetCustomers:
      return {
        ...state,
        businessId: null,
        customers: null,
      };
    default:
      return state;
  }
}
