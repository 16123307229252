/* TODO:CHECKHEADERS
    check if token header is present if present then return token with header else rediirect to login page
*/
export default function getHeader(propsToken) {
  const token = JSON.parse(getAuth().auth).accessToken;
  if (token !== null && token !== undefined) {
    return {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    if (propsToken) {
      return {
        "Content-Type": "application/json",
        Authorization: `Bearer ${propsToken}`,
      };
    }
  }
}
function getAuth() {
  const auth = JSON.parse(localStorage.getItem("persist:root"));
  return auth;
}
