import Login from "views/pages/authentication/Login";
import VerificationOtp from "views/pages/authentication/VerificationOtp";
import AddProfile from "views/pages/newUser/addProfile";
const publicRoutesLogin = [
  { path: "/login", component: Login },
  { path: "/verify", component: VerificationOtp },
  { path: "/complete-your-profile", component: AddProfile },
];

export default publicRoutesLogin;
