const authActions = {
  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
  UPDATE_AUTH_LANGUAGE: "UPDATE_AUTH_LANGUAGE",

  login: (data) => {
    return {
      type: authActions.LOGIN,
      isLogin: true,
      accessToken: data.token,
      user: data.user,
    };
  },
  logout: () => {
    return {
      type: authActions.LOGOUT,
      isLogin: false,
      accessToken: null,
      user: null,
    };
  },
  update_language: (language) => {
    return {
      type: authActions.UPDATE_AUTH_LANGUAGE,
      language: language,
    };
  },
};

export default authActions;
