import React, { Component, useState, useEffect } from "react";
import IntlMessages from "util/intlMessages";
import {
  selected,
  Notselected,
  appLogo,
  appLogoCleaner
} from "../../../helper/constant";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
  ListGroup,
  ListGroupItem
} from "reactstrap";
const ListBusiness = props => {
  const customStyles = {
    paddingTop: "15%",
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  const handleonClick = () => {
    props.toggle();
    props.ToggleAddBusinessModal();
  };
  return (
    <Modal
      isOpen={props.businessmodal}
      toggle={props.toggle}
      style={{ marginTop: "12%" }}
    >
      <ModalHeader toggle={props.toggle}>
        <IntlMessages id={"Business list"} />
      </ModalHeader>
      <ModalBody>
        <div
          className=""
          style={{
            overflow: " scroll",
            height: " 300px"
          }}
        >
          <ListGroup>
            <p className="text-muted mb-1 pl-1">
              {props.businessListArray ? props.businessListArray.length : ""}
              {props.businessListArray && props.businessListArray.length > 1 ? (
                <IntlMessages id={"Businesses"} />
              ) : (
                <IntlMessages id={"Business"} />
              )}
            </p>
            {props.businessListArray &&
              props.businessListArray.map(business => {
                return (
                  <ListGroupItem
                    onClick={() => props.changeBusiness(business)}
                    tag="a"
                    key={business.id}
                    action
                  >
                    <span style={{ wordWrap: "break-word" }}>
                      <img
                        style={{
                          height: "20px",
                          width: "20px",
                          paddingRight: 3
                        }}
                        src={appLogoCleaner}
                      ></img>
                      {business.name}
                    </span>
                    <img
                      style={{
                        float: "right",
                        height: "15px",
                        width: "15px"
                      }}
                      src={
                        props.currentBusinessName &&
                        business.id == props.currentBusinessName.id
                          ? selected
                          : Notselected
                      }
                      height="10px"
                      width="10px"
                    />
                  </ListGroupItem>
                );
              })}
          </ListGroup>
        </div>
      </ModalBody>
      <ModalFooter
        style={{
          display: "center !important",
          paddingTop: 10,
          justifyContent: "center"
        }}
      >
        <Button
          // type="submit"
          className="btn button"
          onClick={handleonClick}
          style={{
            background: "#0073e6",
            color: "#FFF",
            width: "100% !important"
          }}
        >
          <IntlMessages id="Add business" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export default ListBusiness;
