import styled from "styled-components";

export const StockSectionWrapper = styled.div`
  width: 50%;
  .section-container {
    display: flex;
    align-items: center;
  }
  .section-3 {
    flex-grow: 3;
  }
  .inline-flex {
    display: inline-flex;
  }
  .profile-image {
    border-radius: 50%;

    line-height: 2;
    width: 42px;
    height: 41px;
    text-align: center;
    object-fit: fill;
  }
  .profile {
    border-radius: 50%;
    background: #0073e6;
    line-height: 2;
    width: 42px;
    height: 41px;
    text-align: center;
    object-fit: fill;
  }
  .inCap {
    font-size: 20px;
    color: white;
  }
`;
export const StockInfoWrapper = styled.div`
  .vertical-hr {
    margin: 0px 20px;
    width: 1px;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;
