import React, { useState, useEffect } from "react";
import { loginBack, profile } from "helper/constant";
import {
  getCustomerProfile,
  getCustomerList
} from "../../helper/services/customerService";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { updateCustomerProfile } from "helper/services/customerService";
import IntlMessages from "util/intlMessages";
import Notifications, { notify } from "react-notify-toast";
import Loader from "components/loader/Loader";
import ImageUploading from "react-images-uploading";
// import enhancer from "../views/pages/authentication/enhancer/updateProfile";
import { useForm } from "react-hook-form";
import AuthActions from "redux/auth/actions";
import authActionUser from "redux/user/actions";
import customerListAction from "redux/customerList/actions";
import SelectCustomerAction from "redux/customer/actions";
const { login } = AuthActions;
const { setuser } = authActionUser;
const { setCustomers } = customerListAction;
const { selectCustomer } = SelectCustomerAction;

const CustomerProfile = props => {
  const [loader, setLoader] = useState(null);
  const [imageFlage, setImageFlag] = useState(false);
  const [images, setImages] = useState(null);
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    profile: "",
    address: ""
  });
  const [userProfile, setUserProfile] = useState(null);
  const [customerName, setCustomerName] = useState(null);
  const [customerEmail, setCustomerEmail] = useState(null);
  const [isSms, setIsSMS] = useState(false);
  const [isWhatsapp, setIsWhatsapp] = useState(false);
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [CustomerAddress, setCustomerAddress] = useState(null);
  const [customerNumber, setCustomerNumber] = useState(null);
  useEffect(() => {
    getUserData();
  }, []);

  const [selectedImage, setSelectedImage] = useState(null);
  const { touched } = props;
  const { register, handleSubmit, errors } = useForm();
  const handleChange = e => {
    // console.log("id-", e.target.id);

    switch (e.target.id) {
      case "customerName": {
        setCustomerName(e.target.value);
        break;
      }
      case "email": {
        setCustomerEmail(e.target.value);
        break;
      }
      case "address": {
        setCustomerAddress(e.target.value);
        break;
      }
      default: {
      }
    }
  };

  const getUserData = async () => {
    try {
      //   console.log("customer data", props.history.location.state);
      const customerData = props.SelectCustomerAction.customer;
      if (customerData) {
        setCustomerName(customerData.name);
        setCustomerEmail(customerData.email || " ");
        setCustomerAddress(customerData.address || " ");
        if (customerData.customerMeta && customerData.customerMeta.length > 0) {
          setUserProfile(customerData.customerMeta[0].url);
          setSelectedImage({
            data_url: customerData.customerMeta[0].url
          });
        }
        setCustomerNumber("+91 " + customerData.phoneNumber);
        setIsWhatsapp(customerData.isWhatsappModeEnable);
        setIsSMS(customerData.isSMSModeEnable);
        setDisabledStatus(false);
      } else {
        props.history.push("/home");
      }
      //   if (userResponse) {
      //     setDisabledStatus(false);
      //     setLoader(false);
      //     const user = userResponse.user;
      //     // console.log("Response in user profile", user);
      //     setcustomerName(user.contactPersonName);
      //     setCustomerEmail(user.email);
      //     setUserMobileNumber(user.phoneNumber);
      //     setCustomerAddress(user.address);
      //     setUserProfile(user.userMeta);
      //     console.log(user.userMeta);
      //     if (user.userMeta.length > 0) {
      //       setSelectedImage({
      //         data_url: user.userMeta[0].thumbnail,
      //       });
      //     }
      //   }
    } catch (e) {
      console.log("error in fetching data", e);
      //   notify.show(e, "error", 5000);
      setLoader(false);
    }
  };

  const handleImageUpload = event => {
    setSelectedImage(event.target.files[0]);
    setUserProfile(event.target.files[0]);
  };
  // console.log("props", props);
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log("image List", imageList);
    setImages(imageList);
    setSelectedImage(imageList[0]);
    setImageFlag(true);
    setUserProfile(imageList[0]);
  };
  const loginContainer = {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };
  const handleUpdateProfile = async e => {
    setLoader(true);

    console.log("values", JSON.stringify(e));
    try {
      let profilePicture;
      if (selectedImage && imageFlage) {
        profilePicture = { profilePic: selectedImage.file };
      } else {
        profilePicture = { profilePic: "" };
      }
      console.log("data submitted to update", e);
      let response = await updateCustomerProfile({
        customerName: e.customerName,
        profilePic: profilePicture.profilePic,
        address: e.address,
        email: e.email,
        customerid: props.history.location.state.customerData.id,
        isSms,
        isWhatsapp
      });
      if (response) {
        console.log("updated", response.customer);
        // props.setCustomers({ customers: response.customer });
        props.selectCustomer({ customer: response.customer });
        if (response.errors) {
          notify.show(response.errors.message, "error", 5000);
          console.log("error ", response.errors.message);
        }
        //   const auth = JSON.parse(localStorage.getItem("persist:root"));
        //   const tokenDb = JSON.parse(auth.auth).accessToken;
        if (props.auth?.user?.language?.toLowerCase() === "english") {
          notify.show("Customer Profile Updated", "success", 5000);
        }
        if (props.auth?.user?.language?.toLowerCase() === "gujarati") {
          notify.show("ગ્રાહક પ્રોફાઇલ અપડેટ થઈ", "success", 5000);
        }
        if (props.auth?.user?.language?.toLowerCase() === "hindi") {
          notify.show("ग्राहक प्रोफाइल अपडेट किया गया", "success", 5000);
        }

        //   props.setuser({ user: response.user });
        //   props.login({ token: tokenDb, user: response.user });
        //   props.history.go(0);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
      console.log("error", e);
      notify.show(e, "error", 5000);
    }
  };

  const button = {
    fontSize: "16px",
    textTransform: "capitalize",
    boxShadow: "none!important",
    width: "100%",
    borderRadius: "2px",
    fontWeight: "500",
    background: "#0073e6",
    color: " white",
    paddingLeft: "10px",
    textAlign: "center"
  };
  const addImage = {
    position: "absolute",
    color: "green",
    bottom: "78%",
    left: "56%"
  };
  const uplodadButton = {
    position: "absolute"
  };
  const inputLabel = {
    background: "#ffff",
    borderColor: "gray",
    border: " 1px solid gray",
    borderRight: "0px"
  };
  const justify = {
    justifyContent: "center"
  };
  const inputStyle = {
    border: "1px solid grey",
    focus: "none",
    borderLeft: 0
  };
  return (
    <div>
      <div className="container">
        {/* <Notifications /> */}
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />

          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
            <section>
              <nav
                style={{
                  marginBottom: 10
                }}
                className="custom-breadcumb"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className="breadcumb-color breadcrumb-item">
                    <a
                      id={32}
                      style={{
                        color: "gray",
                        cursor: "pointer"
                      }}
                      onMouseEnter={() => {
                        document.getElementById(32).style.textDecoration =
                          "underline";
                      }}
                      onMouseLeave={() => {
                        document.getElementById(32).style.textDecoration =
                          "none";
                      }}
                      onClick={() => {
                        props.location.state &&
                        props.location.state.page &&
                        props.location.state.page
                          ? props.history.push("/account")
                          : props.history.push("/home");
                      }}
                    >
                      {/* {props.SelectCustomerAction?.customer.name}'s */}
                      {props.location.state &&
                      props.location.state.page &&
                      props.location.state.page ? (
                        <IntlMessages id="Account" />
                      ) : (
                        <IntlMessages id="Home" />
                      )}
                    </a>
                  </li>
                  <li className="breadcumb-color breadcrumb-item">
                    <a
                      id={33}
                      style={{
                        color: "gray",
                        cursor: "pointer"
                      }}
                      onMouseEnter={() => {
                        document.getElementById(33).style.textDecoration =
                          "underline";
                      }}
                      onMouseLeave={() => {
                        document.getElementById(33).style.textDecoration =
                          "none";
                      }}
                      onClick={() => props.history.goBack()}
                    >
                      {/* {props.SelectCustomerAction?.customer.name}'s */}
                      <IntlMessages id="customer" />
                    </a>
                  </li>
                  <li
                    className="active breadcrumb-item"
                    style={{
                      color: "#0073e6",
                      wordWrap: "break-word"
                    }}
                    aria-current="page"
                  >
                    <span>
                      <IntlMessages id="sidebar.profile" />
                    </span>
                  </li>
                </ol>
              </nav>
            </section>

            <div className="roe-card-style roe-shadow-2 whitelight mb-10">
              <div>
                <div>
                  <Notifications />
                  <div className=" ptb-15">
                    {" "}
                    <form
                      className="pa-24"
                      onSubmit={handleSubmit(handleUpdateProfile)}
                    >
                      <div className="login-icon image-item">
                        <ImageUploading
                          value={images}
                          onChange={onChange}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper text-center profile-image">
                              <img
                                style={{ objectFit: "fill" }}
                                src={
                                  selectedImage &&
                                  selectedImage.data_url &&
                                  userProfile
                                    ? selectedImage.data_url
                                    : profile
                                }
                                onClick={onImageUpload}
                                id="profilePicture"
                                alt="icon"
                                height="100px"
                              />

                              {imageList.length > 0 ? (
                                imageList.map((image, index) => (
                                  <div key={index} className="image-item">
                                    <div className="image-item__btn-wrapper">
                                      <a
                                        onClick={() => onImageUpdate(index)}
                                        style={{ padding: 10 }}
                                      >
                                        <i className="fa fa-edit" />
                                      </a>
                                      <a onClick={() => onImageRemove(index)}>
                                        <i className="fa fa-trash" />
                                      </a>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div key={1} className="image-item">
                                  <div className="image-item__btn-wrapper">
                                    <a
                                      onClick={onImageUpload}
                                      style={{ padding: 10 }}
                                    >
                                      <i className="fa fa-edit" />
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </ImageUploading>
                      </div>
                      <div className="form-group">
                        <label>
                          <IntlMessages id="Customer name" />
                        </label>
                        <input
                          type="text"
                          className="form-control react-form-input"
                          id="customerName"
                          name="customerName"
                          disabled={disabledStatus}
                          ref={register({
                            required: "Please customer enter name",
                            maxLength: {
                              value: 100,
                              message:
                                "Customer name must have maximum 100 characters long"
                            }
                          })}
                          defaultValue={customerName}
                          placeholder={
                            props.auth?.user?.language?.toLowerCase() ==
                            "english"
                              ? "Customer name"
                              : props.auth?.user?.language?.toLowerCase() ==
                                "hindi"
                              ? "ग्राहक का नाम"
                              : props.auth?.user?.language?.toLowerCase() ==
                                "gujarati"
                              ? "ગ્રાહકનું નામ"
                              : ""
                          }
                        />
                        <span style={{ color: "red" }}>
                          {errors.customerName &&
                          errors.customerName.message ? (
                            <IntlMessages id={errors.customerName.message} />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>
                          <IntlMessages id="Email" />
                        </label>
                        <input
                          type="email"
                          className="form-control react-form-input "
                          disabled={disabledStatus}
                          id="email"
                          name="email"
                          placeholder={
                            props.auth?.user?.language?.toLowerCase() ==
                            "english"
                              ? "Email"
                              : props.auth?.user?.language?.toLowerCase() ==
                                "hindi"
                              ? "ईमेल"
                              : props.auth?.user?.language?.toLowerCase() ==
                                "gujarati"
                              ? "ઇમેઇલ"
                              : ""
                          }
                          defaultValue={customerEmail}
                          ref={register({
                            pattern: {
                              value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "Please enter valid email"
                            }
                          })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.email && errors.email.message ? (
                            <IntlMessages id={errors.email.message} />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>{" "}
                      <div className="form-group">
                        <label>
                          <IntlMessages id="Mobile number" />
                        </label>
                        <input
                          type="tel"
                          className="form-control react-form-input "
                          disabled={true}
                          id="phoneNumber"
                          name="phoneNumber"
                          style={{
                            cursor: "not-allowed"
                          }}
                          defaultValue={customerNumber}
                        />
                      </div>
                      <div className="form-group ">
                        <label>
                          {" "}
                          <IntlMessages id="Address" />
                        </label>
                        <div>
                          <textarea
                            className="form-control react-form-input"
                            rows={2}
                            disabled={disabledStatus}
                            defaultValue={CustomerAddress}
                            id="address"
                            ref={register}
                            placeholder={
                              props.auth?.user?.language?.toLowerCase() ==
                              "english"
                                ? "Address"
                                : props.auth?.user?.language?.toLowerCase() ==
                                  "hindi"
                                ? "पता"
                                : props.auth?.user?.language?.toLowerCase() ==
                                  "gujarati"
                                ? "સરનામું"
                                : ""
                            }
                            name="address"
                          />
                        </div>
                      </div>
                      <label>
                        {" "}
                        <IntlMessages id="Reminders on" />
                      </label>
                      <div className="form-group">
                        <div className="pretty p-icon p-round p-smooth">
                          <input
                            type="checkbox"
                            defaultValue={isSms}
                            onChange={() => setIsSMS(!isSms)}
                            checked={isSms}
                          />
                          <div className="state p-success">
                            <i className="icon fas fa-check"></i>
                            <label>SMS</label>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="pretty p-icon p-round p-smooth">
                          <input
                            type="checkbox"
                            defaultValue={isWhatsapp}
                            onChange={() => setIsWhatsapp(!isWhatsapp)}
                            checked={isWhatsapp}
                          />
                          <div className="state p-success">
                            <i className="icon fas fa-check"></i>
                            <label>
                              <IntlMessages id="WhatsApp" />{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {!loader ? (
                          <button
                            type="submit"
                            style={button}
                            className="btn button"
                          >
                            <IntlMessages id="Update Profile" />
                          </button>
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    user: state.auth.user,
    auth: state.auth
  };
};

export default compose(
  withRouter,

  connect(mapStateToProps, { setCustomers, selectCustomer })
)(CustomerProfile);
