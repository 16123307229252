import appLocaleData from "react-intl/locale-data/gu";
import enMessages from "../locales/gj_IND.json";

const Gjlang = {
  messages: {
    ...enMessages,
  },
  locale: "gu",
  data: appLocaleData,
};
export default Gjlang;
