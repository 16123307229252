const customerListAction = {
  setCustomers: "SET_CUSTOMER_DETAILS",
  unSetCustomers: "UNSET_CUSTOMER_DETAILS",

  setCustomers: (data) => {
    return {
      type: customerListAction.setCustomers,
      businessId: data.businessId,
      customers: data.customers,
    };
  },
  unSetCustomers: () => {
    return {
      type: customerListAction.unSetCustomers,
      businessId: null,
      customers: null,
    };
  },
};

export default customerListAction;
