import handleError from "./handleErrors";
import { BASE_URL, addCustomer, listCustomer } from "./apiConstants";
import getHeader from "./getHeaders";
import handleResponse from "./handleResponse";

export function newCustomer(data) {
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify(data),
  };

  return fetch(BASE_URL + addCustomer, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return response;
    });
}

export function getCustomerList(id, token) {
  let header;
  if (!token) {
    header = getHeader();
  } else {
    header = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  }

  const requestOptions = {
    method: "GET",
    headers: header,
  };

  return fetch(BASE_URL + listCustomer + id, requestOptions)
    .then(handleResponse)
    .then((response) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return response;
    });
}

export function getCustomerProfile(customerid, businessid) {
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
    // body: JSON.stringify({ businessId: businessid }),
  };

  return fetch(BASE_URL + listCustomer + businessid, requestOptions)
    .then(handleResponse)
    .then((response) => {
      return response;
    });
}

export function updateCustomerProfile(data, token = null) {
  const form = new FormData();
  let header;
  if (!token || token == null) {
    const auth = JSON.parse(localStorage.getItem("persist:root"));
    const tokenDb = JSON.parse(auth.auth).accessToken;
    header = { Authorization: `Bearer ${tokenDb}` };
  } else {
    header = { Authorization: `Bearer ${token}` };
  }

  if (data.profilePic !== "") {
    form.append("profilePic", data.profilePic);
  }

  form.append("address", data.address);
  form.append("customerId", data.customerid);
  form.append("email", data.email);
  form.append("name", data.customerName);
  form.append("isWhatsappModeEnable", data.isWhatsapp);
  form.append("isSMSModeEnable", data.isSms);
  form.append("transactionSms", false);
  form.append("isEmailModeEnable", false);
  const requestOptions = {
    method: "PUT",
    headers: header,
    body: form,
  };
  return fetch(BASE_URL + listCustomer, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return data;
    });
}
