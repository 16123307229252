import React, { Fragment, useEffect, useState } from "react";
import HorizontalSidebarWrapper from "./hor-sidebar.style";
import { NavLink } from "react-router-dom";
import NavMenu from "./NavMenu";
import { HorizontalSidebarData } from "util/data/sidebar";
import IntlMessages from "util/intlMessages";
import { appLogo } from "helper/constant";
import { profile } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import AuthActions from "redux/auth/actions";
import { withRouter } from "react-router-dom";
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import currencyAction from "redux/currency/actions";
import SelectCustomerAction from "redux/customer/actions";
import singleTransactionAction from "redux/singleTransaction/actions";
import customerListAction from "redux/customerList/actions";
import authActionUser from "redux/user/actions";

const { logout } = AuthActions;
const { removecustomer } = SelectCustomerAction;
const { unSetCurrency } = currencyAction;
const { unSetCustomers } = customerListAction;
const { unsetSingleTransaction } = singleTransactionAction;
const { unsetuser } = authActionUser;

const HorizontalSidebar = props => {
  const userSignout = () => {
    localStorage.removeItem("Briefbook:currentBusiness");
    props.removecustomer();
    props.unsetuser();
    props.unsetSingleTransaction();
    props.unSetCustomers();
    props.unSetCurrency();
    props.removecustomer();
    props.logout();
  };
  const [profileImage, setProfileImage] = useState({
    url: "",
    status: false
  });

  const dbData = localStorage.getItem("persist:root");

  // const accessToken = JSON.parse(dbValue.auth).accessToken
  useEffect(() => {
    if (props.userData) {
      if (props.userData.url) {
        setProfileImage({
          url: props.userData.url,
          status: true
        });
      } else {
        /* hey */
        setProfileImage({
          url: profile,
          status: true
        });
      }
    } else {
      setProfileImage({
        url: profile,
        status: true
      });
    }
  }, []);
  return (
    <HorizontalSidebarWrapper {...props}>
      <div className="horizontal-nav d-flex justify-content-between">
        <div className="hor_menu-icon">
          <i
            className="fas fa-bars"
            onClick={() => props.drawerMiniMethod()}
          ></i>
        </div>
        <div
          className="nav_logo  fs-20 bold-text p-2 col-example text-left "
          style={{ cursor: "pointer" }}
          onClick={() => props.history.push("/home")}
        >
          <img
            className="hor_logo"
            style={{ marginRight: 5 }}
            src={appLogo}
            alt="logo"
          />
          {/* <IntlMessages id={"Tiny Ledger"} /> */}
        </div>
        {HorizontalSidebarData.map((nav, i) => {
          return (
            <Fragment key={i}>
              {!nav.hasOwnProperty("child") ? (
                <NavLink
                  to={nav.routepath}
                  className="hor_nav-link text-upper text-center p-2 col-example text-left"
                >
                  <div className="mr-8">
                    <i className={nav.iconClass}></i>
                  </div>
                  <IntlMessages id={nav.name} />
                </NavLink>
              ) : (
                <NavMenu {...props} data={nav} index={i} />
              )}
            </Fragment>
          );
        })}
        <div className="main-screen-profile">
          <div id="profile">
            <img
              className="hor_profile"
              src={profileImage.status ? profileImage?.url : profile}
              alt="notify"
            />
          </div>
          <UncontrolledPopover
            className="roy-menu"
            innerClassName="roy-inner-content"
            placement="top-start"
            target="profile"
            trigger="legacy"
          >
            <PopoverBody>
              {/* <div
                className="roy-menu-list"
                onClick={() => props.history.push("/profile")}
              >
                My Profile
              </div>
              <div className="roy-menu-list">Settings</div> */}
              <div className="roy-menu-list" onClick={userSignout}>
                <IntlMessages id={"Logout"} />
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </div>
        {/* <div className="pl-10">
          <button
            onClick={props.layoutSettingDrawerToggle}
            className="top-header-icon"
          >
            <i className="fas fa-th-large"></i>
          </button>
        </div> */}
      </div>
    </HorizontalSidebarWrapper>
  );
};

const mapStateToProps = state => {
  return {
    ...state,
    userData: state.authActionUser.user
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    logout,
    unsetuser,
    unsetSingleTransaction,
    unSetCustomers,
    unSetCurrency,
    removecustomer
  })
)(HorizontalSidebar);
