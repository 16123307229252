import React from "react";
import { badge_received } from "helper/constant";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import IntlMessages from "util/intlMessages";
import { amountFormatter } from "helper/methods";
import Loader from "components/loader/Loader";
const ShareModal = props => {
  return (
    <Modal
      isOpen={props.shareModal}
      toggle={props.toggle}
      style={{ marginTop: "12%" }}
    >
      <ModalBody>
        <div
          className={"row"}
          id="htmltoimage"
          style={{
            background: "#0073e6",
            color: "#FFF",
            borderRadius: "1%",
            margin: "1px"
            // height: "260px",
          }}
        >
          <div
            className={"flex-1 fs-15 pb-8 mlr-10 "}
            style={
              {
                // borderRadius: "5%",
              }
            }
          >
            <p>{props.business.name}</p>
            <p>+91 {props.user?.phoneNumber}</p>
            <p>{props.user?.address}</p>
          </div>
          <div
            align={"right"}
            style={{ marginTop: "15px", marginRight: "10px" }}
          >
            <p>
              <i className="fa fa-building" />
            </p>
            <p></p>
          </div>

          <div
            className={"row"}
            style={{ margin: 5, marginBottom: "10px", width: "100%" }}
          >
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1 "></div>
            <div
              style={{
                background: "#FFF",
                color: "black",
                height: "150px",
                borderRadius: "2%"
              }}
              className="roe-card col-lg-10 col-sm-10 col-md-10 col-xs-10 "
            >
              <div className="text-center profile-widget-image ptb-5">
                <img height="70" width="70" src={badge_received} />
              </div>

              <div className="text-center text-muted fs-14">
                <span>
                  {moment(new Date(props.transaction.createdAt)).format(
                    "ddd D MMM , hh:MM A "
                  )}
                </span>
              </div>
              <div className="text-center bold-text fs-16">
                <span>
                  {props.transaction.amount &&
                  props.transaction.direction == "INBOUND" ? (
                    <div
                      className="text-center card-shadow bold-text fs-16 "
                      style={{
                        display: "inline-flex"
                      }}
                    >
                      <IntlMessages id="Payment received" /> :{" "}
                      <p
                        className="green pl-1"
                        style={{
                          color: "green"
                        }}
                      >
                        <i className={props.currency} />
                        {props.transaction.amount
                          ? amountFormatter(props.transaction.amount)
                          : ""}
                      </p>
                    </div>
                  ) : (
                    <div className="text-center bold-text fs-16 inline-flex">
                      <IntlMessages id="Payment sent" /> {" :"}
                      <p className="red pl-1" style={{ color: "red" }}>
                        <i className={props.currency} />
                        {props.transaction.amount
                          ? amountFormatter(props.transaction.amount)
                          : ""}
                      </p>
                    </div>
                  )}
                </span>
              </div>
            </div>
            <div className="col-lg-1 col-sm-1 col-md-1 col-xs-1 "></div>
          </div>
        </div>{" "}
      </ModalBody>
      <ModalFooter>
        {props.loader && props.loader ? (
          <div className="mr-50">
            <Loader height="30px" width="28px" />
          </div>
        ) : (
          <i className="fa fa-download mr-50" onClick={props.downloadImage}></i>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default ShareModal;
