import AuthActions from "../../redux/auth/actions";
import { store } from "../../redux/store";
export default function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        store.dispatch({
          type: "LOGOUT",
          type: "UNSET_CURRENCY"
        });
        localStorage.removeItem("persist:root");
        localStorage.removeItem("Briefbook:currentBusiness");
        window.location.replace("/login");
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        //Logout from here

        const error = (data && data.errors.message) || response.statusText;
        return Promise.reject(error);
      }
    }

    return data;
  });
}
