import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Tooltip
} from "reactstrap";
import { connect } from "react-redux";
import Loader from "../../loader/Loader";
import { CurrencyData } from "util/data/CurrencyData";
import Select from "react-select";
import moment from "moment";
import InputIntl from "components/Placeholder/Placeholder";
import TextAreaIntl from "components/Placeholder/TextAreaIntl";
import { compose } from "redux";
import IntlMessages from "util/intlMessages";
import { InputGroup } from "reactstrap";
import DatePicker from "react-datepicker";
import ImageUploading from "react-images-uploading";

import {
  createTransaction,
  updateSingleTransaction
} from "helper/services/transactionService";
import AlertError from "../error/AlertError";
import currencyAction from "redux/currency/actions";
import ListCurrency from "components/Settings/ListCurrency";
const { setCurrency } = currencyAction;
const AddTransactionModal = props => {
  const [selectedCurrency, setselectedCurrency] = useState();
  let currentVal;
  let currencyIcon;

  if (props.currencyName === "INR") {
    currentVal = { value: "INR", label: "₹ INR" };
  }
  if (props.currencyName === "DOLLAR") {
    currentVal = { value: "DOLLAR", label: "$ DOLLAR" };
  }
  if (props.currencyName === "EURO") {
    currentVal = { value: "EURO", label: "€ EURO" };
  }

  if (props.currencyName === "POUND") {
    currentVal = { value: "POUND", label: "£ POUND" };
  }

  if (props.currencyName === "YEN") {
    currentVal = { value: "YEN", label: "¥ YEN" };
  }

  const [disabledAmount, setDisabledAmount] = useState(false);
  const [tooltipOpen, settTooltipOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [minimumDate, setMinimumDate] = useState(null);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageupload, setImageUpload] = useState([]);
  const [imageFlage, setImageFlag] = useState(false);
  const [images, setImages] = useState(null);
  const [notes, setNotes] = useState(null);
  const [transactionAmount, setTransactionAmount] = useState(null);
  const [transactionRate, setTransactionRate] = useState(null);
  const [deleteImages, setDeleteImages] = useState(null);
  const [currency, setCurrency] = useState(currentVal);
  const [currencynew, updateCurrency] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currencyModal, setCurrencyModal] = useState(false);
  useEffect(() => {
    if (props.type == "UPDATE") {
      setDisabledAmount(true);
      getAllValues();
    }
    if (props.type == "INSERT") {
      setDisabledAmount(false);
    }
    return () => {
      if (props.currencyName === "INR") {
        currentVal = { value: "INR", label: "₹ INR" };
      }
      if (props.currencyName === "DOLLAR") {
        currentVal = { value: "DOLLAR", label: "$ DOLLAR" };
      }
      if (props.currencyName === "EURO") {
        currentVal = { value: "EURO", label: "€ EURO" };
      }

      if (props.currencyName === "POUND") {
        currentVal = { value: "POUND", label: "£ POUND" };
      }

      if (props.currencyName === "YEN") {
        currentVal = { value: "YEN", label: "¥ YEN" };
      }
    };
  }, []);
  const doSetDefaultCurrency = () => {
    {
      if (props.currencyName === "INR") {
        currentVal = { value: "INR", label: "₹ INR" };
      }
      if (props.currencyName === "DOLLAR") {
        currentVal = { value: "DOLLAR", label: "$ DOLLAR" };
      }
      if (props.currencyName === "EURO") {
        currentVal = { value: "EURO", label: "€ EURO" };
      }

      if (props.currencyName === "POUND") {
        currentVal = { value: "POUND", label: "£ POUND" };
      }

      if (props.currencyName === "YEN") {
        currentVal = { value: "YEN", label: "¥ YEN" };
      }
      setCurrency(currentVal);
    }
  };
  const doHandleModalToggle = () => {
    props.toggle();
    if (props.addTransactionModal) {
      doSetDefaultCurrency();
    }
  };
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    setSelectedImage(imageList[0]);
    setImageFlag(true);
  };

  const toggleCurrencyModal = () => {
    setCurrencyModal(!currencyModal);
  };
  function onMouseBusinessLeave(div) {
    document.getElementById(div).style.background = "#fff";
  }
  function onHoverBusinessStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
    document.getElementById(div).style.cursor = "pointer";
  }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  if (currency.value === "INR") {
    currencyIcon = "₹";
  }

  if (currency.value === "DOLLAR") {
    currencyIcon = "$";
  }

  if (currency.value === "EURO") {
    currencyIcon = "€";
  }

  if (currency.value === "POUND") {
    currencyIcon = "£";
  }

  if (currency.value === "YEN") {
    currencyIcon = "¥";
  }
  const button = {
    fontSize: "16px",
    // textTransform: "capitalize",
    boxShadow: "none!important",
    width: "100%",
    borderRadius: "2px",
    fontWeight: "500",
    background: props.transactionType == "INBOUND" ? "#018842" : "#C50600",
    color: " white",
    paddingLeft: "10px",
    textAlign: "center"
  };

  const errorStyle = {
    color: "red",
    justifyContent: "center",
    fontSize: 15
  };
  const getAllValues = () => {
    // setTransactionAmount(props.transaction.amount);
    setTransactionAmount(props.transaction.exchangeAmount);
    setDisabledAmount(true);
    setNotes(props.transaction.notes);
    setMinimumDate(new Date(props.transaction.date));
    if (props.transaction.singleTransactionMeta?.length > 0) {
      setImages(props.transaction.singleTransactionMeta);
    }
    if (props.transaction.date) {
      setSelectedDate(new Date(props.transaction.date));
    }
  };
  const handleModalSubmit = e => {
    e.preventDefault();
    setErrorModal(false);
    if (e.target.transactionAmount.value) {
      if (e.target.transactionAmount.value.length > 0) {
        setLoader(true);
        console.log(
          "User Transaction data amount-",
          e.target.transactionAmount.value,
          "User Transaction data rate-",
          // e.target.transactionRate.value,
          "Notes",
          notes,
          "date",
          moment(selectedDate).format("DD-MM-YYYY"),
          "images",
          images
        );
        let imagesData = null;
        if (images) {
          console.log("in images filter");

          imagesData = images.map(item => item.file);
          setImageUpload(imagesData);
        }
        if (props.type == "INSERT") {
          insertTransaction({
            currency: currency.value,
            // amount: e.target.transactionAmount.value,
            exchangeAmount: e.target.transactionAmount.value,
            exchangeRate: transactionRate,
            note: notes,
            images: imagesData,
            date: selectedDate,
            customerid: props.customerData.id,
            type: props.transactionType,
            businessid: parseInt(props.businessid)
          });
        } else {
          updateTransaction({
            note: notes,
            images: imagesData,
            deletedImageid: deleteImages,
            date: selectedDate,
            transactionId: props.transaction.id
          });
        }
      }
    } else {
      setErrorMessage({
        errors: {
          message: (
            <IntlMessages id="Please enter amount to record new transaction" />
          )
        }
      });
      setErrorModal(false);
      setErrorModal(true);
    }
  };
  const updateTransaction = async data => {
    try {
      const response = await updateSingleTransaction(data);
      if (response) {
        setLoader(false);
        if (response.errors) {
          console.log("errors", response);
          setErrorModal(true);
          setErrorMessage({
            errors: {
              message: response.errors.message
            }
          });
        }
        if (response.singleTransaction) {
          props.setReload(true);
          props.setTransaction(response.singleTransaction);
          props.SingleTransaction(response.singleTransaction);
          doHandleModalToggle();
          props.notify("Updated", "success");
        }
      }
    } catch (e) {
      console.log("error", e);
      props.notify(" Error Occur", "error");
    }
  };

  const insertTransaction = async data => {
    try {
      if (
        currency.value !== props.currencyName &&
        (transactionRate === null || transactionRate == "")
      ) {
        setErrorMessage({
          errors: {
            message: `Please enter a price in ${props.currencyName}`
          }
        });
        setErrorModal(false);
        setErrorModal(true);
        setLoader(false);
        return;
      }
      const response = await createTransaction(data, props.currencyName);
      // const response = await createTransaction(data);
      if (response) {
        setLoader(false);
        if (response.errors) {
          if (response.errors?.message) {
            setErrorMessage({
              errors: {
                message: response.errors?.message || ""
              }
            });
          }

          setErrorModal(false);
          setErrorModal(true);
        } else {
          props.getTransactions();
          doHandleModalToggle();
        }
      }
      setTransactionAmount("");
      setNotes("");
      setTransactionRate("");
    } catch (e) {
      setLoader(false);
      // props.notify(e, "error");
      if (e) {
        setErrorMessage({
          errors: {
            message: e || ""
          }
        });
        setErrorModal(false);
        setErrorModal(true);
      }
      console.log("error in handling submit", e);
    }
  };
  return (
    <>
      <Modal
        style={{
          ...customStyles,
          marginTop: "12%"
        }}
        isOpen={props.addTransactionModal}
        toggle={doHandleModalToggle}
      >
        <>
          <form className="" onSubmit={e => handleModalSubmit(e)}>
            <ModalHeader
              // toggle={() => {
              //   if (currencyModal == false) {
              //     props.toggle()
              //   }

              // }}
              toggle={doHandleModalToggle}
            >
              {props.title}
            </ModalHeader>

            <ModalBody style={{ paddingBottom: 0 }}>
              <div>
                {errorModal && errorMessage ? (
                  <AlertError errorMessage={errorMessage} />
                ) : (
                  ""
                )}
                <div className="form-group">
                  <Select
                    id="currency"
                    defaultValue={currentVal}
                    label="Select currency"
                    options={CurrencyData}
                    onChange={e => {
                      setCurrency(e);
                    }}
                  />
                  <InputIntl
                    disabled={disabledAmount}
                    autoFocus={disabledAmount ? false : true}
                    style={{
                      marginTop: "10px",
                      // paddingLeft: "25px",
                      cursor: disabledAmount ? "not-allowed" : ""
                    }}
                    placeholderid="Amount"
                    type="number"
                    value={transactionAmount || ""}
                    className="form-control react-form-input"
                    id="transactionAmount"
                    onChange={e => {
                      if (disabledAmount == false) {
                        if (e.target.value.length > 0) {
                          setErrorModal(false);
                        }
                        setTransactionAmount(e.target.value);
                      }
                    }}
                  />
                </div>
                {currency.value !== props.currencyName ? (
                  <>
                    {/* <div className="row"> */}
                    <div>
                      <label>Current price of {currencyIcon}</label>
                    </div>

                    <div className="row">
                      {" "}
                      <div className="col-md-5">
                        <div
                          className="form-group "
                          style={{
                            // width: "182px",
                            marginTop: "15px"
                            // marginRight: "36px",
                            // marginLeft: "18px"
                          }}
                        >
                          <span className="form-control">{currencyIcon}1</span>
                        </div>
                      </div>
                      <div
                        className="col-md-1"
                        style={{ marginLeft: "25px", marginTop: "18px" }}
                      >
                        <label>=</label>
                      </div>
                      <div
                        className="form-group col-md-5"
                        style={{
                          // width: "182px",
                          marginTop: "15px",
                          marginLeft: "1rem"
                        }}
                      >
                        <InputIntl
                          disabled={disabledAmount}
                          autoFocus={disabledAmount ? false : true}
                          style={{
                            paddingLeft: "25px",
                            cursor: disabledAmount ? "not-allowed" : ""
                          }}
                          placeholderid={`Price in ${props.currencyName}`}
                          type="number"
                          value={transactionRate || ""}
                          className="form-control react-form-input"
                          id="transactionRate"
                          onChange={e => {
                            if (disabledAmount == false) {
                              if (e.target.value.length > 0) {
                                setErrorModal(false);
                              }
                              setTransactionRate(e.target.value);
                            }
                          }}
                        />

                        {/* <InputIntl
                    placeholderid="Price in DOLLAR"
                    type="number"
                    className="form-control react-form-input"
                  /> */}
                      </div>
                      <div
                        className="col-md-1"
                        style={{
                          marginTop: "18px",
                          marginLeft: "-3.5rem",
                          cursor: "pointer"
                        }}
                        onClick={toggleCurrencyModal}
                        id={`business${1}`}
                      >
                        <i className="fa fa-edit text-muted fs-12" />
                      </div>
                      <span
                        // className="align-center"
                        onMouseEnter={() =>
                          onHoverBusinessStyle(`business${1}`)
                        }
                        onMouseLeave={() =>
                          onMouseBusinessLeave(`business${1}`)
                        }
                      >
                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen}
                          autohide={false}
                          target={`business${1}`}
                          toggle={() => settTooltipOpen(!tooltipOpen)}
                        >
                          <IntlMessages id="Click to change default currency" />
                        </Tooltip>
                      </span>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="form-group">
                  <TextAreaIntl
                    className="form-control"
                    id="transactionNotes"
                    value={notes || ""}
                    onChange={e => {
                      setNotes(e.target.value);
                    }}
                    rows="2"
                    maxLength="120"
                    placeholderid="Notes"
                  />
                  <span className="d-block text-right">
                    <IntlMessages id="Maximum characters" /> {" :"}{" "}
                    {notes && notes ? notes.length : "0"}/120
                  </span>
                </div>
                <div className="form-group flex">
                  <span
                    className="fa fa-calendar"
                    style={{
                      marginTop: "10px",
                      marginLeft: "6px",
                      marginRight: "-13px",
                      position: "absolute"
                    }}
                  ></span>
                  <DatePicker
                    style={{
                      paddingLeft: "25px"
                    }}
                    className="form-control "
                    // minDate={minimumDate ? minimumDate : new Date()}
                    selected={selectedDate ? selectedDate : new Date()}
                    //   dateFormat="yyyy-MM-dd HH:MM:SS"
                    onChange={date => setSelectedDate(date)}
                  />
                </div>
                <div className="form-group">
                  <ImageUploading
                    className="flex"
                    multiple
                    value={images}
                    onChange={onChange}
                    maxNumber={3}
                    dataURLKey="data_url"
                  >
                    {({
                      imageList,
                      onImageUpload,
                      onImageUpdate,
                      onImageRemove,
                      isDragging,
                      dragProps,
                      errors
                    }) => (
                      <>
                        <div className="text-center">
                          <div className="form-group">
                            <button
                              type="button"
                              style={{
                                width: "50%"
                              }}
                              // type="button"
                              onClick={onImageUpload}
                              className=" form-control"
                            >
                              <i className="fa fa-camera" />{" "}
                              {images && images.length > 0 ? (
                                <IntlMessages id="Upload more images" />
                              ) : (
                                <IntlMessages id="Upload images" />
                              )}
                            </button>
                          </div>
                          <div
                            style={{
                              display: "flex"
                            }}
                          >
                            {imageList.length > 0
                              ? imageList.map((image, index) => (
                                  <div key={index} className="demi-flex pl-1">
                                    <img
                                      src={
                                        image.url
                                          ? image.url
                                          : image["data_url"]
                                      }
                                      alt=""
                                      height="80"
                                      width="80"
                                    />
                                    <div className=" ">
                                      <a
                                        onClick={() => {
                                          if (props.type === "UPDATE") {
                                            if (image.id) {
                                              setDeleteImages(
                                                deleteImages
                                                  ? deleteImages +
                                                      "," +
                                                      image.id
                                                  : image.id
                                              );
                                            }
                                            onImageUpdate(index);

                                            // onImageRemove(index);
                                          }
                                          if (props.type === "INSERT") {
                                            onImageUpdate(index);
                                          }
                                        }}
                                        style={{ padding: 10 }}
                                      >
                                        <i className="fa fa-edit" />
                                      </a>
                                      <a
                                        onClick={() => {
                                          if (props.type === "UPDATE") {
                                            if (image.id) {
                                              setDeleteImages(
                                                deleteImages
                                                  ? deleteImages +
                                                      "," +
                                                      image.id
                                                  : image.id
                                              );
                                            }
                                            onImageRemove(index);
                                          }
                                          if (props.type === "INSERT") {
                                            onImageRemove(index);
                                          }
                                        }}
                                      >
                                        <i className="fa fa-trash" />
                                      </a>
                                    </div>
                                  </div>
                                ))
                              : ""}
                          </div>
                          <div>
                            {errors && errors.maxNumber && (
                              <span
                                style={{
                                  color: "red"
                                }}
                              >
                                <IntlMessages id="Photos (Maximum 3)" />
                              </span>
                            )}
                            {/* {errors.acceptType && (
                            <span>
                              Images types of Jpeg,png and gif are allowed{" "}
                            </span>
                          )} */}
                          </div>
                        </div>
                      </>
                    )}
                  </ImageUploading>
                </div>
              </div>
            </ModalBody>
            <ModalFooter style={{ display: "block", paddingTop: 10 }}>
              <div className="form-group">
                {loader ? (
                  <Loader />
                ) : (
                  <button
                    className="btn btn-button"
                    type="submit"
                    /*  value={
                      props.type == "UPDATE" ? (
                        "Update"
                      ) : props.transactionType &&
                        props.transactionType == "INBOUND" ? (
                        <IntlMessages id="Accept payment" />
                      ) : (
                        <intlMessages id="Give credit" />
                      )
                    } */
                    style={button}
                  >
                    {props.type == "UPDATE" ? (
                      <IntlMessages id="Update" />
                    ) : props.transactionType &&
                      props.transactionType == "INBOUND" ? (
                      <IntlMessages id="Accept payment" />
                    ) : (
                      <IntlMessages id="Give credit" />
                    )}
                  </button>
                )}
              </div>
              <ListCurrency
                currency={currencynew}
                updateCurrency={updateCurrency}
                setCurrencyModal={setCurrencyModal}
                setCurrencyRedux={data => {
                  props.setCurrency(data);
                }}
                currencyModal={currencyModal}
                toggleCurrencyModal={toggleCurrencyModal}
                currencySymbol={currencySymbol}
                setCurrencySymbol={setCurrencySymbol}
                istoggleCurrencyModal={true}
                {...props}
              />
            </ModalFooter>
          </form>
        </>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default connect(mapStateToProps, { setCurrency })(AddTransactionModal);
