import React, { useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { amountFormatter } from "helper/methods";
import "react-datepicker/dist/react-datepicker.css";
import IntlMessages from "util/intlMessages";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
/* Date picker */

/* add transaction Modal */
import AddTransactionModal from "../../components/Customer/cutomerTransaction/TransactionModal";

import TransactionHeader from "components/Customer/cutomerTransaction/transactionheader/TransactionHeader";

import Notifications, { notify } from "react-notify-toast";
//customer
import TransactionList from "../../components/Customer/cutomerTransaction/TransactionList.jsx";
// import AlertError from "../components/Customer/error/AlertError";
// import enhancer from "./authentication/enhancer/newCustomerContactEnhancer";

//service
import {
  newCustomer,
  getCustomerList
} from "../../helper/services/customerService";
import { fetchTransaction } from "../../helper/services/transactionService";
import singleTransactionAction from "redux/singleTransaction/actions";
// every functionality should be in a component
//  modal for Add new customer contact should be in a new component and call this component instead of using code in a same file
// const {setCustomer}
const { setSingleTransaction } = singleTransactionAction;
const CustomerTransaction = props => {
  ////
  const [selectedReminderDate, setSelectedReminderDate] = useState(new Date());
  const [customerTransaction, SetCustomerTransaction] = useState([]);
  const [currentBusinessName, setCurrentBusiness] = useState(null);
  const [businessmodal, setBusinessmodal] = useState(false);
  const [addbusinessmodal, setAddBusinessmodal] = useState(false);

  const [inboundModal, setInboundModal] = useState(false);
  const [outBoundModal, setOutBoundModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [customerArr, setCustomerArr] = useState([]);
  const [getTotalGive, setTotalGive] = useState(0);
  const [getTotalGot, setTotalGot] = useState(0);
  const [startDate, setStartDate] = useState(new Date());
  const Datepickerbutton = ({ value, onClick }) => (
    <div className="btn" onClick={onClick}>
      <span>
        Set Reminder <i className=" far fa-calendar-alt pr-1" />
        {value}
      </span>
    </div>
  );
  const [customerlistLoader, setCustomerListLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    getTransactions();
  }, []);

  const { errors, touched, submitCount } = props;
  const toggleInboundModal = () => {
    setInboundModal(!inboundModal);
  };
  const toggleOutBoundModal = () => {
    setOutBoundModal(!outBoundModal);
  };
  const customerProfile = () => {
    props.history.push("/customer-profile", props.history.location.state);
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  /* get total of got and give  */
  const getSumTotalTransaction = customer => {
    /*TODO
 here first we will check if transaction is null than store null value 
than if not null check if value is greater than 0 ,than store in gottotal
else if its less than 0 than givetotal variable

*/
    //
    let totalgivevalue = 0;
    let totalgotvalue = 0;
    if (customer.length > 0) {
      customer.map(customer => {
        if (customer.transaction[0]) {
          if (customer.transaction[0].balance > 0) {
            //
            //   "customer transactions balance greater than 0 give ",
            //   customer.transaction[0].balance
            // );
            totalgivevalue += customer.transaction[0].balance;
          }

          if (customer.transaction[0].balance < 0) {
            //
            totalgotvalue += customer.transaction[0].balance;
          }
        }

        setTotalGive(totalgivevalue);
        setTotalGot(totalgotvalue);
      });
    } else {
      setTotalGive(0);
      setTotalGot(0);
      //here not storing value as its undefined and undefined means it has 0 value
      //
    }
  };

  const setSingleTransactionToReducer = (data, parentid) => {
    props.setSingleTransaction({ transaction: data, parentId: parentid });
  };
  const getTransactions = async () => {
    setCustomerListLoader(true);

    if (props.SelectCustomerAction?.customer) {
      try {
        const response = await fetchTransaction(
          props.SelectCustomerAction.customer.id,
          Number(props.customerListAction.businessId)
        );
        if (response) {
          setCustomerListLoader(false);

          setStartDate(new Date(response.transaction.balanceDueDate));
          SetCustomerTransaction(response.transaction);
        } else {
          props.history.push("/home");
        }
      } catch (e) {}
    } else {
      props.history.push("/home");
    }
  };
  const handleChanges = (v, f) => {
    setSelectedReminderDate(f);
  };
  const fetchCustomer = async id => {
    setCustomerListLoader(true);
    let historyProps = props.history.location.state;
    try {
      const responseCustomer = await getCustomerList(
        id,
        props.auth.accessToken
      );
      //
      if (responseCustomer) {
        //
        getSumTotalTransaction(responseCustomer.customers);
        setCustomerArr(responseCustomer.customers);
        setCustomerListLoader(false);
      }
      if (!responseCustomer) {
        //
        getSumTotalTransaction(responseCustomer.customers);
        // setCustomerArr(responseCustomer.customers);
        setCustomerListLoader(false);
      }
    } catch (err) {
      console.error(err);
      setCustomerListLoader(false);
    }
  };
  const notifyStatus = (message, status, timer) => {
    notify.show(message, status, timer);
  };
  const ToggleBusinessModal = () => setBusinessmodal(!businessmodal);
  const ToggleAddBusinessModal = () => setAddBusinessmodal(!addbusinessmodal);
  const changeBusiness = business => {
    setCustomerListLoader(true);
    setLoader(true);
    setCurrentBusiness({
      id: business.id,
      name: business.name
    });
    localStorage.setItem("Briefbook:currentBusiness", business.id);

    fetchCustomer(business.id);
    setBusinessmodal(!businessmodal);
    setLoader(false);
    setCustomerListLoader(false);
  };
  const profileImage = {
    borderRadius: "50%",
    border: "1 solid",
    width: 42,
    background: "#0073e6",
    height: 41,
    objectFit: "fill",
    cursor: "pointer",
    marginLeft: 40
  };
  const InCap = {
    lineHeight: 2,
    fontSize: 20,
    paddingLeft: "13px",
    color: "white",
    textAlign: "center"
  };

  return (
    <div>
      <div className="container">
        <Notifications />

        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />

          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
            <section>
              <nav
                style={{
                  marginBottom: 10
                }}
                className="custom-breadcumb"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className="breadcumb-color breadcrumb-item">
                    <a
                      id={32}
                      style={{
                        color: "gray"
                      }}
                      onClick={() => {
                        {
                          props.location.state.page &&
                          props.location.state.page === "Account"
                            ? props.history.push("/account")
                            : props.history.push("/home");
                        }
                      }}
                      onMouseEnter={() => {
                        document.getElementById(32).style.textDecoration =
                          "underline";
                      }}
                      onMouseLeave={() => {
                        document.getElementById(32).style.textDecoration =
                          "none";
                      }}
                    >
                      {props.location.state.page &&
                      props.location.state.page ? (
                        <IntlMessages id="sidebar.Account" />
                      ) : (
                        <IntlMessages id="sidebar.Home" />
                      )}
                    </a>
                  </li>
                  {props.location.state.page && props.location.state.page ? (
                    <li className="breadcumb-color breadcrumb-item">
                      <a
                        id={33}
                        style={{
                          color: "gray",
                          cursor: "pointer"
                        }}
                        onClick={() => props.history.goBack()}
                        onMouseEnter={() => {
                          document.getElementById(33).style.textDecoration =
                            "underline";
                        }}
                        onMouseLeave={() => {
                          document.getElementById(33).style.textDecoration =
                            "none";
                        }}
                        // href="/home"
                      >
                        {props.location.state.page &&
                        props.location.state.page ? (
                          <IntlMessages id="Customers" />
                        ) : (
                          ""
                        )}
                      </a>
                    </li>
                  ) : (
                    ""
                  )}
                  <li
                    className="active breadcrumb-item"
                    style={{
                      color: "#0073e6",
                      wordWrap: "break-word"
                    }}
                    aria-current="page"
                  >
                    <span>
                      {props.SelectCustomerAction?.customer.name}{" "}
                      <IntlMessages id="users transactions" />
                    </span>
                  </li>
                </ol>
              </nav>
            </section>
            <TransactionHeader
              currency={props.currencyAction.symbol}
              section1={{
                title: <IntlMessages id="You will Get" />,
                amount: "0"
              }}
              customerProfile={customerProfile}
              section2={{
                title:
                  customerTransaction && customerTransaction.balance != null ? (
                    customerTransaction.balance >= 0 ? (
                      <IntlMessages id="You will give" />
                    ) : (
                      <IntlMessages id="You will get" />
                    )
                  ) : (
                    ""
                  ),
                amount:
                  customerTransaction.balance != null
                    ? amountFormatter(customerTransaction.balance)
                    : "",
                amountInNumber: customerTransaction.balance
              }}
              SelectCustomerAction={props.SelectCustomerAction}
            />
            <div className="roe-card-style roe-shadow-2 whitelight">
              {/* <div
                style={{
                  marginTop: 5,
                  marginBottom: 5,
                }}
              >
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 border-right">
                    <div
                      className="flex-x    "
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        props.history.push(
                          "/customer-profile",
                          props.history.location.state
                        )
                      }
                      data-toggle="tooltip"
                      title="Edit Customer profile"
                    >
                      {props.SelectCustomerAction &&
                      props.SelectCustomerAction.customer &&
                      props.SelectCustomerAction.customer.customerMeta &&
                      props.SelectCustomerAction.customer.customerMeta.length >
                        0 ? (
                        <div style={{ marginLeft: "35px !important" }}>
                          <img
                            style={{
                              borderRadius: "50%",
                              // border: "1px solid ",
                              width: 42,
                              height: 41,
                              objectFit: "contain",
                              marginLeft: 40,
                            }}
                            src={
                              props.SelectCustomerAction.customer &&
                              props.SelectCustomerAction.customer.customerMeta.map(
                                (url) => {
                                  return url.thumbnail;
                                }
                              )
                            }
                            alt="profile"
                          />
                        </div>
                      ) : (
                        <div style={profileImage}>
                          <span style={InCap}>
                            {
                              "user data",
                              props.SelectCustomerAction.customer
                            )}
                            {props.SelectCustomerAction.customer.name
                              .charAt(0)
                              .toUpperCase()}
                          </span>
                        </div>
                      )}
                      <div
                        className="flex-1"
                        style={{
                          marginLeft: 10,
                        }}
                      >
                        <p
                          style={{
                            cursor: "pointer",
                          }}
                          className="bold-text"
                          style={{ marginTop: 8, textTransform: "capitalize" }}
                        >
                          {props.SelectCustomerAction.customer.name}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div
                    className="col-md-6 col-sm-6 col-xs-6"
                    style={{ textAlign: "left", marginTop: 8 }}
                  >
                    <span style={{ paddingLeft: 46, paddingTop: 4 }}>
                      {customerTransaction &&
                      customerTransaction.balance != null
                        ? customerTransaction.balance >= 0
                          ? "You will give"
                          : "You will get"
                        : ""}
                    </span>
                    <span
                      className="fs-15"
                      style={{
                        color:
                          customerTransaction.balance > 0 ? "green" : "red",
                        marginLeft: 6,
                      }}
                    >
                      {customerTransaction.balance != null ? (
                        <>
                          <i className={props.currencyAction.symbol} />
                          {customerTransaction.balance
                            .toString()
                            .replace("-", "")}
                        </>
                      ) : (
                        ""
                      )}
                    </span>
                    <span
                      style={{
                        paddingLeft: "34px",
                      }}
                    >
                      {customerTransaction && customerTransaction.balance
                        ? customerTransaction.balance < 0
                          ? ""
                          : // <DatePicker
                            //   minDate={new Date()}
                            //   selected={startDate}
                            //   onChange={(date) => setStartDate(date)}
                            //   customInput={<Datepickerbutton />}
                            // />
                            ""
                        : ""}
                    </span>
                  </div>
                </div>
              </div>
              */}
              <div className="roe-footer">
                <button
                  className="btn btn-button  btn-md card sticky-bottom-right roe-card-style"
                  style={{
                    height: 38,
                    width: 150,
                    boxShadow:
                      "0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%) !important",
                    position: "fixed",
                    bottom: 30,
                    left: 0,
                    right: 180,
                    margin: "auto",
                    paddingLeft: "45px",
                    backgroundColor: "#018842",
                    color: "#ffff",
                    border: "1px solid",
                    borderRadius: "8px",
                    display: "block"
                  }}
                  onClick={toggleInboundModal}
                >
                  {/* <i
              className="fa fa-plus pt-0 pr-1"
              // style={{
              //   padding: " 6px 2px",
              // }}
            /> */}
                  <IntlMessages id="You got" />
                </button>
                <button
                  onClick={toggleOutBoundModal}
                  className="btn btn-button btn-md card  sticky-bottom-right roe-card-style"
                  style={{
                    height: 38,
                    width: 150,
                    boxShadow:
                      "0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%) !important",
                    position: "fixed",
                    bottom: 30,
                    right: 0,
                    margin: "auto",
                    paddingLeft: "40px",
                    left: 168,
                    backgroundColor: "#C50600",
                    color: "#ffff",
                    border: "1px solid",
                    borderRadius: "8px",
                    display: "block"
                  }}
                  // onMouseOver={() => setHover(true)}
                  // onMouseOut={() => setHover(false)}
                  // onClick={toggleBasicAlert}
                >
                  {/* <i
              className="fa fa-plus pt-0 pr-1"
              // style={{
              //   padding: " 6px 2px",
              // }}
            /> */}
                  <IntlMessages id="You gave" />
                </button>
              </div>
            </div>

            <div
              className="roe-card-style roe-shadow-2 whitelight"
              style={{ marginTop: 15 }}
            >
              <div align="center" className="roe-card-body">
                <AddTransactionModal
                  notify={(e, status, time) => notify.show(e, status)}
                  title={<IntlMessages id="You got" />}
                  getTransactions={() => getTransactions()}
                  customerData={props.SelectCustomerAction.customer}
                  businessid={props.customerListAction.businessId}
                  transactionType="INBOUND"
                  type={"INSERT"}
                  setErrorMessage={setErrorMessage}
                  currency={props.currencyAction.symbol}
                  currencyName={props.currencyAction.currency}
                  addTransactionModal={inboundModal}
                  toggle={toggleInboundModal}
                />

                <AddTransactionModal
                  notify={(e, status, time) => notify.show(e, status)}
                  title={<IntlMessages id="You gave" />}
                  getTransactions={() => getTransactions()}
                  customerData={props.SelectCustomerAction.customer}
                  transactionType="OUTBOUND"
                  type={"INSERT"}
                  businessid={props.customerListAction.businessId}
                  setErrorMessage={setErrorMessage}
                  currency={props.currencyAction.symbol}
                  currencyName={props.currencyAction.currency}
                  addTransactionModal={outBoundModal}
                  toggle={toggleOutBoundModal}
                />
                {/* <div className="ml-3">
                  <div className="text-left pt-2">
                    <span>
                      <a className="mlr-2" href="/home">
                        Home
                      </a>
                      /<a className="mlr-2">Customer Transaction</a>/
                      <a className="mlr-2">
                        {props.customerListAction.customer.name}
                      </a>
                    </span>
                  </div>
                </div> */}

                <TransactionList
                  customerName={props.SelectCustomerAction.customer.name}
                  currency={props.currencyAction.symbol}
                  transaction={customerTransaction}
                  loader={customerlistLoader}
                  business={props.history.location.state.business}
                  SingleTransaction={(data, parentid) =>
                    setSingleTransactionToReducer(data, parentid)
                  }
                  page={
                    props.location.state.page === "Account" ? "Account" : "Home"
                  }
                  {...props}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { setSingleTransaction })
)(CustomerTransaction);
