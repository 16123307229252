const authActionUser = {
  unsetUser: "UNSET_USER",
  setuser: "SET_USER",
  updateuser_lang: "UPDATE_USER_LANG",

  setuser: (user) => {
    return {
      type: authActionUser.setuser,
      isLogin: true,
      user: user.user,
    };
  },
  unsetuser: () => {
    return {
      type: authActionUser.unsetUser,
      isLogin: false,
      user: null,
    };
  },
  updateuser_lang: (language) => {
    return {
      type: authActionUser.updateuser_lang,
      isLogin: false,
      language: language,
    };
  },
};

export default authActionUser;
