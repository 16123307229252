import appLocaleData from "react-intl/locale-data/hi";
import enMessages from "../locales/hi_IND.json";

const HiLang = {
  messages: {
    ...enMessages,
  },
  locale: "hi",
  data: appLocaleData,
};
export default HiLang;
