import React, { useState, useEffect } from "react";
import { loginBack, profile } from "helper/constant";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import enhancer from "../authentication/enhancer/RegisterFormEnhancer";
import ImageUploading from "react-images-uploading";
import axios from "axios";
import Notifications, { notify } from "react-notify-toast";
import Loader from "../../../components/loader/Loader";
import AuthActions from "redux/auth/actions";
import Select from "react-select";
import { CurrencyData } from "util/data/CurrencyData";
import {
  get,
  post,
  put,
  updateProfile
} from "../../../helper/services/authentication";
import { newBusiness } from "../../../helper/services/businessService";
import {
  listUsers,
  addBusiness,
  listBusiness
} from "../../../helper/services/apiConstants";

import currencyAction from "redux/currency/actions";
import authActionUser from "redux/user/actions";

const { login } = AuthActions;
const { setCurrency } = currencyAction;
const { setuser } = authActionUser;

const AddProfile = props => {
  if (!props.history.location.state) {
    props.history.push("/login");
  }

  const [loader, setLoader] = useState(null);
  const [statusUpdate, setStatusUpdate] = useState("");
  const [currency, setCurrency] = useState(CurrencyData[1]);
  const [currencyError, setCurrencyError] = useState(false);
  const handleRegister = async e => {
    let { values, isValid, handleSubmit } = props;

    e.preventDefault();

    if (isValid && currency) {
      if (
        values.businessName &&
        values.email &&
        values.businessOwnerName &&
        values.userAddress
      ) {
        try {
          let historyProps = props.history.location.state;
          setLoader(true);
          const form = new FormData();
          let profilePicture;

          if (selectedImage) {
            profilePicture = { profilePic: selectedImage.file };
          } else {
            profilePicture = { profilePic: "" };
          }
          form.append("address", values.userAddress);
          form.append("email", values.email);
          form.append("contactPersonName", values.businessOwnerName);
          form.append("currency", "DOLLAR");
          setStatusUpdate("Updating Profile");
          let response = await updateProfile(historyProps.token, {
            profilePic: profilePicture.profilePic,
            address: values.userAddress,
            email: values.email,
            contactPersonName: values.businessOwnerName
          });
          if (response) {
            if (response.errors) {
              notify.show(response.errors.message, "error");
            } else setStatusUpdate("Adding New Business");
            /* Add New Business */

            const responseAddBusiness = await newBusiness(
              { name: values.businessName, currency: currency.value },
              historyProps.token
            );
            if (responseAddBusiness) {
              if (responseAddBusiness.errors) {
                notify.show(responseAddBusiness.errors.message, "error");
                setLoader(false);
              } else {
                notify.show("Business created", "success");
                setLoader(false);
                props.setuser({ user: response.user });
                props.setCurrency({ currency: response.user.currency });
                props.login({
                  token: historyProps.token,
                  user: response.user
                });
                props.history.push("/home", {
                  sessionId: historyProps.sessionId,
                  token: historyProps.token,
                  phoneNumber: historyProps.phoneNumber,
                  business: responseAddBusiness.business
                });
              }
            }
          }
        } catch (err) {
          setLoader(false);
          notify.show(err, "error");
        }
      }
    } else {
    }

    handleSubmit();
  };

  const [images, setImages] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const { handleChange, handleBlur, errors, touched, submitCount } = props;

  const handleImageUpload = event => {
    setSelectedImage(event.target.files[0]);
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    setSelectedImage(imageList[0]);
  };
  const loginContainer = {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  const button = {
    fontSize: "16px",
    textTransform: "capitalize",
    boxShadow: "none!important",
    width: "100%",
    borderRadius: "2px",
    fontWeight: "500",
    background: "#0073e6",
    color: " white",
    paddingLeft: "10px",
    textAlign: "center"
  };
  const addImage = {
    position: "absolute",
    color: "green",
    bottom: "78%",
    left: "56%"
  };
  const uplodadButton = {
    position: "absolute"
  };
  return (
    <div className="container-fluid" style={loginContainer}>
      <div>
        <Notifications />
        <div className="form-container col-sm-4 ptb-15">
          <div className="login-icon image-item">
            <ImageUploading
              value={images}
              onChange={onChange}
              dataURLKey="data_url"
            >
              {({
                imageList,
                onImageUpload,
                onImageUpdate,
                onImageRemove,
                isDragging,
                dragProps
              }) => (
                // write your building UI
                <div className="upload__image-wrapper text-center profile-image">
                  <img
                    src={
                      selectedImage && selectedImage.data_url
                        ? selectedImage.data_url
                        : profile
                    }
                    onClick={onImageUpload}
                    id="profilePicture"
                    alt="icon"
                    height="100px"
                  />
                  <a style={addImage}>
                    <i onClick={onImageUpload} className="fa fa-plus"></i>
                  </a>
                  {imageList.map((image, index) => (
                    <div key={index} className="image-item">
                      <div className="image-item__btn-wrapper">
                        <a
                          onClick={() => onImageUpdate(index)}
                          style={{ padding: 10 }}
                        >
                          <i className="fa fa-edit" />
                        </a>
                        <a onClick={() => onImageRemove(index)}>
                          <i className="fa fa-trash" />
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </ImageUploading>
          </div>
          <form className="pa-24" onSubmit={handleRegister}>
            <div className="form-group">
              <label>Business name</label>
              <input
                type="text"
                className="form-control react-form-input"
                id="businessName"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Business name"
              />
              <Error field="businessName" />
            </div>
            <div className="form-group">
              <label>Default business currency</label>
              <Select
                id="currency"
                defaultValue={
                  currency !== "" && currency ? currency : CurrencyData[1]
                }
                label="Select currency"
                options={CurrencyData}
                onChange={e => {
                  setCurrency(e);
                }}
              />
              {currencyError && (
                <span className={"error-msg"}>please select a currency</span>
              )}
            </div>
            <div className="form-group">
              <label>Business owner name</label>
              <input
                type="text"
                className="form-control react-form-input"
                id="businessOwnerName"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Business owner name"
              />
              <Error field="businessOwnerName" />
            </div>
            <div className="form-group">
              <label>Email</label>
              <input
                type="email"
                className="form-control react-form-input "
                id="email"
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Email"
              />
              <Error field="email" />
            </div>{" "}
            <div className="form-group ">
              <label>Address</label>
              <div>
                <textarea
                  className="form-control"
                  rows={2}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  id="userAddress"
                  placeholder="Address"
                />
              </div>
              <Error field="userAddress" />
            </div>
            <div style={{ textAlign: "center" }}>
              {!loader ? (
                <button type="submit" style={button} className="btn button">
                  Save
                </button>
              ) : (
                <Loader />
              )}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  enhancer,
  connect(null, { login, setuser, setCurrency })
)(AddProfile);
