import React, { Component, useState, useEffect } from "react";
import Loader from "../../../components/loader/Loader";
import Select from "react-select";
import { CurrencyData } from "util/data/CurrencyData";

import InputIntl from "components/Placeholder/Placeholder";
import BusinessAlertError from "../../../components/Business/error/AlertError";
import { newBusiness } from "../../../helper/services/businessService";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import IntlMessages from "util/intlMessages";
const AddBusiness = props => {
  const [BusinessLoader, setBusinessLoader] = useState(false);
  // const [currency, setCurrency] = useState(CurrencyData[1]);
  const [addBusinesserrorModal, setAddBusinesserrorModal] = useState({
    status: false,
    errorMessage: ""
  });

  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;
  const errorStyle = {
    color: "red",
    justifyContent: "center",
    fontSize: 15
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const customStyles = {
    paddingTop: "15%",
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  const button = {
    fontSize: "16px",
    textTransform: "capitalize",
    boxShadow: "none!important",
    width: "100%",
    borderRadius: "2px",
    fontWeight: "500",
    background: "#0073e6",
    color: " white",
    paddingLeft: "10px",
    textAlign: "center"
  };
  const handleAddBusinessSubmit = async e => {
    setBusinessLoader(true);
    try {
      e.preventDefault();

      if (e.target.businessName.value.length > 0) {
        //Add business and set the loader
        setAddBusinesserrorModal({
          status: false
        });
        let businessresponse = await newBusiness({
          name: e.target.businessName.value,
          currency: props.currencyAction.currency
        });
        if (businessresponse) {
          if (businessresponse.errors) {
            setBusinessLoader(false);
            setAddBusinesserrorModal({
              status: true,
              errorMessage: businessresponse.errors.message
            });
            //
          } else {
            setBusinessLoader(false);
            props.toggle();
            props.notify("Business created", "success", 5000);
            localStorage.setItem(
              "Briefbook:currentBusiness",
              businessresponse.business.business.id ||
                businessresponse.business.id
            );
            props.setCurrentBusiness({
              id: businessresponse.business.id,
              name: businessresponse.business.name
            });
            props.BusinessList(businessresponse.business.name);
          }
        }
      } else {
        setBusinessLoader(false);
        setAddBusinesserrorModal({
          status: true,
          errorMessage: <IntlMessages id="Please enter business name" />
        });
        props.BusinessList();
      }
    } catch (e) {
      setBusinessLoader(false);
      //
      setAddBusinesserrorModal({
        status: true,
        errorMessage: e
      });
      //
      if (e) {
        //
        setAddBusinesserrorModal({
          status: true,
          errorMessage: e
        });
      }
    }
  };
  return (
    <Modal
      style={customStyles}
      isOpen={props.addbusinessmodal}
      toggle={props.toggle}
    >
      <>
        <form
          onSubmit={e => {
            handleAddBusinessSubmit(e);
          }}
        >
          <ModalHeader toggle={props.toggle}>
            <IntlMessages id="Add New Business" />
          </ModalHeader>
          <ModalBody style={{ paddingBottom: 0, padding: "27px  19px 13px" }}>
            <div>
              {addBusinesserrorModal && addBusinesserrorModal.status ? (
                <BusinessAlertError
                  errorMessage={addBusinesserrorModal.errorMessage}
                />
              ) : (
                ""
              )}
              <div className="form-group">
                <InputIntl
                  placeholderid="Business name"
                  type={"text"}
                  className="form-control react-form-input"
                  id="businessName"
                  onBlur={handleBlur}
                  required
                  onChange={handleChange}
                />
                <Error field="Business name" style={errorStyle} />
              </div>
              {/* <div className="form-group">
                <label>Default business currency</label>
                <Select
                  id="currency"
                  defaultValue={
                    currency !== "" && currency ? currency : CurrencyData[1]
                  }
                  label="Select currency"
                  options={CurrencyData}
                  onChange={e => {
                    setCurrency(e);
                  }}
                />
              </div> */}
            </div>
          </ModalBody>
          <ModalFooter style={{ display: "block", paddingTop: 10 }}>
            <div className="form-group">
              {BusinessLoader ? (
                <Loader />
              ) : (
                <input
                  className="btn btn-button"
                  type="submit"
                  value="Submit"
                  style={button}
                />
              )}
            </div>
          </ModalFooter>
        </form>
      </>
    </Modal>
  );
};
export default AddBusiness;
