import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    mobileNumber: Yup.string()
      .required("Please enter mobile number to login")
      .matches(/^\d+$/, "Please enter only number of exact 10 digits ")
      .min(10, "Mobile number must have exact 10 digits")
      .max(10, "Mobile number must have exact 10 digits")
  }),
  mapPropsToValues: props => ({}),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
