import React from "react";
import { StockSectionWrapper, StockInfoWrapper } from "./StockInfo.style";
import IntlMessages from "util/intlMessages";
import { amountFormatter } from "helper/methods";

const BalanceSheet = ({ section1, section2, currency, ViewReportRedirect }) => {
  return (
    <>
      <StockInfoWrapper className=" whitelight pt-20 flex">
        <StockSection {...section1} currency={currency} amountColor="red" />
        <div className="vertical-hr" />
        <StockSection {...section2} currency={currency} amountColor="green" />
      </StockInfoWrapper>
      <hr></hr>
      <div className="text-center pb-8">
        <span
          style={{
            color: "#0073e6",
            cursor: "pointer",
            textTransform: "uppercase"
          }}
          onClick={() => ViewReportRedirect()}
        >
          <IntlMessages id={"VIEW_REPORT"} />
        </span>
      </div>
    </>
  );
};

const StockSection = ({
  title,
  amount,
  description,
  direction,
  percent,
  amountColor,
  currency
}) => (
  <StockSectionWrapper className="stock-section">
    <div className="section-container">
      <div className="section-3">
        <p className="fs-17 text-center" style={{ color: amountColor }}>
          <i className={currency} />
          <span className="pl-1">{amountFormatter(amount)}</span>
        </p>
        <p className="fs-17 text-center">{title}</p>
        {/* <p className="grey--text">{description}</p> */}
      </div>
      {/* <div className="section-1">
        <i
        //   className={`fas ${
        //     direction === "up" ? "fa-arrow-up" : "fa-arrow-down"
        //   }`}
        />
        ${amount}
      </div> */}
    </div>
  </StockSectionWrapper>
);

export default BalanceSheet;
