// export const BASE_URLold = "https://web.theappauthors.com/briefbooks/api/";
 export const BASE_URL = "https://99.tinyledger.io/api/";
// export const BASE_URL = "http://localhost:8082/api/";

export const STATIC_BASE_URL = "https://tinyledger.netlify.app/";
/* Business  */
export const addBusiness = "business/add-business";
export const listBusiness = "business/list-business";

/* Authentication */

export const verifyOtp = "users/verifyOTP";
export const login = "users/login";
export const ME = "users/me";

/* customers */
export const listCustomer = "customer/";
// export const singleCustomer = "customer/";
export const addCustomer = "customer/add";

/* users */
export const listUsers = "users";

/* Collections */
export const collection = "users/collection/";
export const exportCollection = "users/collection/export/";
/* SUmamry */
export const summary = "users/summary/";
/* Downlaod Backup */
export const backup = "users/backup/generate/";

/* Currency */
export const currency = "users/currency";

/* Language */
export const language = "users/language";
/* Reminders */
export const reminders = "customer/collection/";

/* Transactions */
export const createTransaction = "transaction/create";
/* Delete single Transaction */
export const deleteSingleTransactionRecord = "transaction/single/";
/* Single Transaction */
export const retrieveTransaction = "transaction";

/* Transaction Summary  */
export const summaryTransaction = "users/accountStatement";
export const generatePdf = "users/accountStatement/generate";
export const generateDetailedStatement =
  "users/accountStatement/generateDetailedStatement";
