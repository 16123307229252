import React, { useState } from "react";
import { Alert } from "reactstrap";
const AlertError = (error) => {
  const [visible, setVisible] = useState(true);
  return (
    <Alert
      className="c-"
      style={{ backgroundColor: "RED ", color: "white" }}
      isOpen={visible}
      toggle={() => setVisible(false)}
    >
      {visible ? error.errorMessage.errors.message : ""}
    </Alert>
  );
};
export default AlertError;
