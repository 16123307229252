import authAction from "./actions";

const initState = {
  isLogin: localStorage.getItem("isLogin")
    ? localStorage.getItem("isLogin") === "true"
    : false,
  accessToken: localStorage.getItem("accessToken"),
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case authAction.LOGIN:
      return {
        ...state,
        isLogin: action.isLogin,
        accessToken: action.accessToken,
        user: action.user,
      };
    case authAction.LOGOUT:
      return {
        ...state,
        isLogin: action.isLogin,
        accessToken: null,
        user: null,
      };
    case authAction.UPDATE_AUTH_LANGUAGE:
      return {
        ...state,
        isLogin: state.isLogin,
        accessToken: state.accessToken,
        user: {
          ...state.user,
          language: action.language,
        },
      };
    default:
      return state;
  }
}
