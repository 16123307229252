import React, { useState, useEffect } from "react";
import { loginBack, profile } from "helper/constant";
import { injectIntl, intlShape } from "react-intl";
import { getUser } from "../helper/services/authentication";
import { FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { updateProfile } from "../helper/services/authentication";
import IntlMessages from "util/intlMessages";
import Notifications, { notify } from "react-notify-toast";
import Loader from "../components/loader/Loader";
import ImageUploading from "react-images-uploading";

import { useForm } from "react-hook-form";
import AuthActions from "redux/auth/actions";
import authActionUser from "redux/user/actions";
const { login } = AuthActions;
const { setuser } = authActionUser;
const Profile = props => {
  const [loader, setLoader] = useState(null);
  const [imageFlage, setImageFlag] = useState(false);
  const [images, setImages] = useState(null);

  const [userProfile, setUserProfile] = useState(null);
  const [businessownerName, setBusinessOwnerName] = useState(null);
  const [useremail, setUserEmail] = useState(null);
  const [disabledStatus, setDisabledStatus] = useState(true);
  const [mobileNumber, setMobilenumber] = useState(null);
  const [useraddress, setUserAddress] = useState(null);
  useEffect(() => {
    const test = <IntlMessages id="Name" />;
    getUserData();
  }, []);

  const [selectedImage, setSelectedImage] = useState(null);
  const { register, handleSubmit, errors } = useForm();
  const handleChange = e => {
    //

    switch (e.target.id) {
      case "businessOwnerName": {
        setBusinessOwnerName(e.target.value);
        break;
      }
      case "email": {
        setUserEmail(e.target.value);
        break;
      }
      case "userAddress": {
        setUserAddress(e.target.value);
        break;
      }
      default:
    }
  };
  const getUserData = async () => {
    try {
      setLoader(true);
      const userResponse = await getUser();
      if (userResponse) {
        setDisabledStatus(false);
        setLoader(false);
        const user = userResponse.user;
        //
        setBusinessOwnerName(user.contactPersonName);
        setUserEmail(user.email);
        setUserAddress(user?.address);
        setUserProfile(user?.userMeta[0]?.url);
        setMobilenumber(user?.phoneNumber);
        if (user.userMeta.length > 0) {
          setSelectedImage({
            data_url: user.userMeta[0].url
          });
        }
      }
    } catch (e) {
      //
      notify.show(e, "error", 5000);
      setLoader(false);
    }
  };

  // const PlaceholderMessage = ({ intl }) => {
  //   // const placeholder = intl.formatMessage({ id: "Name" });
  //   const placeholder = <IntlMessages id="Name" />;
  //   console.log("Place holder", placeholder);
  //   // return placeholder
  // };

  // PlaceholderMessage.propTypes = {
  //   intl: intlShape.isRequired,
  // };
  const handleImageUpload = event => {
    setSelectedImage(event.target.files[0]);
    setUserProfile(event.target.files[0]);
  };

  //
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit

    if (imageList[0].file.size > 1 * 10000 * 1024) {
      console.log("File with maximum size of 5MB is allowed");
      notify.show("File with maximum size of 5MB is allowed", "error", 5000);
      return;
    }
    setImages(imageList);
    setSelectedImage(imageList[0]);
    setImageFlag(true);
    setUserProfile(imageList[0]);
  };
  const loginContainer = {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };
  const handleUpdateProfile = async e => {
    setLoader(true);

    try {
      let profilePicture;
      if (selectedImage && imageFlage) {
        profilePicture = { profilePic: selectedImage.file };
      } else {
        profilePicture = { profilePic: "" };
      }

      let response = await updateProfile(props.auth.accessToken, {
        contactPersonName: e.businessOwnerName,
        profilePic: profilePicture.profilePic,
        address: e.userAddress,
        email: e.email
      });
      if (response) {
        const auth = JSON.parse(localStorage.getItem("persist:root"));
        const tokenDb = JSON.parse(auth.auth).accessToken;
        if (props.auth?.user?.language?.toLowerCase() === "english") {
          notify.show("User profile updated", "success", 5000);
        }
        if (props.auth?.user?.language?.toLowerCase() === "gujarati") {
          notify.show(" પ્રોફાઇલ અપડેટ થઈ", "success", 5000);
        }
        if (props.auth?.user?.language?.toLowerCase() === "hindi") {
          notify.show(" प्रोफाइल अपडेट किया गया", "success", 5000);
        }
        // notify.show("User Profile updated!", "success", 5000);
        props.setuser({ user: response.user });
        props.login({ token: tokenDb, user: response.user });
        props.history.go(0);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);

      notify.show(e, "error", 5000);
    }
  };

  const button = {
    fontSize: "16px",
    // textTransform: "capitalize",
    boxShadow: "none!important",
    width: "100%",
    borderRadius: "2px",
    fontWeight: "500",
    background: "#0073e6",
    color: " white",
    paddingLeft: "10px",
    textAlign: "center"
  };

  return (
    <div>
      <div className="container">
        {/* <Notifications /> */}
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />

          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
            <div className="roe-card-style roe-shadow-2 whitelight mb-10">
              <div>
                <div>
                  <Notifications />
                  <div className=" ptb-15">
                    {" "}
                    <form
                      className="pa-24"
                      onSubmit={handleSubmit(handleUpdateProfile)}
                    >
                      <div className="login-icon image-item">
                        <ImageUploading
                          value={images}
                          onChange={onChange}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper text-center profile-image">
                              <img
                                style={{ objectFit: "cover" }}
                                src={
                                  selectedImage &&
                                  selectedImage.data_url &&
                                  userProfile
                                    ? selectedImage.data_url
                                    : profile
                                }
                                onClick={onImageUpload}
                                id="profilePicture"
                                alt="icon"
                                height="100px"
                              />

                              {imageList.length > 0 ? (
                                imageList.map((image, index) => (
                                  <div key={index} className="image-item">
                                    <div className="image-item__btn-wrapper">
                                      <a
                                        onClick={() => onImageUpdate(index)}
                                        style={{ padding: 10 }}
                                      >
                                        <i className="fa fa-edit" />
                                      </a>
                                      <a onClick={() => onImageRemove(index)}>
                                        <i className="fa fa-trash" />
                                      </a>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div key={1} className="image-item">
                                  <div className="image-item__btn-wrapper">
                                    <a
                                      onClick={onImageUpload}
                                      style={{ padding: 10 }}
                                    >
                                      <i className="fa fa-edit" />
                                    </a>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </ImageUploading>
                      </div>

                      <div className="form-group">
                        <label>
                          <IntlMessages id="Name" />
                        </label>
                        <input
                          type="text"
                          className="form-control react-form-input"
                          id="businessOwnerName"
                          name="businessOwnerName"
                          defaultValue={businessownerName}
                          disabled={disabledStatus}
                          ref={register({
                            required: "Please enter name"
                          })}
                          // onChange={handleChange}
                          placeholder={
                            props.auth?.user?.language?.toLowerCase() ==
                            "english"
                              ? "Name"
                              : props.auth?.user?.language?.toLowerCase() ==
                                "hindi"
                              ? "नाम"
                              : props.auth?.user?.language?.toLowerCase() ==
                                "gujarati"
                              ? "નામ"
                              : "નામ"
                          }
                        />
                        <span style={{ color: "red" }}>
                          {errors.businessOwnerName &&
                          errors.businessOwnerName.message ? (
                            <IntlMessages
                              id={errors.businessOwnerName.message}
                            />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>
                          <IntlMessages id="Email" />
                        </label>

                        <input
                          type="email"
                          className="form-control react-form-input "
                          disabled={disabledStatus}
                          id="email"
                          name="email"
                          placeholder={
                            props.auth?.user?.language?.toLowerCase() ==
                            "english"
                              ? "Email"
                              : props.auth?.user?.language?.toLowerCase() ==
                                "hindi"
                              ? "ईमेल"
                              : props.auth?.user?.language?.toLowerCase() ==
                                "gujarati"
                              ? "ઇમેઇલ"
                              : ""
                          }
                          defaultValue={useremail}
                          ref={register({
                            required: "Please enter email",
                            pattern: {
                              value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                              message: "Please enter valid email"
                            }
                          })}
                        />
                        <span style={{ color: "red" }}>
                          {errors.email && errors.email.message ? (
                            <IntlMessages id={errors.email.message} />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      <div className="form-group">
                        <label>
                          <IntlMessages id="Mobile number" />
                        </label>
                        <input
                          type="tel"
                          className="form-control react-form-input "
                          disabled={true}
                          id="phoneNumber"
                          name="phoneNumber"
                          style={{
                            cursor: "not-allowed"
                          }}
                          defaultValue={mobileNumber}
                        />
                      </div>
                      <div className="form-group ">
                        <label>
                          <IntlMessages id="Address" />
                        </label>
                        <div>
                          <textarea
                            className="form-control"
                            rows={2}
                            disabled={disabledStatus}
                            onChange={handleChange}
                            defaultValue={
                              useraddress !== null ? useraddress : ""
                            }
                            id="userAddress"
                            placeholder={
                              props.auth?.user?.language?.toLowerCase() ==
                              "english"
                                ? "Address"
                                : props.auth?.user?.language?.toLowerCase() ==
                                  "hindi"
                                ? "पता"
                                : props.auth?.user?.language?.toLowerCase() ==
                                  "gujarati"
                                ? "સરનામું"
                                : ""
                            }
                            name="userAddress"
                            ref={register({
                              required: "Please enter address"
                            })}
                          />
                        </div>
                        <span style={{ color: "red" }}>
                          {errors.userAddress && errors.userAddress.message ? (
                            <IntlMessages id={errors.userAddress.message} />
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        {!loader ? (
                          <button
                            type="submit"
                            style={button}
                            className="btn button"
                          >
                            <IntlMessages id="Update Profile" />
                          </button>
                        ) : (
                          <Loader />
                        )}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    auth: state.auth
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, { login, setuser })
)(Profile);
