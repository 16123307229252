export const CurrencyData = [
  { value: "INR", label: "₹ INR" },
  {
    value: "DOLLAR",
    label: "$ DOLLAR"
  },
  { value: "EURO", label: "€ EURO" },
  { value: "POUND", label: "£ POUND" },
  { value: "YEN", label: "¥ YEN" }
];
