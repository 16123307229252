export const AppName = "Tiny Ledger";
export const drawerWidth = "260px";
export const miniDrawerWidth = "80px";
export const themeSettingDrawerWidth = "300px";
export const chatDrawerWidth = "260px";
export const chatMiniDrawerWidth = "0px";
export const profile = require("assets/images/profilepic.jpg");
export const englishlanguage = require("assets/images/language/English.png");
export const indianlanguage = require("assets/images/language/flagIndia.png");
export const loginBack = require("assets/images/loginback.jpg");
// export const iconDemo = require("assets/images/roelogo.png");
// export const phoneDemo = require("assets/images/phonedemoold.jpeg");
export const phoneDemo = require("assets/images/phonedemo.png");
export const selected = require("assets/images/utils/selected.png");
export const Notselected = require("assets/images/utils/NotSelected.png");
export const customer = require("assets/images/customers.png");
export const balance = require("assets/images/wallet_blue.png");
export const badge_received = require("assets/images/badge_received@3x.png");

export const collection = require("assets/images/collection.png");
export const backup = require("assets/images/backup.png");
export const trialbalance = require("assets/images/document.png");
export const greaterThan = require("assets/images/arrow@2x.png");
export const ForgotIcon = require("assets/images/forgotpassword.svg");
export const locakscreenBack = require("assets/images/lockscreen.jpg");
export const ProfileLockScreen = require("assets/images/profile.jpg");
export const errorback = require("assets/images/errorback.jpg");
// export const appLogoold = require("assets/images/briefbookLogo.png");
export const appLogo = require("assets/images/tinyledger logowhite.png");
export const appLogoCleaner = require("assets/images/app_icon_clear.png");
export const currency = require("assets/images/currency.png");
export const privacy = require("assets/images/icon_privacy.png");
export const aboutus = require("assets/images/icon_aboutus.png");
export const reminders = require("assets/images/reminder.png");
export const language = require("assets/images/language@3x.png");
export const help = require("assets/images/icon_help.png");
export const share = require("assets/images/icon_share_app.png");
export const logout = require("assets/images/signout.png");
export const feed1 = require("assets/images/feed1.jpg");
export const feed2 = require("assets/images/feed2.jpg");
export const friend1 = require("assets/images/friend1.jpg");
export const friend2 = require("assets/images/friend2.jpg");
export const friend3 = require("assets/images/friend3.jpg");
export const friend4 = require("assets/images/friend4.jpg");
export const friend5 = require("assets/images/friend5.jpg");
export const friend6 = require("assets/images/friend6.jpg");
export const media1 = require("assets/images/media1.jpeg");
export const media2 = require("assets/images/media2.jpeg");
export const media3 = require("assets/images/media3.jpeg");
export const media4 = require("assets/images/media4.jpeg");
export const media5 = require("assets/images/media5.jpeg");
export const media6 = require("assets/images/media6.jpeg");
export const media7 = require("assets/images/media7.jpeg");
export const media8 = require("assets/images/media8.jpeg");
export const media9 = require("assets/images/media9.jpeg");
export const sidebar1 = require("assets/images/sidebar1.jpg");
export const sidebar2 = require("assets/images/sidebar2.jpg");
export const sidebar3 = require("assets/images/sidebar3.jpg");
export const sidebar4 = require("assets/images/sidebar4.jpg");
export const sidebar5 = require("assets/images/sidebar5.jpg");
export const sidebar6 = require("assets/images/sidebar6.jpg");
export const sidebar7 = require("assets/images/sidebar7.jpg");
export const sidebar8 = require("assets/images/sidebar8.jpg");
export const Img6464 = require("assets/images/6464Img.svg");
// export const boardBanner = require("assets/images/boardbanner.jpeg");

export const people1 = require("assets/images/boardImages/people1.jpeg");
export const people2 = require("assets/images/boardImages/people2.jpeg");
export const people3 = require("assets/images/boardImages/people3.jpeg");
// export const people4 = require("assets/images/boardImages/people4.jpeg");
// export const people5 = require("assets/images/boardImages/people5.jpeg");
// export const people6 = require("assets/images/boardImages/people6.jpeg");
// export const people7 = require("assets/images/boardImages/people7.jpeg");
// export const people8 = require("assets/images/boardImages/people8.jpeg");
// export const people9 = require("assets/images/boardImages/people9.jpeg");
// export const people10 = require("assets/images/boardImages/people10.jpeg");
// export const people11 = require("assets/images/boardImages/people11.jpeg");
// export const people12 = require("assets/images/boardImages/people12.jpeg");
// export const people13 = require("assets/images/boardImages/people13.jpeg");
// export const people14 = require("assets/images/boardImages/people14.jpeg");
// export const people15 = require("assets/images/boardImages/people15.jpeg");

// export const cover1 = require("assets/images/boardImages/cover1.jpeg");
// export const cover2 = require("assets/images/boardImages/cover2.jpeg");
// export const cover3 = require("assets/images/boardImages/cover3.jpeg");
// export const cover4 = require("assets/images/boardImages/cover4.jpg");
// export const cover5 = require("assets/images/boardImages/cover5.jpeg");
// export const cover6 = require("assets/images/boardImages/cover6.jpeg");
// export const cover7 = require("assets/images/boardImages/cover7.jpeg");
// export const cover8 = require("assets/images/boardImages/cover8.jpeg");
// export const cover9 = require("assets/images/boardImages/cover9.jpeg");
// export const cover10 = require("assets/images/boardImages/cover10.jpeg");

// File Manager

// export const folder = require("assets/images/filemanager/folder.jpg");
// export const file1 = require("assets/images/filemanager/file1.jpg");
// export const file2 = require("assets/images/filemanager/file2.jpg");
// export const file3 = require("assets/images/filemanager/file3.jpg");
// export const file4 = require("assets/images/filemanager/file4.jpg");
// export const file5 = require("assets/images/filemanager/file5.jpg");
// export const file6 = require("assets/images/filemanager/file6.jpg");
// export const file7 = require("assets/images/filemanager/file7.jpg");
// export const file8 = require("assets/images/filemanager/file8.jpg");
// export const file9 = require("assets/images/filemanager/file9.jpg");
// export const file10 = require("assets/images/filemanager/file10.jpg");
// export const file11 = require("assets/images/filemanager/file11.jpg");
// export const file12 = require("assets/images/filemanager/file12.jpg");
// export const file13 = require("assets/images/filemanager/file13.jpg");
// export const file14 = require("assets/images/filemanager/file14.jpg");
// export const file15 = require("assets/images/filemanager/file15.jpg");
// export const file16 = require("assets/images/filemanager/file16.jpg");
// export const file17 = require("assets/images/filemanager/file17.jpg");
// export const file18 = require("assets/images/filemanager/file18.jpg");

// Demos

export const demo1 = require("assets/images/themes/demo2.png");
export const demo2 = require("assets/images/themes/demo2.png");
export const demo3 = require("assets/images/themes/demo3.png");
export const demo4 = require("assets/images/themes/demo4.png");
export const demo5 = require("assets/images/themes/demo5.png");
