import { withFormik } from "formik";
import * as Yup from "yup";

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    businessName: Yup.string()
      .required("Please enter business name")
      .min(2, "Minimun 2 characters required")
      .max(80, "Maximum must be of 80 characters"),
    userAddress: Yup.string()
      .required("Please enter Addres")
      .min(2, "Minimun 2 characters required"),
    businessOwnerName: Yup.string()
      .required("Please Enter Business owner name")
      .min(2, "Minimun 2 characters required")
      .max(150, "Maximum must be of 80 characters"),
    email: Yup.string()
      .required("Please enter email")
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        "Email must have @example.com"
      )
      .email()
  }),
  handleSubmit: values => {},
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
