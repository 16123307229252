import React from "react";
import { injectIntl, intlShape } from "react-intl";

const TextAreaIntl = (props) => {
  const placeholder = props.intl.formatMessage({ id: props.placeholderid });
  return <textarea placeholder={placeholder} {...props} />;
};

TextAreaIntl.propTypes = {
  intl: intlShape.isRequired,
};

export default injectIntl(TextAreaIntl);
