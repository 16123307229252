import { themeConfig } from "./index";

const changeThemes = {
  id: "changeThemes",
  label: "themeChanger",
  defaultTheme: themeConfig.theme,
  options: [
    {
      themeName: "themedefault",
      buttonColor: "#ffffff",
      textColor: "#323332"
    }
  ]
};

const topbarTheme = {
  id: "topbarTheme",
  label: "themeChanger.topbar",
  defaultTheme: themeConfig.topbar,
  options: [
    {
      themeName: "Tiny Ledger",
      buttonBackColor: "#FFF",
      buttonColor: "linear-gradient(45deg,#F5F7FA,#B8C6DB)",
      backgroundColor: "#0073e6",
      textColor: "#ffff",
      badgeBackColor: "#5C258D",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#5C258D",
      horSidebarActiveBackColor: "#fff",
      horSidebarActiveTextColor: "#000"
    },
    {
      themeName: "gredient8",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      backgroundColor: "#0073e6",
      textColor: "#ffff",
      badgeBackColor: "#FF6B6B",
      badgeTextColor: "#fff",
      breadCumbActiveColor: "#FF6B6B",
      horSidebarActiveBackColor: "#fff",
      horSidebarActiveTextColor: "#000"
    }
  ]
};

const sidebarTheme = {
  id: "sidebarTheme",
  label: "themeChanger.sidebar",
  defaultTheme: themeConfig.sidebar,
  options: [
    {
      themeName: "Tiny Ledger",
      backgroundColor: "#ffff",
      textColor: "#000000 ",
      activeRouteTextColor: "#013e8a",
      buttonColor: "rgba(0,0,0,.2)",
      activeRouteBackColor: "#FFF",
      style4VarientActiveTextColor: "#F4C95D",
      sideOpenListBackground: "rgba(0, 0, 0, .05)"
    },
    {
      themeName: "gredient8",
      backgroundColor: "#fff",
      textColor: "rgba(0, 0, 0, .87)",
      activeRouteTextColor: "#FF6B6B",
      buttonColor: "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      activeRouteBackColor:
        "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      style4VarientActiveTextColor: "#FF6B6B",
      sideOpenListBackground: "rgba(0, 0, 0, .035)",
      type: "gredient"
    }
  ]
};

const layoutTheme = {
  id: "layoutTheme",
  label: "themeChanger.background",
  defaultTheme: themeConfig.layout,
  options: [
    {
      themeName: "themedefault",
      buttonColor: "#fafafa",
      backgroundColor: "rgb(249, 249, 249)",
      cardBackground: "#fff",
      headingColor: "#6a727a",
      textColor: "#000"
    },
    {
      themeName: "theme1",
      buttonColor: "#ffffff",
      backgroundColor: "#ffffff",
      cardBackground: "#fff",
      headingColor: "#6a727a",
      textColor: "#000"
    },
    {
      themeName: "theme2",
      buttonColor: "#F9F9F9",
      backgroundColor: "#F9F9F9",
      cardBackground: "#fff",
      headingColor: "#6a727a",
      textColor: "#000"
    },
    {
      themeName: "theme3",
      buttonColor: "#ebebeb",
      backgroundColor: "#ebebeb",
      cardBackground: "#fff",
      headingColor: "#6a727a",
      textColor: "#000"
    },
    {
      themeName: "theme4",
      buttonColor: "#D9D9D9",
      backgroundColor: "#D9D9D9",
      cardBackground: "#fff",
      headingColor: "#6a727a",
      textColor: "#000"
    },
    {
      themeName: "theme5",
      buttonColor: "#eef5f9",
      backgroundColor: "#eef5f9",
      cardBackground: "#fff",
      headingColor: "#000",
      textColor: "#000"
    },
    {
      themeName: "theme6",
      buttonColor: "#2d3038",
      backgroundColor: "#2d3038",
      cardBackground: "rgba(68, 70, 79, 0.5)",
      headingColor: "#fff",
      textColor: "#949ba2"
    },
    {
      themeName: "theme7",
      buttonColor: "#1c1e2f",
      backgroundColor: "#1c1e2f",
      cardBackground: "#222437",
      headingColor: "#fff",
      textColor: "#949ba2"
    }
  ]
};

const footerTheme = {
  id: "footerTheme",
  label: "themeChanger.footer",
  defaultTheme: themeConfig.footer,
  options: [
    {
      themeName: "sky",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,#F5F7FA, rgb(10, 191, 188))",
      backgroundColor: "linear-gradient(45deg,#F5F7FA, rgb(10, 191, 188))",
      textColor: "rgba(0, 0, 0, .65)"
    },
    {
      themeName: "gredient8",
      buttonBackColor: "rgba(0, 0, 0, .1)",
      buttonColor: "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      backgroundColor: "linear-gradient(45deg,rgba(216,241,230,0.46),#e2ebf0)",
      textColor: "rgba(0,0,0,0.8)",
      type: "gredient"
    }
  ]
};

const customizedThemes = {
  changeThemes,
  topbarTheme,
  sidebarTheme,
  layoutTheme,
  footerTheme
};

export function getCurrentTheme(attribute, selectedThemename) {
  let selecetedTheme = {};
  customizedThemes[attribute].options.forEach(theme => {
    if (theme.themeName === selectedThemename) {
      selecetedTheme = theme;
    }
  });
  return selecetedTheme;
}
export default customizedThemes;
