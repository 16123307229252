import axios from "axios";
import handleError from "./handleErrors";
import { BASE_URL, listBusiness, addBusiness } from "./apiConstants";
import getHeader from "./getHeaders";
import handleResponse from "./handleResponse";

export function fetchBusinessList(token) {
  let header;

  if (!token || token == null) {
    header = getHeader();
  } else {
    header = getHeader(token);
  }
  const requestOptions = {
    method: "GET",
    headers: header
  };
  return fetch(BASE_URL + listBusiness, requestOptions)
    .then(handleResponse)
    .then(business => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return business;
    });
}

export function newBusiness(data, token) {
  let header;

  if (!token || token == null) {
    header = getHeader();
  } else {
    header = getHeader(token);
  }

  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      name: data.name,
      currency: data.currency
    })
  };
  return fetch(BASE_URL + addBusiness, requestOptions)
    .then(handleResponse)
    .then(business => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return business;
    });
}

//     post(addBusiness, historyProps.token, { name: values.businessName })
