import React, { useState } from "react";
import { phoneDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { getCurrentLanguage } from "settings/languageConfig";
import AuthActions from "redux/auth/actions";
import authActionUser from "redux/user/actions";
import currencyAction from "redux/currency/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import OtpInput from "react-otp-input";
import Notifications, { notify } from "react-notify-toast";
import Loader from "../../../components/loader/Loader";
import languageActions from "redux/languageSwitcher/actions";
import { verify } from "../../../helper/services/authentication";
import { fetchBusinessList } from "../../../helper/services/businessService";
const { login } = AuthActions;
const { setuser } = authActionUser;
const { setCurrency } = currencyAction;
const { changeLanguage } = languageActions;
const VerificationOtp = props => {
  if (props) {
    // const db = JSON.parse(localStorage.getItem('persist:root'))
    // const getLogin = JSON.parse(db.auth).isLogin
    // if (getLogin) {
    //     props.history.push('/home')
    // }
  }
  if (!props.history.location.state) {
    props.history.push("/login");
  }
  let historyProps = props.history.location.state;
  const [loader, setLoader] = useState(false);
  const [OTP, setOTP] = useState("");
  const [resend, setResend] = useState("text-center link-label");

  const fetchBusiness = async (token, user) => {
    try {
      /* TODO:make all methods of fetch  */
      let response = await fetchBusinessList(token);
      if (response) {
        if (response.business.length === 0) {
          setLoader(false);
          props.history.push("/complete-your-profile", {
            sessionId: historyProps.sessionId,
            token: token,
            phoneNumber: historyProps.phoneNumber
          });
        } else {
          /* Only storing business if  already old user is login  for complete profile its remaining */
          setLoader(false);
          props.setuser({ user });
          props.setCurrency({ currency: user.currency });
          props.changeLanguage(user.language.toLowerCase());
          getCurrentLanguage(user.language.toLowerCase());
          props.login({ token: token, user: user });

          props.history.push("/home", {
            sessionId: historyProps.sessionId,
            token: token,
            phoneNumber: historyProps.phoneNumber,
            business: response.business
          });
        }
      }
    } catch (e) {
      setLoader(false);
      if (e.response) {
        notify.show(e, "error");
      } else console.log("errors", e);
    }
  };

  const handleLogin = async e => {
    try {
      e.preventDefault();
      let { values, handleSubmit } = props;

      if (OTP.length > 0) {
        setLoader(true);

        let dataVerify = {
          otpInput: "1111",
          sessionId: historyProps.sessionId
        };
        let response = await verify(dataVerify, historyProps.token);
        if (response) {
          fetchBusiness(response.token, response.user, historyProps.token);
        }
      } else {
        notify.show("please Enter Otp ", "error");
      }
      handleSubmit();
    } catch (e) {
      setLoader(false);
      console.log("error ", e);
      notify.show(e, "error");
    }
  };

  const resendCoder = async () => {
    try {
      setLoader(true);
      let dataVerify = { otpInput: "11111", sessionId: historyProps.sessionId };
      let response = await verify(dataVerify, historyProps.token);
      if (response) {
        setResend("text-center");
        notify.show(
          "OTP sent successfully to +91 " +
            props.history.location.state.phoneNumber,
          "success",
          5000
        );
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      notify.show(err.response.data.errors.message, "error", 5000, "red");
    }
  };

  const { values, handleBlur, errors, touched, submitCount } = props;

  const loginContainer = {
    // backgroundImage: `url(${loginBack
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };
  const button = {
    fontSize: "16px",
    textTransform: "capitalize",
    boxShadow: "none!important",
    width: "50%",
    borderRadius: "2px",
    fontWeight: "500",
    background: "#0073e6",
    color: " white",
    paddingLeft: "10px",
    verticalAlign: "middle"
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <Notifications />
      <div className="form-container col-sm-4 ptb-15">
        <div className="login-icon">
          <img src={phoneDemo} alt="icon" height="150px" />
        </div>
        <div className="login-title">Enter verification code</div>
        <div className="text-center form-info-text plr-24 mt-16">
          We have sent you a code via SMS for Mobile number verification
        </div>
        <form className="pa-24" onSubmit={handleLogin}>
          <OtpInput
            shouldAutoFocus
            inputStyle={{ width: "2.2rem", padding: 5 }}
            value={OTP}
            onChange={setOTP}
            numInputs={5}
            containerStyle="justify-content-center"
            separator={
              <span style={{ display: "inline-block" }}>&nbsp;&nbsp;</span>
            }
          />

          <div className="text-center form-info-text plr-24 mt-16">
            Didn't get the code ?{" "}
            <b className={resend} onClick={resendCoder}>
              Resend code
            </b>
          </div>
          <br />
          <div style={{ textAlign: "center" }}>
            {!loader ? (
              <input
                type="submit"
                value={"Submit"}
                disabled={OTP.length === 5 ? false : true}
                className="btn "
                style={button}
              />
            ) : (
              <Loader />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default compose(
  withRouter,
  enhancer,
  connect(null, { login, setuser, setCurrency, changeLanguage })
)(VerificationOtp);
