import React, { useState, useEffect } from "react";
import sortBy from "lodash/sortBy";
import IntlMessages from "util/intlMessages";
import LatestActivityWrapper from "./Reminders";
import { people1, people2, people3, profile } from "../../../helper/constant";
import ShimmerLoader from "../../Customer/shimmer/ShimmerLoader";
import moment from "moment";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
  ListGroup,
  ListGroupItem
} from "reactstrap";

import { fetchReminders } from "../../../helper/services/settingsService";
import intlMessages from "util/intlMessages";
const profileImage = {
  borderRadius: "50%",
  border: "1 solid",
  width: 42,
  background: "#0073e6",
  height: 41,
  objectFit: "fill"
};
const InCap = {
  lineHeight: 2,
  fontSize: 20,
  color: "white",
  textAlign: "center",
  paddingLeft: "13px"
};

//https: thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
const RemindersList = props => {
  const [loader, setLoader] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [onMouseHover, setOnMouseHover] = useState(false);
  const [transactionReminders, setTransactionReminders] = useState(null);
  useEffect(() => {
    getReminders();
  }, [currencySymbol]);
  const updateCurrencySymbol = currency => {
    // console.log("setting currency---------", currency);

    switch (currency) {
      case "INR": {
        setCurrencySymbol("fa fa-rupee-sign");

        // console.log("currency inr");
        break;
      }
      case "DOLLAR": {
        setCurrencySymbol("fa fa-dollar-sign");
        // console.log("DOLLAR");
        break;
      }
      case "EURO": {
        setCurrencySymbol("fa fa-euro-sign");
        break;
      }
      case "POUND": {
        setCurrencySymbol("fa fa-pound-sign");
        break;
      }
      case "YEN": {
        setCurrencySymbol("fa fa-yen-sign");
        break;
      }
      default: {
      }
    }
  };
  const getReminders = async () => {
    setLoader(true);
    try {
      updateCurrencySymbol(props.currency);
      const currentbusinessDb = localStorage.getItem(
        "Briefbook:currentBusiness"
      );
      if (currentbusinessDb) {
        const remindersResponse = await fetchReminders(currentbusinessDb);
        if (remindersResponse) {
          // console.log("reminders", remindersResponse.transaction);
          // console.log("lenght", remindersResponse.transaction.length);
          setLoader(false);
          setTransactionReminders(remindersResponse.transaction);
        }
      }
    } catch (e) {
      console.log("error in reminders", e);
    }
  };

  //console.log(finalArr);

  function onHoverStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
    // document.getElementById(div).style.cursor = "pointer";
    // e.target.style.background = "#f5f5f5";
  }
  function onMouseLeave(div) {
    document.getElementById(div).style.background = "#fff";
    // e.target.style.background = "#fff";
  }
  // let ans = getData(props.customerData);
  // //console.log("concat", filteredArray);
  return (
    <Modal
      isOpen={props.reminders}
      toggle={props.toggle}
      style={{ marginTop: "12%" }}
    >
      <ModalHeader toggle={props.toggle}>
        <div className="">
          <IntlMessages id="Reminders" />
        </div>
      </ModalHeader>
      <ModalBody>
        <LatestActivityWrapper className="fill-height">
          <div>
            {/* {console.log("filtered array", finalArr)} */}
            {loader ? (
              <ShimmerLoader props={props} />
            ) : transactionReminders && transactionReminders.length > 0 ? (
              <div>
                <p className="text-muted text-left mb-2">
                  {transactionReminders && transactionReminders.length}{" "}
                  <IntlMessages id="Reminders" />
                </p>

                {transactionReminders !== undefined &&
                transactionReminders !== null &&
                transactionReminders.length > 0
                  ? transactionReminders.map((transaction, i) => {
                      return (
                        <div
                          // style={onMouseHover ? onHoverStyle : {}}
                          className="flex-x ptb-2 mb-6 border-bottom "
                          key={i}
                          id={`divqw${i}`}
                          onMouseEnter={() => onHoverStyle(`divqw${i}`)}
                          onMouseLeave={() => onMouseLeave(`divqw${i}`)}
                        >
                          {transaction.userMeta !== undefined &&
                          transaction.userMeta.length > 0 ? (
                            <div>
                              <div>
                                <img
                                  style={{
                                    borderRadius: "50%",
                                    // border: "1px solid ",
                                    width: 42,
                                    height: 41,
                                    objectFit: "contain"
                                  }}
                                  src={transaction.userMeta.map(url => {
                                    return url.thumbnail;
                                  })}
                                  alt="profile"
                                />
                              </div>
                            </div>
                          ) : (
                            <div style={profileImage}>
                              <span style={InCap}>
                                {transaction.customer.name
                                  .charAt(0)
                                  .toUpperCase()}
                              </span>
                            </div>
                          )}
                          <div
                            className=" pb-8 mlr-10 flex-1"
                            style={{ textAlign: "left" }}
                          >
                            <p
                              className=" fs-16 demi-flex-start "
                              // style={{ marginBottom: -3 }}
                            >
                              {transaction.customer.name}
                            </p>
                            <small
                              className="fs-14 text-left"
                              style={{ color: "red" }}
                              key={moment(new Date(transaction.balanceDueDate))}
                            >
                              <i className={props.currencySymbol}> </i>
                              {transaction.balance.toString().replace("-", "")}
                              {"  "}
                              <IntlMessages id="Due" />
                            </small>
                          </div>
                          <div>
                            <div>
                              <div>
                                <small
                                  key={
                                    moment(
                                      new Date(transaction.balanceDueDate)
                                    ) + 2
                                  }
                                >
                                  {/* <i className={props.currency}></i> */}
                                  {moment(
                                    new Date(transaction.balanceDueDate)
                                  ).format("YYYY-MM-DD")}
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  : ""}
              </div>
            ) : (
              <p className="fs-20 bold-text px15 board-list-title text-muted">
                <IntlMessages id="No reminders" />
              </p>
            )}
          </div>
        </LatestActivityWrapper>
      </ModalBody>{" "}
    </Modal>
  );
};

export default RemindersList;
