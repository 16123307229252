import styled from "styled-components";
const SocialQuoteWrapper = styled.div`
  .profile-widget-image {
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .transaction-image {
    img {
      width: 80px;
      height: 71px;
      padding-left: 10px;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .button {
    fontsize: 16px;
    texttransform: capitalize;
    boxshadow: none !important;
    width: 100%;
    borderradius: 2px;
    fontweight: 500;
    background: #a70909;
    color: white;
    paddingleft: 10px;
    textalign: center;
  }
  .inline-flex {
    display: inline-flex !important;
  }
  .green {
    color: green;
  }
  .red {
    color: red;
  }
`;

export default SocialQuoteWrapper;
