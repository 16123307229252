import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { compose } from "redux";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import base64 from "base64topdf";
import Loader from "components/loader/Loader";
import moment from "moment";
import { fetchDetailsStatementPDF } from "helper/services/transactionReport";
import { amountFormatter, calculateTotalAmount } from "helper/methods";
const DetailedStatement = props => {
  const [trialBalanceData, setTrialBalanceData] = useState(
    props?.location?.state?.trialBalanceData
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [isDownloadLoader, setDownloadLoader] = useState(false);
  useEffect(() => {
    if (props?.location?.state) {
      setTrialBalanceData(props?.location?.state?.trialBalanceData);
      setStartDate(moment(new Date(props.location.state.startDate)));
      setEndDate(moment(new Date(props.location.state.endDate)));
      setCurrencySymbol(props.currencyAction.symbol);
    }
  }, [props?.location?.state?.trialBalanceData]);

  const saveFile = async blob => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "TenyLedgerTrialBalance-DetailedStatement.PDF";
    a.click();
    window.URL.revokeObjectURL(a.href);
  };

  const detailStatementToPDF = async () => {
    setDownloadLoader(true);
    const summaryResponse = await fetchDetailsStatementPDF(
      startDate.toString(),
      endDate.toString(),
      props.customerListAction.businessId
    );
    if (summaryResponse.errors) {
      console.log("errors");
    } else {
      // var a = document.createElement("a"); //Create <a>
      // a.href = "data:PDF;base64," + summaryResponse.accountStatement; //Image Base64 Goes here
      // a.download = "TenyLedgerTrialBalance-DetailedStatement.PDF"; //File name Here
      // a.click(); //Downloaded file
      const blob = new Blob([summaryResponse], { type: "application/pdf" });
      saveFile(blob);
      setDownloadLoader(false);
    }
  };
  return (
    <div>
      <div className="container">
        {/* <Notifications /> */}
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />

          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
            <section>
              <nav
                style={{
                  marginBottom: 10
                }}
                className="custom-breadcumb"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className="breadcumb-color breadcrumb-item">
                    <a
                      id={32}
                      style={{
                        color: "gray",
                        cursor: "pointer"
                      }}
                      onMouseEnter={() => {
                        document.getElementById(32).style.textDecoration =
                          "underline";
                      }}
                      onMouseLeave={() => {
                        document.getElementById(32).style.textDecoration =
                          "none";
                      }}
                      onClick={() => {
                        props.location.state &&
                        props.location.state.page &&
                        props.location.state.page == "account"
                          ? props.history.push("/account")
                          : props.history.push("/home");
                      }}
                    >
                      {props.location.state &&
                      props.location.state.page &&
                      props.location.state.page == "account" ? (
                        <IntlMessages id="sidebar.Account" />
                      ) : (
                        <IntlMessages id="sidebar.Home" />
                      )}
                      {/* {props.SelectCustomerAction?.customer.name}'s */}
                    </a>
                  </li>
                  <li className="breadcumb-color breadcrumb-item">
                    <a
                      id={33}
                      style={{
                        color: "gray",
                        cursor: "pointer"
                      }}
                      onMouseEnter={() => {
                        document.getElementById(33).style.textDecoration =
                          "underline";
                      }}
                      onMouseLeave={() => {
                        document.getElementById(33).style.textDecoration =
                          "none";
                      }}
                      onClick={() => props.history.goBack()}
                    >
                      {/* {props.SelectCustomerAction?.customer.name}'s */}
                      <IntlMessages id="Trial balance" />
                    </a>
                  </li>
                  <li
                    className="active breadcrumb-item"
                    style={{
                      color: "#0073e6",
                      wordWrap: "break-word"
                    }}
                    aria-current="page"
                  >
                    <span>
                      <IntlMessages id="Detailed  statement" />
                    </span>
                  </li>
                </ol>
              </nav>
            </section>

            <div className="roe-card-style roe-shadow-2 whitelight mb-10">
              <div className="roe-card-header">
                <span>
                  <IntlMessages id="Detailed  statement" />{" "}
                </span>
              </div>
              <div className="roe-card-body">
                <div className="row">
                  <span className="col-lg-11 col-md-11 col-sm-11 col-xs-12">
                    {startDate && endDate
                      ? `From : ${moment(startDate).format(
                          "DD-MM-YYYY"
                        )} To : ${moment(endDate).format("DD-MM-YYYY")}`
                      : ""}{" "}
                  </span>
                  <span className="col-lg-1 col-md-1 col-sm-1 col-xs-12 ">
                    {isDownloadLoader ? (
                      <Loader height="18px" width="18px" />
                    ) : (
                      <i
                        className="fa fa-download"
                        onClick={() => detailStatementToPDF()}
                      />
                    )}
                  </span>
                </div>
                <Table striped borderless responsive>
                  <thead
                    style={{
                      backgroundColor: "#c1d6e8"
                    }}
                  >
                    <tr>
                      <th>
                        <IntlMessages id="Account" />
                      </th>
                      <th>
                        <IntlMessages id="Received" />
                      </th>
                      <th>
                        <IntlMessages id="Paid" />
                      </th>
                      <th>
                        <IntlMessages id="Balance" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {trialBalanceData &&
                      trialBalanceData.allTransaction &&
                      trialBalanceData.allTransaction.length > 0 &&
                      trialBalanceData.allTransaction.map((data, i) => {
                        return (
                          <tr key={i * 5522}>
                            <th scope="row">{data.customerName}</th>

                            <td>
                              {data.direction === "INBOUND" ? (
                                <span
                                  style={{
                                    color: "green"
                                  }}
                                >
                                  <i className={currencySymbol} />
                                  {data.amount.toLocaleString()}
                                </span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td>
                              {data.direction === "OUTBOUND" ? (
                                <span
                                  style={{
                                    color: "red"
                                  }}
                                >
                                  <i className={currencySymbol} />
                                  {data.amount.toLocaleString()}
                                </span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td
                              style={{
                                color:
                                  data.direction === "OUTBOUND"
                                    ? "red"
                                    : "green"
                              }}
                            >
                              <i className={currencySymbol} />
                              {data.amount.toLocaleString()}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                  <tfoot
                    style={{
                      backgroundColor: "#c1d6e8"
                    }}
                  >
                    <tr>
                      <th>
                        <IntlMessages id="Total" />
                      </th>
                      <td
                        style={{
                          color: "green"
                        }}
                      >
                        {trialBalanceData &&
                        trialBalanceData.totalCreditAmount ? (
                          <>
                            <i className={currencySymbol} />{" "}
                            {trialBalanceData.totalCreditAmount.toLocaleString()}
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        style={{
                          color: "red"
                        }}
                      >
                        {trialBalanceData &&
                        trialBalanceData.totalPayableAmount ? (
                          <>
                            <i className={currencySymbol} />
                            {trialBalanceData.totalPayableAmount.toLocaleString()}
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                      <td
                        style={{
                          color:
                            calculateTotalAmount(
                              trialBalanceData.totalCreditAmount,
                              trialBalanceData.totalPayableAmount
                            ) < 0
                              ? "red"
                              : "green"
                        }}
                      >
                        {trialBalanceData && trialBalanceData.netBalance ? (
                          <>
                            <i className={currencySymbol} />
                            {/* {trialBalanceData.netBalance.toLocaleString()} */}
                            {amountFormatter(trialBalanceData.netBalance)}
                          </>
                        ) : (
                          <>
                            <i className={currencySymbol} />
                            {"0"}
                          </>
                        )}
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default compose(connect(mapStateToProps, null))(DetailedStatement);
