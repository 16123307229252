import Lottie from "react-lottie";
import React from "react";
import loader from "../../assets/images/loader.json";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
// eventListeners={[
//         {
//             eventName: 'complete',
//             callback: () => window.location.reload(),
//         },
// ]}

const Loader = (props) => {
  return (
    <div>
      <Lottie
        options={defaultOptions}
        height={props.height ? props.height : 50}
        width={props.width ? props.width : 50}
      />
    </div>
  );
};
export default Loader;
