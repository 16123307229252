import axios from "axios";
import handleError from "./handleErrors";
import {
  BASE_URL,
  retrieveTransaction,
  createTransaction as insertTransaction,
  deleteSingleTransactionRecord
} from "./apiConstants";
import getHeader from "./getHeaders";
import handleResponse from "./handleResponse";

export function fetchTransaction(customerId, businessId, token) {
  let header;

  if (!token || token == null) {
    header = getHeader();
  } else {
    header = { Authorization: `Bearer ${token}` };
  }
  const requestOptions = {
    method: "POST",
    headers: header,
    body: JSON.stringify({
      customerId: customerId,
      businessId: businessId
    })
  };
  return fetch(BASE_URL + retrieveTransaction, requestOptions)
    .then(handleResponse)
    .then(transaction => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return transaction;
    });
}

export function createTransaction(data, currencyName) {
  const form = new FormData();
  // let rate;
  if (data.currency === currencyName) {
    // rate = data.exchangeRate === null ? 0 : data.exchangeRate;
    data.exchangeRate = 1;
  }
  if (
    data.images !== null &&
    data.images !== "" &&
    data.images !== "undefined"
  ) {
    data.images.map((image, i) => {
      form.append(`images`, data.images[i]);
    });
  }

  form.append("note", data.note || "");

  if (
    data.date &&
    data.date !== "" &&
    data.date !== null &&
    data.date !== undefined
  ) {
    form.append("date", data.date);
  }
  form.append("currency", data.currency);
  form.append("customerId", data.customerid);
  form.append("businessId", Number(data.businessid));
  // form.append("amount", data.amount);
  form.append("exchangeAmount", data.exchangeAmount);
  form.append("direction", data.type);
  form.append("exchangeRate", data.exchangeRate);
  // form.append("exchangeRate", rate);
  const auth = JSON.parse(localStorage.getItem("persist:root"));
  const token = JSON.parse(auth.auth).accessToken;
  const requestOptions = {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: form
  };
  return fetch(BASE_URL + "transaction/create", requestOptions)
    .then(handleResponse)
    .then(data => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return data;
    });
}
/* /transaction/single */

export function updateSingleTransaction(data) {
  const form = new FormData();

  if (data.deletedImageid) {
    form.append("deletedImageIds", data.deletedImageid);
  }

  if (
    data.images !== null &&
    data.images !== "" &&
    data.images !== "undefined"
  ) {
    data.images.map((image, i) => {
      if (image) {
        if (image?.id) {
        } else {
          form.append(`images`, data.images[i]);
        }
      }
    });
  }

  form.append("note", data.note || "");

  if (
    data.date &&
    data.date !== "" &&
    data.date !== null &&
    data.date !== undefined
  ) {
    form.append("date", data.date);
  }
  form.append("singleTransactionId", Number(data.transactionId));

  const auth = JSON.parse(localStorage.getItem("persist:root"));
  const token = JSON.parse(auth.auth).accessToken;
  const requestOptions = {
    method: "PUT",
    headers: { Authorization: `Bearer ${token}` },
    body: form
  };
  return fetch(BASE_URL + "transaction/single", requestOptions)
    .then(handleResponse)
    .then(data => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return data;
    });
}
export function deleteSingleTransaction(parentId, singleTransactionId) {
  const requestOptions = {
    method: "DELETE",
    headers: getHeader()
  };
  return fetch(
    BASE_URL +
      deleteSingleTransactionRecord +
      `${singleTransactionId}/${parentId}`,
    requestOptions
  )
    .then(handleResponse)
    .then(data => {
      return data;
    });
}
