import React, { Component, useState, useEffect } from "react";
import IntlMessages from "util/intlMessages";
import {
  selected,
  Notselected,
  appLogo,
  appLogoCleaner
} from "helper/constant";
import { fetchCurrency, updateCurrency } from "helper/services/settingsService";
import Loader from "components/loader/Loader";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
  ListGroup,
  ListGroupItem
} from "reactstrap";
const ListCurrency = props => {
  useEffect(() => {
    getCurrency();
  }, []);

  /* STATE */

  const [loader, setLoader] = useState(false);

  /* STYLES */
  const customStyles = {
    paddingTop: "15%",
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  const changeCurrency = async currency => {
    setLoader(true);
    try {
      if (currency) {
        const response = await updateCurrency(currency);

        if (response) {
          props.updateCurrency(response);
          props.setCurrencyRedux({ currency: response });
          setLoader(false);
        }
      }
    } catch (e) {
      setLoader(false);
    }
  };

  const getCurrency = async () => {
    try {
      setLoader(true);
      const response = await fetchCurrency();
      if (response) {
        props.updateCurrency(response);
        setLoader(false);
      }
    } catch (e) {
      setLoader(false);
    }
  };

  return (
    <Modal
      isOpen={props.currencyModal}
      toggle={() => {
        props.istoggleCurrencyModal == true
          ? props.toggleCurrencyModal()
          : props.toggle();
      }}
      style={{ marginTop: "12%" }}
    >
      <ModalHeader
        toggle={() =>
          props.istoggleCurrencyModal == true
            ? props.toggleCurrencyModal()
            : props.toggle()
        }
      >
        <span className="text-muted mb-2">
          <IntlMessages id="Select currency" /> :
        </span>
      </ModalHeader>
      <ModalBody>
        <div
          className=""
          style={{
            overflow: " scroll",
            height: " 300px"
          }}
        >
          <div className="flex">
            <span className="text-muted mb-2">
              <IntlMessages id="Selected currency" /> :
            </span>
            <span className="bold-text flex mb-2 pl-1">
              {loader ? (
                <Loader height={30} width={25} />
              ) : (
                <>
                  {/* <i className={props.currencySymbol}></i> */}
                  {"  "}
                  {<IntlMessages id={props.currencyAction.currency} /> ||
                    "Fetching"}
                </>
              )}
            </span>
          </div>
          <ListGroup style={{ cursor: "pointer" }}>
            <ListGroupItem
              onClick={() => changeCurrency("INR")}
              tag="a"
              key={"INR"}
              action
            >
              <span>
                <i
                  className="fa fa-rupee-sign pr-4"
                  style={{ color: "#0073e6" }}
                ></i>
              </span>
              <span>
                <IntlMessages id="INR" />
              </span>
              <img
                className="mt-1"
                style={{
                  float: "right",
                  height: "15px",
                  width: "15px"
                }}
                src={
                  props.currencyAction.currency &&
                  props.currencyAction.currency.toUpperCase() == "INR"
                    ? selected
                    : Notselected
                }
              />
            </ListGroupItem>
            <ListGroupItem
              onClick={() => changeCurrency("DOLLAR")}
              tag="a"
              key={"DOLLAR"}
              action
            >
              <span>
                <i
                  className="fa fa-dollar-sign pr-4"
                  style={{ color: "#0073e6" }}
                ></i>
              </span>
              <span>
                <IntlMessages id="DOLLAR" />
              </span>
              <img
                className="mt-1"
                style={{
                  float: "right",
                  height: "15px",
                  width: "15px"
                }}
                src={
                  props.currencyAction.currency &&
                  props.currencyAction.currency.toUpperCase() == "DOLLAR"
                    ? selected
                    : Notselected
                }
                height="10px"
                width="10px"
              />
            </ListGroupItem>
            <ListGroupItem
              onClick={() => changeCurrency("EURO")}
              tag="a"
              key={"EURO"}
              action
            >
              <span>
                <i
                  className="fa fa-euro-sign pr-4"
                  style={{ color: "#0073e6" }}
                ></i>
              </span>
              <span>
                {" "}
                <IntlMessages id="EURO" />
              </span>
              <img
                className="mt-1"
                style={{
                  float: "right",
                  height: "15px",
                  width: "15px"
                }}
                src={
                  props.currencyAction.currency &&
                  props.currencyAction.currency.toUpperCase() == "EURO"
                    ? selected
                    : Notselected
                }
                height="10px"
                width="10px"
              />
            </ListGroupItem>
            <ListGroupItem
              onClick={() => changeCurrency("POUND")}
              tag="a"
              key={"POUND"}
              action
            >
              <span>
                <i
                  className="fa fa-pound-sign pr-4"
                  style={{ color: "#0073e6" }}
                ></i>
              </span>
              <span>
                <IntlMessages id="POUND" />
              </span>
              <img
                className="mt-1"
                style={{
                  float: "right",
                  height: "15px",
                  width: "15px"
                }}
                src={
                  props?.currencyAction?.currency &&
                  props?.currencyAction?.currency.toUpperCase() == "POUND"
                    ? selected
                    : Notselected
                }
                height="10px"
                width="10px"
              />
            </ListGroupItem>
            <ListGroupItem
              onClick={() => changeCurrency("YEN")}
              tag="a"
              key={"YEN"}
              action
            >
              <span>
                <i
                  className="fa fa-yen-sign pr-4"
                  style={{ color: "#0073e6" }}
                ></i>
              </span>
              <span>
                <IntlMessages id="YEN" />
              </span>
              <img
                className="mt-1"
                style={{
                  float: "right",
                  height: "15px",
                  width: "15px"
                }}
                src={
                  props.currencyAction.currency &&
                  props.currencyAction.currency.toUpperCase() == "YEN"
                    ? selected
                    : Notselected
                }
                height="10px"
                width="10px"
              />
            </ListGroupItem>
          </ListGroup>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ListCurrency;
