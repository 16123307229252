import styled from "styled-components";

export const ReportWrapper = styled.div`
  .c-outline-report {
    color: #0073e6 !important;
    background-color: transparent !important;
    background-image: none !important;
    border-color: #0073e6 !important;
  }
  .button-show {
    background-color: #0073e6;
    color: #fff;
    height: 30px;
    width: 70px !Important;
    border: 1px solid transparent;
    border-radius: 0.25rem;
  }

  .c-outline-report:hover {
    color: #fff !important;
    background-color: #0073e6 !important;
    border-color: #0073e6 !important;
  }
  .c-outline-report:focus {
    color: #fff !important;
    background-color: #0073e6 !important;
    border-color: #0073e6 !important;
  }
  .border-solid {
    color: #0073e6;
    border: 1px solid;
    border-color: #0073e6;
    cursor: pointer;
  }
  .date-selected {
    color: #fff;
    background-color: #0073e6;
    cursor: pointer;
    height: 30px;
    text-align: center;
  }
`;
