import authActionUser from "./actions";

const initState = {
  isLogin: localStorage.getItem("isLogin")
    ? localStorage.getItem("isLogin") === "true"
    : false,
  user:
    localStorage.getItem("isLogin") === "true" &&
    localStorage.getItem("user") !== null
      ? localStorage.getItem("user")
      : null
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case authActionUser.setuser:
      return {
        ...state,
        isLogin: true,
        user: action.user
      };
    case authActionUser.unsetUser:
      return {
        ...state,
        isLogin: action.isLogin,
        user: null
      };
    case authActionUser.updateuser_lang:
      return {
        user: {
          ...state.user,
          language: action.language
        }
      };

    default:
      return state;
  }
}
