import SelectCustomerAction from "./actions";

const initState = {
  customer: null,
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case SelectCustomerAction.setSelectedCustomer:
      return {
        ...state,
        customer: action.customer,
      };
    case SelectCustomerAction.unsetselectedCustomer:
      return {
        ...state,
        customer: null,
      };
    default:
      return state;
  }
}
