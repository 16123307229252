import React, { useState } from "react";
import IntlMessages from "util/intlMessages";
import { compose } from "redux";
import { connect } from "react-redux";
import CustomerContact from "components/Customer/CustomerContact";
import customerListAction from "redux/customerList/actions";
import SelectCustomerAction from "redux/customer/actions";

const { setCustomers } = customerListAction;
const { selectCustomer } = SelectCustomerAction;
const CustomerList = props => {
  const [customerlistLoader, setCustomerlistLoader] = useState(false);
  const showCustomerTransaction = (customerid, businessid, data) => {
    // redirect to customer Transaction
    props.setCustomers({ businessId: businessid, customers: data });
    props.selectCustomer({ customer: data });
    props.history.push("/customer-transaction", {
      customerid,
      businessid,
      currencySymbol: props.currencyAction.symbol,
      customerData: data,
      //   business: currentBusinessName,
      page: "Account"
    });
  };
  return (
    <div>
      <div className="container">
        {/* <Notifications /> */}
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />

          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
            <section className="mt-2">
              <nav
                style={{
                  marginBottom: 10
                }}
                className="custom-breadcumb"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className="breadcumb-color breadcrumb-item">
                    <a
                      id={32}
                      style={{
                        color: "gray",
                        cursor: "pointer"
                      }}
                      onMouseEnter={() => {
                        document.getElementById(32).style.textDecoration =
                          "underline";
                      }}
                      onMouseLeave={() => {
                        document.getElementById(32).style.textDecoration =
                          "none";
                      }}
                      onClick={() => props.history.goBack()}
                    >
                      {/* {props.SelectCustomerAction?.customer.name}'s */}
                      <IntlMessages id="sidebar.Account" />
                    </a>
                  </li>
                  <li
                    className="active breadcrumb-item"
                    style={{
                      color: "#0073e6",
                      wordWrap: "break-word"
                    }}
                    aria-current="page"
                  >
                    <span>
                      {" "}
                      <IntlMessages id="Customers" />
                    </span>
                  </li>
                </ol>
              </nav>
            </section>

            <div className="roe-card-style roe-shadow-2 whitelight mb-10">
              <div className="roe-card-header">
                <IntlMessages id="Customers" />
              </div>
              <div className="roe-card-body">
                <CustomerContact
                  language={props.LanguageSwitcher.language.locale}
                  showCustomerTransaction={(cid, bid, cdata) =>
                    showCustomerTransaction(cid, bid, cdata)
                  }
                  page={"Account"}
                  currency={props.currencyAction.symbol}
                  currentBusinessName={props.customerListAction.businessId}
                  customerData={props.location.state.customers}
                  loader={customerlistLoader}
                  // function to Redirect to customer transaction
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default compose(
  connect(mapStateToProps, { setCustomers, selectCustomer })
)(CustomerList);
