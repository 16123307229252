import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";

export const PublicRoutes = ({ authData, children }) => {
  return (
    <div>
      {authData.isLogin ? (
        <Redirect to={"/home"} />
      ) : (
        <Fragment>{children}</Fragment>
      )}
    </div>
  );
};

// import React, { Fragment } from "react";
// import { Route, Redirect } from "react-router-dom";

// export const PublicRoutes = ({ component: Component, ...rest }) => {

//     const db = JSON.parse(localStorage.getItem('persist:root'))
//     const getLogin = JSON.parse(db.auth).isLogin
//     return (
//         <Route {...rest} render={props => (
//             getLogin ? (
//
//                 <Redirect to="/home" />
//             ) : (
//                     <Component {...props} />
//                 )
//         )}
//         />)
// }
