import Account from "views/Account";
import Home from "views/Home";
import CustomerTransaction from "views/pages/customerTransaction";
import CustomerProfile from "views/pages/customerProfile";
import Profile from "views/Profile";
import Settings from "views/Settings";
import TransactionStatus from "components/Customer/cutomerTransaction/transactionStatus/TransactionStatus";
import ViewReport from "views/pages/report/ViewReport";
import DetailedStatement from "views/pages/report/DetailedStatement";
import CustomerList from "views/pages/customers/CustomerList";
import Collections from "views/pages/customers/Collections";
const dashboardRoutes = [
  { path: "/account", component: Account },
  { path: "/home", component: Home },
  { path: "/profile", component: Profile },
  { path: "/settings", component: Settings },
  { path: "/customer-transaction", component: CustomerTransaction },
  { path: "/customer-profile", component: CustomerProfile },
  { path: "/transaction-status", component: TransactionStatus },
  { path: "/view-report", component: ViewReport },
  { path: "/detailed-statement", component: DetailedStatement },
  { path: "/customers", component: CustomerList },
  { path: "/collections", component: Collections },
];

export default dashboardRoutes;
