import currencyAction from "./actions";

const initState = {
  currency: "DOLLAR",
};

const fetchCurrencySymbol = (currency) => {
  switch (currency) {
    case "INR": {
      return "fa fa-rupee-sign";
      break;
    }
    case "DOLLAR": {
      return "fa fa-dollar-sign";
      break;
    }
    case "EURO": {
      return "fa fa-euro-sign";
      break;
    }
    case "POUND": {
      return "fa fa-pound-sign";
      break;
    }
    case "YEN": {
      return "fa fa-yen-sign";
      break;
    }
    default: {
    }
  }
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case currencyAction.setCurrency:
      return {
        ...state,
        currency: action.currency,
        symbol: fetchCurrencySymbol(action.currency),
      };
    case currencyAction.unSetCurrency:
      return {
        ...state,
        currency: null,
        symbol: null,
      };
    default:
      return state;
  }
}
