import React, { Component, useState, useEffect } from "react";
import LanguageWrapper from "./language";
import { connect } from "react-redux";
import { compose } from "redux";
// import actions from "redux/themeSettings/actions";
import { getCurrentLanguage } from "settings/languageConfig";
import { withRouter } from "react-router-dom";
import IntlMessages from "util/intlMessages";
import Loader from "components/loader/Loader";
import { fetchLanguage, updateLanguage } from "helper/services/settingsService";
import { getUser } from "helper/services/authentication";
import {
  selected,
  Notselected,
  appLogo,
  appLogoCleaner,
  englishlanguage,
  indianlanguage
} from "helper/constant";
import languageActions from "redux/languageSwitcher/actions";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Tooltip,
  ListGroup,
  ListGroupItem
} from "reactstrap";
import AuthActions from "redux/auth/actions";
import authActionUser from "redux/user/actions";
const { changeLanguage } = languageActions;
const { updateuser_lang } = authActionUser;
const { login } = AuthActions;
const { update_language } = AuthActions;
const { setuser } = authActionUser;
const LanguageModal = props => {
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    getLanguages();
  }, []);
  const customStyles = {
    paddingTop: "15%",
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  const updateStates = async () => {
    const response = await getUser();
    if (response) {
      const auth = JSON.parse(localStorage.getItem("persist:root"));
      const tokenDb = JSON.parse(auth.auth).accessToken;
      props.updateuser_lang(response.user.language);
      props.update_language(response.user.language);
      // props.setuser({ user: response.user });
    }
  };
  const getLanguages = async () => {
    setLoader(true);
    try {
      const response = await fetchLanguage();
      if (response) {
        setLoader(false);
        props.setLanguage(response);
        getCurrentLanguage(response.toLowerCase());
        props.changeLanguage(response.toLowerCase());

        // updateuser_lang;
      }
    } catch (e) {
      setLoader(false);
    }
  };

  const update_Language = async language => {
    try {
      setLoader(true);
      const response = await updateLanguage(language);
      if (response) {
        setLoader(false);
        props.setLanguage(response);
        getCurrentLanguage(response.toLowerCase());
        props.changeLanguage(response.toLowerCase());
        updateStates();
      }
    } catch (e) {
      setLoader(false);
    }
  };

  return (
    <LanguageWrapper>
      <Modal
        isOpen={props.languageModalState}
        toggle={props.toggle}
        style={{ marginTop: "12%" }}
      >
        <ModalHeader toggle={props.toggle}>
          <IntlMessages id="Select language" />
        </ModalHeader>
        <ModalBody>
          <div
            style={{
              overflow: " scroll",
              height: " 300px"
            }}
          >
            <div className="flex">
              <span className="text-muted mb-2 ">
                <IntlMessages id="Selected language" />
              </span>
              <span className="mb-2 pl-1">
                {loader ? (
                  <Loader height={30} width={25} />
                ) : (
                  <IntlMessages id={props.language} /> || "Fetching"
                )}
              </span>
            </div>
            <ListGroup>
              <ListGroupItem
                onClick={() => update_Language("ENGLISH")}
                tag="a"
                key={"English"}
                action
              >
                <span>
                  <img
                    // className="pt-5"
                    style={{
                      height: "50ppx",
                      width: "50px"
                    }}
                    src={englishlanguage}
                  />
                </span>
                <span className="pl-5 bold-text">ENGLISH</span>
                <img
                  className="mt-3"
                  style={{
                    float: "right",
                    height: "15px",
                    width: "15px"
                  }}
                  src={
                    props.language && "ENGLISH" == props.language
                      ? selected
                      : Notselected
                  }
                  height="10px"
                  width="10px"
                />
              </ListGroupItem>
              <ListGroupItem
                onClick={() => update_Language("HINDI")}
                tag="a"
                key={"HINDI"}
                action
              >
                <span>
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "55%"
                    }}
                    src={indianlanguage}
                  />
                </span>
                <span className="pl-5">हिन्दी</span>
                <img
                  className="mt-3"
                  style={{
                    float: "right",
                    height: "15px",
                    width: "15px"
                  }}
                  src={
                    props.language && "HINDI" == props.language
                      ? selected
                      : Notselected
                  }
                  height="10px"
                  width="10px"
                />
              </ListGroupItem>
              <ListGroupItem
                onClick={() => update_Language("GUJARATI")}
                tag="a"
                key={"GUJARATI"}
                action
              >
                <span>
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      borderRadius: "55%"
                    }}
                    src={indianlanguage}
                  />
                </span>
                <span className="pl-5">ગુજરાતી</span>
                <img
                  className="mt-3"
                  style={{
                    float: "right",
                    height: "15px",
                    width: "15px"
                  }}
                  src={
                    props.language && "GUJARATI" == props.language
                      ? selected
                      : Notselected
                  }
                  height="10px"
                  width="10px"
                />
              </ListGroupItem>
            </ListGroup>
          </div>
        </ModalBody>
      </Modal>
    </LanguageWrapper>
  );
};
const mapStateToProps = state => {
  return {
    ...state
  };
};
export default compose(
  withRouter,
  connect(mapStateToProps, {
    changeLanguage,
    updateuser_lang,
    login,
    update_language,
    setuser
  })
)(LanguageModal);
