import {
  BASE_URL,
  currency as currencyurl,
  ME,
  language,
  reminders
} from "./apiConstants";
import getHeader from "./getHeaders";
import handleResponse from "./handleResponse";

export function updateCurrency(data, token) {
  let header;
  //
  if (!token || token == null) {
    //
    header = getHeader();
  } else {
    header = { Authorization: `Bearer ${token}` };
  }

  //
  //
  const requestOptions = {
    method: "PUT",
    headers: header,
    /* {
    "currency": "EURO"
} */
    body: JSON.stringify({ currency: data })
  };
  return fetch(BASE_URL + currencyurl, requestOptions)
    .then(handleResponse)
    .then(currencyResponse => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      if (currencyResponse.user.currency == data) {
        return currencyResponse.user.currency;
      }
      //props.login
      //   return currencyResponse;
    });
}

export function fetchCurrency(token) {
  let header;
  //
  if (!token || token == null) {
    //
    header = getHeader();
  } else {
    header = { Authorization: `Bearer ${token}` };
  }
  const requestOptions = {
    method: "GET",
    headers: header
  };
  return fetch(BASE_URL + ME, requestOptions)
    .then(handleResponse)
    .then(currency => {
      //   //
      return currency?.user?.currency;
    });
}

export function fetchLanguage() {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(BASE_URL + ME, requestOptions)
    .then(handleResponse)
    .then(language => {
      //
      return language.user.language;
    });
}

export function updateLanguage(data, token) {
  let header;
  //
  if (!token || token == null) {
    //
    header = getHeader();
  } else {
    header = { Authorization: `Bearer ${token}` };
  }

  //
  //
  const requestOptions = {
    method: "PUT",
    headers: header,
    /* {
    "currency": "EURO"
} */
    body: JSON.stringify({ language: data })
  };
  return fetch(BASE_URL + language, requestOptions)
    .then(handleResponse)
    .then(languageResponse => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      if (languageResponse.user.language == data) {
        return languageResponse.user.language;
      }
      //props.login
      //   return currencyResponse;
    });
}

export function fetchReminders(id) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };
  return fetch(BASE_URL + reminders + id, requestOptions)
    .then(handleResponse)
    .then(reminders => {
      //
      return reminders;
    });
}
