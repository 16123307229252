import React, { Component } from "react";
import Shimmer from "react-shimmer-effect";
const ShimmerLoader = (props) => {
  const StyleSheet = {
    container: {
      border: "0px solid rgba(255, 255, 255, 1)",
      // boxShadow: "0px 0px 20px rgba(0, 0, 0, .1)",
      borderRadius: "4px",
      backgroundColor: "white",
      display: "flex",
      marginBottom: "10px",
      paddingLeft: "15px",
      width: "100%",
      boxShadow: "rgb(0 0 0 / 0%) 0px 0px 20px",
    },
    circle: {
      height: "43px",
      width: "58px",
      background: "#e4e4e4",
      borderRadius: "50%",
    },
    nameline: {
      width: "40%",
      height: "8px",
      alignSelf: "center",
      marginLeft: "10px",
      borderRadius: "8px",
      marginBlockStart: "-25px",
      background: "#e4e4e4",

      // backgroundImage: " linear- gradient(to right,#dcdcdc 40 %,#cacaca 50 %,#ffffff 60 %)"
    },
    dateline: {
      width: "36%",
      height: "8px",
      alignSelf: "center",
      marginLeft: "-25%",
      borderRadius: "12px",
      // backgroundImage: " linear- gradient(to right,#dcdcdc 40 %,#cacaca 50 %,#ffffff 60 %)"
    },
    priceline: {
      width: "20%",
      height: "8px",
      alignSelf: "center",
      marginLeft: "50%",
      borderRadius: "8px",
      // backgroundImage: " linear- gradient(to right,#dcdcdc 40 %,#cacaca 50 %,#ffffff 60 %)"
    },
    Shimmer: {
      background: "#e4e4e4",
      backgroundImage:
        " linear- gradient(to right,#dcdcdc 40 %,#cacaca 50 %,#ffffff 60 %)",
    },
    //     background: #e4e4e4;
    //    background-image: linear-gradient(to right,#dcdcdc 40%,#cacaca 50%,#ffffff 60%);
  };
  // const { classes } = props.props;
  let i = 0;
  return (
    <>
      <div key={i++ * 2}>
        <Shimmer>
          <div
            className="flex-x ptb-15 mb-6 border-bottom "
            style={StyleSheet.container}
          >
            <div style={StyleSheet.circle} key={i++} />
            <div style={StyleSheet.nameline} key={i++} />
            <div style={StyleSheet.dateline}></div>
            <div style={StyleSheet.priceline} key={i++} />
          </div>
        </Shimmer>
        <Shimmer>
          <div
            className="flex-x ptb-15 mb-6 border-bottom "
            style={StyleSheet.container}
          >
            <div style={StyleSheet.circle} key={i++} />
            <div style={StyleSheet.nameline} key={i++} />
            <div style={StyleSheet.dateline}></div>
            <div style={StyleSheet.priceline} key={i++} />
          </div>
        </Shimmer>
        <Shimmer>
          <div
            className="flex-x ptb-15 mb-6 border-bottom "
            style={StyleSheet.container}
          >
            <div style={StyleSheet.circle} key={i++} />
            <div style={StyleSheet.nameline} key={i++} />
            <div style={StyleSheet.dateline}></div>
            <div style={StyleSheet.priceline} key={i++} />
          </div>
        </Shimmer>
      </div>
      {/* <div style={StyleSheet.container} key={(i++) * 2}>
                <Shimmer key={i++}>
                    <div style={StyleSheet.circle} key={i++} />
                    <div style={StyleSheet.nameline} key={i++} />
                    <div style={StyleSheet.dateline}></div>
                    <div style={StyleSheet.priceline} key={i++} />
                </Shimmer>
            </div >
            <div style={StyleSheet.container} key={(i++) * 2}>
                <Shimmer key={i++}>
                    <div style={StyleSheet.circle} key={i++} />
                    <div style={StyleSheet.nameline} key={i++} />
                    <div style={StyleSheet.dateline}></div>
                    <div style={StyleSheet.priceline} key={i++} />
                </Shimmer>
            </div > */}
    </>
  );
};
export default ShimmerLoader;
