import React from "react";
import { injectIntl, intlShape } from "react-intl";

const InputIntl = props => {
  const placeholder = props.intl.formatMessage({ id: props.placeholderid });
  if (props.name) {
    return (
      <input
        placeholder={placeholder}
        name={props.name}
        ref={props.ref}
        {...props}
        autoFocus
      />
    );
  } else {
    return <input placeholder={placeholder} {...props} />;
  }
};

InputIntl.propTypes = {
  intl: intlShape.isRequired
};

export default injectIntl(InputIntl);
