import { withFormik } from "formik";
import * as Yup from "yup";
import IntlMessages from "util/intlMessages";
const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    customerName: Yup.string()
      .required("Please enter name")
      .min(1, "Minimum name must be of 1 character"),
    customerMobileNumber: Yup.string()
      .required("Please enter mobile number")
      .matches(/^\d+$/, "Please enter only number is must have 6 digits")
      .min(6, "Mobile number must have minimum 6 digits")
  }),
  mapPropsToValues: props => ({}),
  handleSubmit: (values, { resetForm }) => {
    resetForm();
  },
  displayName: "CustomValidationForm",
  enableReinitialize: true
});

export default formikEnhancer;
