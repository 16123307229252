import { language } from "./index";

import englishLang from "assets/images/usaflag.png";
import chineseLang from "assets/images/china.svg";
import spanishLang from "assets/images/spanishflag.png";
import frenchLang from "assets/images/france.svg";
import italianLang from "assets/images/italian.png";
import indianLang from "assets/images/language/flagIndia.png";
import { fetchLanguage } from "helper/services/settingsService";

const setLanguage = () => {
  try {
    const dbData = localStorage.getItem("persist:root");
    const dbValue = JSON.parse(dbData);

    const accessToken = JSON.parse(dbValue.auth).user;
    const LanguageD = accessToken.language;

    return LanguageD.toLowerCase();
    // const response = await fetchLanguage();
    // if (response) {
    //   return response;
    // }
  } catch (err) {
    return "english";
  }
};
const config = {
  defaultLanguage: setLanguage(),
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang,
    },
    {
      languageId: "gujarati",
      locale: "gu",
      text: "Gujarati",
      icon: indianLang,
    },
    {
      languageId: "hindi",
      locale: "hi",
      text: "Hindi",
      icon: indianLang,
    },
    {
      languageId: "chinese",
      locale: "zh",
      text: "Chinese",
      icon: chineseLang,
    },
    {
      languageId: "spanish",
      locale: "es",
      text: "Spanish",
      icon: spanishLang,
    },
    {
      languageId: "french",
      locale: "fr",
      text: "French",
      icon: frenchLang,
    },
    {
      languageId: "italian",
      locale: "it",
      text: "Italian",
      icon: italianLang,
    },
  ],
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach((language) => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
