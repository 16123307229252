import React from "react";

import { Modal, ModalBody } from "reactstrap";

import Loader from "components/loader/Loader";

function Loadmodal({ loader }) {
  return (
    <div>
      <Modal
        isOpen={loader}
        style={{ marginTop: "12%", background: "transparent !important" }}
      >
        <ModalBody>
          <Loader />
        </ModalBody>
      </Modal>
    </div>
  );
}

export default Loadmodal;
