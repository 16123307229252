import React, { useEffect, useState } from "react";
import SocialQuoteWrapper from "./socialquote.style";
import { compose } from "redux";
import { connect } from "react-redux";
import { amountFormatter } from "helper/methods";

import ShareModal from "./ShareModal";
import AddTransactionModal from "components/Customer/cutomerTransaction/TransactionModal";
import Notifications, { notify } from "react-notify-toast";
import { deleteSingleTransaction } from "helper/services/transactionService";
import { badge_received } from "helper/constant";
import IntlMessages from "util/intlMessages";
// import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import * as htmlToImage from "html-to-image";
import singleTransactionAction from "redux/singleTransaction/actions";
const { setSingleTransaction } = singleTransactionAction;
const TransactionStatus = props => {
  const [shareModal, setShareModal] = useState(false);
  // const [imageModal, setImageModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [transaction, setTransaction] = useState(null);
  const [currency, setCurrency] = useState(null);
  const [business, setBusiness] = useState(null);
  const [parentTransactionId, setParentTransactionId] = useState(null);
  const [customers, setCustomers] = useState(null);
  const [reload, setReload] = useState(false);
  const [isLoaderShare, setLoaderShare] = useState(false);
  useEffect(() => {
    if (reload) {
    } else {
      setReload(false);
      if (
        props.singleTransactionAction &&
        props.singleTransactionAction.transaction
      ) {
        setTransaction(props.singleTransactionAction.transaction);
        setParentTransactionId(props.singleTransactionAction.parentId);
        setCurrency(props.currencyAction.symbol);
        setBusiness(props.customerListAction.businessId);
        setCustomers(props.customerListAction.customers);
      }
      // if (props.history && props.history.location && props.location.state) {
      //   setTransaction(props.location.state.transaction);
      //   setParentTransactionId(props.location.state.parentTransactionId);
      //   setCurrency(props.location.state.currency);
      //   setBusiness(props.location.state.business);
      //   setCustomers(props.customerListAction.customers);
      // }
    }
  }, [transaction, currency, parentTransactionId, business]);
  const downloadImage = () => {
    setLoaderShare(true);
    htmlToImage
      .toJpeg(document.getElementById("htmltoimage"), {
        quality: 0.95
      })
      .then(function(dataUrl) {
        var link = document.createElement("a");
        link.download = "Transaction.jpeg";
        link.href = dataUrl;
        link.click();
        setLoaderShare(false);
      });
  };
  const toggleShareModal = () => {
    setShareModal(!shareModal);
  };
  const toggleUpdateModal = () => {
    setUpdateModal(!updateModal);
  };
  const deleteTransaction = async (pId, singleTransactionId) => {
    try {
      const response = await deleteSingleTransaction(pId, singleTransactionId);
      if (response) {
        if (response.errors) {
        }
        if (response.transaction && response.transaction.affected) {
          if (response.transaction.affected == 1) {
            props.history.goBack();
          }
        }
      }
    } catch (e) {}
  };

  const setSingleTransactionToReducer = (data, parentid) => {
    props.setSingleTransaction({ transaction: data, parentId: parentid });
  };
  return (
    <div>
      <div className="container">
        {/* <Notifications /> */}
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />

          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
            <Notifications />
            <SocialQuoteWrapper>
              <section>
                <nav
                  style={{
                    marginBottom: 10
                  }}
                  className="custom-breadcumb"
                  aria-label="breadcrumb"
                >
                  <ol className="breadcrumb">
                    <li className="breadcumb-color breadcrumb-item">
                      <a
                        id={31}
                        style={{
                          color: "gray"
                        }}
                        onMouseEnter={() => {
                          document.getElementById(31).style.textDecoration =
                            "underline";
                        }}
                        onMouseLeave={() => {
                          document.getElementById(31).style.textDecoration =
                            "none";
                        }}
                        onClick={() =>
                          props.history.push(props.location.state.page)
                        }
                      >
                        {props.location.state.page === "Account" ? (
                          <IntlMessages id="sidebar.Account" />
                        ) : (
                          <IntlMessages id="sidebar.Home" />
                        )}
                      </a>
                    </li>
                    <li className="breadcumb-color breadcrumb-item">
                      <a
                        id={32}
                        style={{
                          color: "gray",
                          cursor: "pointer"
                        }}
                        onMouseEnter={() => {
                          document.getElementById(32).style.textDecoration =
                            "underline";
                        }}
                        onMouseLeave={() => {
                          document.getElementById(32).style.textDecoration =
                            "none";
                        }}
                        onClick={() => props.history.goBack()}
                      >
                        {/* {props.SelectCustomerAction?.customer.name}'s */}
                        <IntlMessages id="Transaction" />
                      </a>
                    </li>
                    <li
                      className="active breadcrumb-item"
                      style={{
                        color: "#0073e6",
                        wordWrap: "break-word"
                      }}
                      aria-current="page"
                    >
                      <span>
                        <IntlMessages id="View" />
                      </span>
                    </li>
                  </ol>
                </nav>
              </section>
              <div className="roe-card-style roe-shadow-2 whitelight mb-10">
                {transaction && (
                  <div className="roe-card-body ptb-15">
                    <div className="flex-x align-center fs-15 bold-text text-muted">
                      <i
                        className="fa fa-user text-muted"
                        style={{
                          marginLeft: 1,
                          marginRight: 4
                        }}
                      />
                      {customers && customers.name}
                      {/* <IntlMessages
                        id="sidebar.filemanager"
                        title="sidebar.filemanager"
                      /> */}
                      <div
                        className="ml-auto text-muted fs-15 demi-bold-text"
                        onClick={() => setUpdateModal(!updateModal)}
                        data-toggle="tooltip"
                        title={<IntlMessages id="Edit transaction" />}
                      >
                        <i
                          className="fa fa-edit pr-1"

                          // onClick={() => props.history.goBack()}
                        />
                        <IntlMessages id="Edit" />
                      </div>
                    </div>
                    <div className="text-center profile-widget-image ptb-5">
                      <img src={badge_received} />
                    </div>
                    <div className="text-center text-muted fs-14">
                      <span>
                        {moment(new Date(transaction.createdAt)).format(
                          "ddd D MMM , hh:MM A "
                        )}
                      </span>
                    </div>
                    <div className="text-center bold-text fs-16 mb-3">
                      <span>
                        {transaction.amount &&
                        transaction.direction == "INBOUND" ? (
                          <div className="text-center bold-text fs-16 inline-flex">
                            <IntlMessages id="Payment received" /> {" :"}
                            <p className="green ">
                              <i
                                className={currency}
                                style={{
                                  marginLeft: 5
                                }}
                              />
                              {amountFormatter(transaction.amount)}
                            </p>
                          </div>
                        ) : (
                          <div className="text-center bold-text fs-16 inline-flex">
                            <IntlMessages id="Payment sent" /> {" :"}
                            <p className="red pl-1">
                              <i
                                className={currency}
                                style={{
                                  marginLeft: 5
                                }}
                              />
                              {amountFormatter(transaction.amount)}
                            </p>
                          </div>
                        )}
                      </span>
                    </div>
                    <div>
                      {transaction.singleTransactionMeta &&
                      transaction.singleTransactionMeta.length > 0 ? (
                        <div className="transaction-image">
                          {transaction.singleTransactionMeta &&
                            transaction.singleTransactionMeta.map(
                              (images, i) => {
                                return (
                                  <a key={i} href={images.url} target="_blank">
                                    <img
                                      // onClick={() =>
                                      //   expandImageModal(images.url)
                                      // }
                                      src={images.url}
                                    ></img>
                                  </a>
                                );
                              }
                            )}{" "}
                        </div>
                      ) : (
                        ""
                      )}{" "}
                    </div>
                    <hr />
                    <label className="text-muted">
                      <IntlMessages id="Notes" />
                    </label>
                    <div
                      className="text-left"
                      style={{ wordWrap: "break-word" }}
                    >
                      <span>
                        {transaction.notes ? (
                          transaction.notes
                        ) : (
                          <IntlMessages id="No notes added" />
                        )}
                      </span>
                    </div>
                    <hr />
                    <div
                      className="text-left cursor-pointer text-muted"
                      onClick={() => setShareModal(true)}
                    >
                      <i className="fa fa-share-alt " aria-hidden="true">
                        <span style={{ marginLeft: 15 }}>
                          <IntlMessages id="Share on whatsApp" />
                        </span>
                      </i>
                    </div>{" "}
                    <hr />
                    <div className="text-center ptb-20 ">
                      <button
                        className="btn button"
                        type="button"
                        onClick={() =>
                          deleteTransaction(parentTransactionId, transaction.id)
                        }
                      >
                        <IntlMessages id="Delete payment" />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </SocialQuoteWrapper>
            <ShareModal
              shareModal={shareModal}
              currency={currency}
              toggle={toggleShareModal}
              user={props.authActionUser.user}
              business={business || ""}
              transaction={transaction || ""}
              downloadImage={downloadImage}
              loader={isLoaderShare}
            />
            {/* <AddTransactionModal></AddTransactionModal> */}

            {transaction && customers && currency && business && (
              <AddTransactionModal
                notify={(e, status, time) => notify.show(e, status)}
                title={<IntlMessages id="Edit transaction" />}
                // getTransactions={() => getTransactions()}
                setReload={f => setReload(f)}
                customerData={customers}
                transaction={transaction}
                setTransaction={data => setTransaction(data)}
                transactionType={transaction.direction}
                type={"UPDATE"}
                businessid={business.id || props.customerListAction.businessId}
                currency={currency}
                currencyName={props.currencyAction.currency}
                addTransactionModal={updateModal}
                toggle={toggleUpdateModal}
                SingleTransaction={data =>
                  setSingleTransactionToReducer(
                    data,
                    props.singleTransactionAction.parentId
                  )
                }
              />
            )}
            {/* <Modal
              isOpen={imageModal}
              toggle={() => setImageModal(!imageModal)}
              style={{
                paddingTop: 10,
              }}
            >
              {" "}
              <ModalBody>
                <div className="container text-center">
                  {selectedImage ? (
                    <img
                      src={selectedImage}
                      style={{
                        height: "150px",
                        width: "150px",
                      }}
                    />
                  ) : (
                    "Please click on Image to view"
                  )}
                </div>
              </ModalBody>
            </Modal> */}
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default compose(connect(mapStateToProps, { setSingleTransaction }))(
  TransactionStatus
);
