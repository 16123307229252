import React, { useState } from "react";
import { phoneDemo } from "helper/constant";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import AuthActions from "redux/auth/actions";
import enhancer from "./enhancer/LoginFormEnhancer";
import Notifications, { notify } from "react-notify-toast";
import Loader from "../../../components/loader/Loader";
import "../../../assets/style.css";
import { login as loginUser } from "../../../helper/services/authentication";
import { InputGroup } from "reactstrap";

const { login } = AuthActions;

const Login = props => {
  const [loader, setLoader] = useState(false);
  const handleLogin = async e => {
    try {
      e.preventDefault();
      let { values, handleSubmit } = props;
      if (
        values.mobileNumber !== undefined &&
        values.mobileNumber.toString().length === 10
      ) {
        setLoader(true);
        let response = await loginUser(values.mobileNumber.toString());
        if (response) {
          setLoader(false);
          props.history.push("/verify", {
            sessionId: response.sessionId,
            token: response.token,
            phoneNumber: values.mobileNumber
          });
        }
      } else {
        notify.show("Please enter mobile number", "error");
      }
      handleSubmit();
    } catch (e) {
      notify.show(e, "error");
      setLoader(false);
    }
  };

  const { handleChange, handleBlur, errors, touched, submitCount } = props;

  const loginContainer = {
    backgroundPosition: "center center",
    backgroundSize: "cover",
    position: "fixed",
    overflow: "auto",
    top: 0,
    bottom: 0
  };
  const inputLabel = {
    background: "#ffff",
    borderColor: "gray",
    border: " 1px solid gray",
    borderRight: "0px"
  };
  const justify = {
    justifyContent: "center"
  };
  const inputStyle = {
    border: "1px solid grey",
    focus: "none",
    borderLeft: 0,
    paddingLeft: 10
  };
  const button = {
    fontSize: "16px",
    // textTransform: "capitalize",
    boxShadow: "none!important",
    width: "100%",
    borderRadius: "2px",
    fontWeight: "500",
    // background: "#083a6f",
    background: "#0073e6",
    color: " white",
    paddingLeft: "10px",
    justifyContent: "center"
  };

  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };

  return (
    <div className="container-fluid" style={loginContainer}>
      <Notifications />
      <div className="form-container col-sm-4 ptb-15">
        <div className="login-icon">
          <img src={phoneDemo} alt="icon" height="150px" />
        </div>
        <div className="login-title">Mobile verification</div>
        <div className="text-center form-info-text plr-24 mt-16">
          Please enter your Mobile number.
          {/*  We will send a verification code to
          authenticate your account. */}
        </div>
        <form className="pa-24" style={justify} onSubmit={handleLogin}>
          <div className="form-group">
            <InputGroup size="sm" style={justify}>
              <div className="p-1" style={inputLabel}>
                <span>🇮🇳</span> +91
              </div>
              <input
                className="input-mobile"
                autoFocus
                onChange={handleChange}
                onBlur={handleBlur}
                id="mobileNumber"
                style={inputStyle}
                // type="tel"
                type="number"
                placeholder="Mobile number"
              />
              <br />
            </InputGroup>
            <br />
            <div className="form-group text-center">
              <Error field="mobileNumber" />
            </div>
          </div>
          <div className="text-center form-info-text plr-24 mt-16">
            We will use this number to send verification code
          </div>
          <br />
          <div style={{ textAlign: "center" }}>
            {!loader ? (
              <input
                type="submit"
                style={button}
                className="btn  button"
                value={"Request verification code"}
              />
            ) : (
              <Loader />
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default compose(withRouter, enhancer, connect(null, { login }))(Login);
