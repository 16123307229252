import React, { useState, useEffect } from "react";
import Loader from "components/loader/Loader";
import sortBy from "lodash/sortBy";
import IntlMessages from "util/intlMessages";
import { amountFormatter } from "helper/methods";
import LatestActivityWrapper from "./Account";
import {
  trialbalance,
  backup,
  collection,
  balance,
  customer,
  greaterThan
} from "../../helper/constant";
import { getBalance, generateBackup } from "helper/services/accountServics";

// import ShimmerLoader from "./shimmer/ShimmerLoader";
import moment from "moment";
const profileImage = {
  borderRadius: "50%",
  border: "1 solid",
  width: 34,
  background: "#0073e6",
  height: 41,
  objectFit: "fill"
};
const InCap = {
  lineHeight: 2,
  fontSize: 20,
  color: "white",
  textAlign: "center"
};

//https: thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
const AccountList = props => {
  const [totalcustomers, setTotalCustomers] = useState(null);
  const [Balance, setBalance] = useState(null);
  const [isloader, setIsLoader] = useState(false);
  useEffect(() => {
    fetchBalance();
  }, [props.currency]);
  const [loader, setLoader] = useState(false);

  const [onMouseHover, setOnMouseHover] = useState(false);
  const fetchBalance = async () => {
    const Response = await getBalance(props.customerListAction.businessId);

    if (Response) {
      //
      if (Response.balance) {
        if (Response.balance) {
          setBalance(Response.balance);
          setTotalCustomers(Response.customer);
        }
      }
    }
  };
  const saveFile = async blob => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "TinyLedgerBackup.PDF";
    a.click();
    window.URL.revokeObjectURL(a.href);
  };
  const downloadPDF = async () => {
    setIsLoader(true);
    try {
      const summaryResponse = await generateBackup(
        props.customerListAction.businessId
      );
      if (summaryResponse.errors) {
        //
      } else {
        //
        // var a = document.createElement("a"); //Create <a>
        // a.href = "data:PDF;base64," + summaryResponse.accountStatement; //Image Base64 Goes here
        // a.download = "TinyLedgerBackup.PDF"; //File name Here
        // a.click(); //Downloaded file
        const blob = new Blob([summaryResponse], { type: "application/pdf" });
        saveFile(blob);
        setIsLoader(false);
      }
    } catch (e) {
      //
    }
  };
  function onHoverStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
    if ("divqw2" != div) {
      document.getElementById(div).style.cursor = "pointer";
    }
    // e.target.style.background = "#f5f5f5";
  }
  function onMouseLeave(div) {
    document.getElementById(div).style.background = "#fff";
    // e.target.style.background = "#fff";
  }
  // let ans = getData(props.customerData);
  // ////
  const updateCurrencySymbol = () => {
    //

    switch (props.currency) {
      case "INR": {
        props.setCurrencySymbol("fa fa-rupee-sign");
        //
        break;
      }
      case "DOLLAR": {
        props.setCurrencySymbol("fa fa-dollar-sign");
        //
        break;
      }
      case "EURO": {
        props.setCurrencySymbol("fa fa-euro-sign");
        break;
      }
      case "POUND": {
        props.setCurrencySymbol("fa fa-pound-sign");
        break;
      }
      case "YEN": {
        props.setCurrencySymbol("fa fa-yen-sign");
        break;
      }
      default: {
      }
    }
  };

  return (
    <LatestActivityWrapper className="fill-height">
      <div className="card-body">
        {/* {
        {/* {props.loader ? (
          <ShimmerLoader props={props} />
        )  */}
        <div>
          {/* <p className="text-muted text-left"> </p> */}
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={1}
            id={`divqw${1}`}
            onMouseEnter={() => onHoverStyle(`divqw${1}`)}
            onMouseLeave={() => onMouseLeave(`divqw${1}`)}
            onClick={() => {
              if (totalcustomers) {
                props.history.push("/customers", {
                  customers: props.customerList
                });
              }
            }}
          >
            <div>
              <img
                style={{
                  width: 34,
                  height: 35,
                  objectFit: "contain"
                }}
                src={customer}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10   flex-1"
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <p className=" fs-16 demi-flex-start ">
                <IntlMessages id="Customers" />
              </p>
              <small className="fs-12 text-left"></small>
            </div>
            <div>
              <div className="" style={{ marginTop: 5 }} key={1}>
                <div>
                  {/* <small>
                    <i className={props.currencySymbol}></i>
                  </small> */}
                  <small></small>
                  <small>
                    <span style={{ marginRight: 10 }}>
                      {totalcustomers && totalcustomers ? totalcustomers : "0"}
                    </span>
                    <img src={greaterThan} height="18" />
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={2}
            id={`divqw${2}`}
            onMouseEnter={() => onHoverStyle(`divqw${2}`)}
            onMouseLeave={() => onMouseLeave(`divqw${2}`)}
          >
            <div>
              <img
                style={{
                  width: 34,
                  height: 35,
                  objectFit: "fill"
                }}
                src={balance}
                alt="security"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10   flex-1"
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <p className=" fs-16 demi-flex-start ">
                <IntlMessages id="Balance" />{" "}
              </p>
            </div>
            <div>
              <div style={{ marginTop: 5 }} key={1}>
                <div>
                  {Balance ? (
                    Balance > 0 ? (
                      <small style={{ color: "green" }}>
                        <i className={props.currencyAction.symbol} />
                        {amountFormatter(Balance)} <IntlMessages id="Advance" />
                      </small>
                    ) : (
                      <small style={{ color: "red" }}>
                        <i className={props.currencyAction.symbol} />
                        {amountFormatter(Balance)} <IntlMessages id="Due" />
                      </small>
                    )
                  ) : (
                    <small>
                      <i className={props.currencyAction.symbol} /> 0{" "}
                    </small>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={3}
            id={`divqw${3}`}
            onMouseEnter={() => onHoverStyle(`divqw${3}`)}
            onMouseLeave={() => onMouseLeave(`divqw${3}`)}
            onClick={() => props.history.push("/collections")}
          >
            <div>
              <img
                style={{
                  width: 34,
                  height: 35,
                  objectFit: "contain"
                }}
                src={collection}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10   flex-1"
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <IntlMessages id="Collections" />
            </div>
            <div>
              <div key={1} style={{ marginTop: 5 }}>
                <div>
                  <small>
                    <img src={greaterThan} height="18" />
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={4}
            id={`divqw${4}`}
            onMouseEnter={() => onHoverStyle(`divqw${4}`)}
            onMouseLeave={() => onMouseLeave(`divqw${4}`)}
            onClick={() =>
              props.history.push("/view-report", {
                page: "account"
              })
            }
          >
            <div>
              <img
                style={{
                  width: 34,
                  height: 35,
                  objectFit: "contain"
                }}
                src={trialbalance}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10 flex-1 "
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <IntlMessages id="Trial balance" />
            </div>
            <div>
              <div className="" style={{ marginTop: 5 }} key={1}>
                <small>
                  <img src={greaterThan} height="18" />
                </small>
              </div>
            </div>
          </div>
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={5}
            id={`divqw${5}`}
            onMouseEnter={() => onHoverStyle(`divqw${5}`)}
            onMouseLeave={() => onMouseLeave(`divqw${5}`)}
            onClick={() => downloadPDF()}
          >
            <div>
              <img
                style={{
                  // width: 34,
                  height: 35,
                  objectFit: "contain"
                }}
                src={backup}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10 flex-1 "
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <IntlMessages id="Download backup" />
            </div>
            <div>
              <div
                style={{
                  marginTop: 5
                }}
                key={1}
              >
                <div>
                  <small>
                    {isloader ? (
                      <Loader height="18px" width="20px" />
                    ) : (
                      <img src={greaterThan} height="18" />
                    )}
                  </small>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </LatestActivityWrapper>
  );
};

export default AccountList;
