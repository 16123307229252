import axios from "axios";
import handleError from "./handleErrors";
import { BASE_URL, listUsers, verifyOtp, ME } from "./apiConstants";
import getHeader from "./getHeaders";
import handleResponse from "./handleResponse";

export function login(phoneNumber) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ phoneNumber: phoneNumber, countryCode: "91" }),
  };

  return fetch(BASE_URL + `users/login`, requestOptions)
    .then(handleResponse)
    .then((user) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes
      //
      //props.login
      return user;
    });
}

export function verify(data, token) {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      otpInput: "11111",
      sessionId: data.sessionId,
    }),
  };

  return fetch(BASE_URL + verifyOtp, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return data;
    });
}

export function getUser(token) {
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };
  return fetch(BASE_URL + ME, requestOptions)
    .then(handleResponse)
    .then((data) => {
      return data;
    });
}

export function updateProfile(token, data) {
  const form = new FormData();
  let header;
  if (!token || token == null) {
    const auth = JSON.parse(localStorage.getItem("persist:root"));
    const tokenDb = JSON.parse(auth.auth).accessToken;
    header = { Authorization: `Bearer ${tokenDb}` };
  } else {
    header = { Authorization: `Bearer ${token}` };
  }

  if (data.profilePic !== "") {
    form.append("profilePic", data.profilePic);
  }

  form.append("address", data.address);
  form.append("email", data.email);
  form.append("contactPersonName", data.contactPersonName);
  const requestOptions = {
    method: "PUT",
    headers: header,
    body: form,
  };
  return fetch(BASE_URL + listUsers, requestOptions)
    .then(handleResponse)
    .then((data) => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return data;
    });
}
