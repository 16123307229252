const singleTransactionAction = {
  SET_SINGLE_TRANSACTION: "SET_SINGLE_TRANSACTION",
  UNSET_SINGLE_TRANSACTION: "UNSET_SINGLE_TRANSACTION",

  setSingleTransaction: (data) => {
    return {
      type: singleTransactionAction.SET_SINGLE_TRANSACTION,
      transaction: data.transaction,
      parentId: data.parentId,
    };
  },
  unsetSingleTransaction: (data) => {
    return {
      type: singleTransactionAction.UNSET_SINGLE_TRANSACTION,
      transaction: null,
      parentId: null,
    };
  },
};

export default singleTransactionAction;
