import axios from "axios";
import handleError from "./handleErrors";
import {
  BASE_URL,
  summaryTransaction,
  generatePdf,
  generateDetailedStatement
} from "./apiConstants";
import getHeader from "./getHeaders";
import handleResponse from "./handleResponse";

export function fetchSummary(startDate, endDate, businessId) {
  const requestOptions = {
    method: "POST",
    headers: getHeader(),
    body: JSON.stringify({
      startDate,
      endDate,
      businessId
    })
  };
  return fetch(BASE_URL + summaryTransaction, requestOptions)
    .then(handleResponse)
    .then(transaction => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return transaction;
    });
}

export async function fetchSummaryToPDF(startDate, endDate, businessId) {
  // const requestOptions = {
  //   method: "POST",
  //   headers: getHeader(),
  //   body: JSON.stringify({
  //     startDate,
  //     endDate,
  //     businessId
  //   })
  // }
  const data = {
    startDate: startDate,
    endDate: endDate,
    businessId: businessId
  };
  // let transaction = await fetch(BASE_URL + generatePdf, requestOptions)
  //   .then(handleResponse)
  //   .then(transaction => {
  //     console.log(transaction, 943589405833)
  //     // store user details and jwt token in local storage to keep user logged in between page refreshes
  //     //props.login
  //     return transaction;
  //     //   });
  //   }).catch((err) => );
  // return transaction
  let api = BASE_URL + generatePdf;
  const transaction = await axios.post(api, data, {
    headers: getHeader(),
    responseType: "arraybuffer"
  });
console.log(transaction,"transaction")
  return transaction?.data;
}

export async function fetchDetailsStatementPDF(startDate, endDate, businessId) {
  // const requestOptions = {
  //   method: "POST",
  //   headers: getHeader(),
  //   body: JSON.stringify({
  //     startDate,
  //     endDate,
  //     businessId
  //   })
  // };
  const data = {
    startDate: startDate,
    endDate: endDate,
    businessId: businessId
  };

  let api = BASE_URL + generateDetailedStatement;
  const transaction = await axios.post(api, data, {
    headers: getHeader(),
    responseType: "arraybuffer"
  });

  return transaction?.data;
  // return fetch(BASE_URL + generateDetailedStatement, requestOptions)
  //   .then(handleResponse)
  //   .then(transaction => {
  //     // store user details and jwt token in local storage to keep user logged in between page refreshes

  //     //props.login
  //     return transaction;
  //   });
}
