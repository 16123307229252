import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import SettingsLayout from "../components/Settings/SettingsLayout";
import ListCurrency from "../components/Settings/ListCurrency";
import LanguageModal from "../components/Settings/Language/Language";
import RemindersList from "../components/Settings/Reminders/RemindersList";
import { getCurrentLanguage } from "settings/languageConfig";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ListGroup, ListGroupItem } from "reactstrap";
import IntlMessages from "util/intlMessages";
import {
  fetchBusinessList as getBusinessList,
  newBusiness,
} from "../helper/services/businessService";
import { selected, Notselected } from "../helper/constant";
import AlertError from "../components/Customer/error/AlertError";
import Loader from "../components/loader/Loader";
import Notifications, { notify } from "react-notify-toast";
import currencyAction from "redux/currency/actions";
import actions from "redux/languageSwitcher/actions";
const { setCurrency } = currencyAction;
const { changeLanguage } = actions;

const Settings = (props) => {
  const [currency, updateCurrency] = useState("");
  const [language, setLanguage] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currencyModal, setCurrencyModal] = useState(false);
  const [remindersModal, setRemindersModal] = useState(false);
  const [languageModalState, setLanguageModalState] = useState(false);
  const toggleCurrencyModal = () => {
    if (currency) {
      setCurrencyModal(!currencyModal);
    }
  };
  const toggleLanguageModal = () => {
    if (language) {
      setLanguageModalState(!languageModalState);
    }
  };
  const toggleRemindersModal = () => setRemindersModal(!remindersModal);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
            <div className="roe-card-style roe-shadow-2 whitelight">
              <div className="roe-card-header pb-0">
                <IntlMessages id="sidebar.settings" />
              </div>
              <div className="roe-card-body pb-0">
                {/* settings listings */}
                <SettingsLayout
                  toggleCurrencyModal={toggleCurrencyModal}
                  currency={currency}
                  language={language}
                  currencySymbol={currencySymbol}
                  setCurrencySymbol={setCurrencySymbol}
                  reminders={remindersModal}
                  toggleRemindersModal={toggleRemindersModal}
                  toggleLanguageModal={toggleLanguageModal}
                  {...props}
                />
                <ListCurrency
                  currency={currency}
                  updateCurrency={updateCurrency}
                  setCurrencyModal={setCurrencyModal}
                  setCurrencyRedux={(data) => {
                    props.setCurrency(data);
                  }}
                  currencyModal={currencyModal}
                  toggle={toggleCurrencyModal}
                  currencySymbol={currencySymbol}
                  setCurrencySymbol={setCurrencySymbol}
                  {...props}
                />
                <LanguageModal
                  language={language}
                  setLanguage={(data) => {
                    setLanguage(data);
                    getCurrentLanguage(data);
                    props.changeLanguage(data);
                  }}
                  setLanguageModalState={setLanguageModalState}
                  languageModalState={languageModalState}
                  toggle={toggleLanguageModal}
                  {...props}
                />
                <RemindersList
                  reminders={remindersModal}
                  currency={currency}
                  currencySymbol={currencySymbol}
                  setCurrencySymbol={setCurrencySymbol}
                  toggle={toggleRemindersModal}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, { setCurrency, changeLanguage })(
  Settings
);
