import auth from "./auth/reducer";
import authActionUser from "./user/reducer";
import themeChanger from "./themeChanger/reducer";
import currencyAction from "./currency/reducers";
import customerListAction from "./customerList/reducers";
import singleTransactionAction from "./singleTransaction/reducers";
import SelectCustomerAction from "./customer/reducers";
import LanguageSwitcher from "./languageSwitcher/reducer";
import themeSetting from "./themeSettings/reducer";
import scrumboard from "./scrumboard/reducer";
import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

const createReducer = (asyncReducers) =>
  combineReducers({
    auth,
    authActionUser,
    currencyAction,
    customerListAction,
    singleTransactionAction,
    SelectCustomerAction,
    themeChanger,
    LanguageSwitcher,
    themeSetting,
    scrumboard,
    router: routerReducer,
    ...asyncReducers,
  });

export default createReducer;
