const currencyAction = {
  setCurrency: "SET_CURRENCY",
  unSetCurrency: "UNSET_CURRENCY",

  setCurrency: (data) => {
    return {
      type: currencyAction.setCurrency,
      currency: data.currency,
    };
  },
  unSetCurrency: () => {
    return {
      type: currencyAction.unSetCurrency,
      currency: null,
    };
  },
};

export default currencyAction;
