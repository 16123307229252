import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Loader from "../loader/Loader";
import InputIntl from "components/Placeholder/Placeholder";
import TextAreaIntl from "components/Placeholder/TextAreaIntl";
import IntlMessages from "util/intlMessages";
import {
  newCustomer,
  getCustomerList
} from "../../helper/services/customerService";

import AlertError from "../../components/Customer/error/AlertError";
const AddContact = props => {
  const customStyles = {
    paddingTop: "15%",
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  const button = {
    fontSize: "16px",
    textTransform: "capitalize",
    boxShadow: "none!important",
    width: "100%",
    borderRadius: "2px",
    fontWeight: "500",
    background: "#0073e6",
    color: " white",
    paddingLeft: "10px",
    textAlign: "center"
  };
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;
  const errorStyle = {
    color: "red",
    justifyContent: "center",
    fontSize: 15
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          <IntlMessages id={"" + errors[field1]} />
        </span>
      );
    } else {
      return <span />;
    }
  };
  const handleModalSubmit = e => {
    e.preventDefault();
    let { values, handleSubmit } = props;
    if (values.customerName.length >= 100) {
      props.setErrorMessage({
        errors: {
          message: (
            <IntlMessages id="Customer name must have maximum 100 characters long" />
          )
        }
      });
      props.setErrorModal(false);
      props.setErrorModal(true);
    }
    if (e.target.customerName.value && e.target.customerMobileNumber.value) {
      if (values.customerName && values.customerMobileNumber) {
        if (
          values.customerName.length > 0 &&
          values.customerMobileNumber.toString().length >= 6
        ) {
          props.setLoader(true);

          addNewContact(
            {
              name: values.customerName,
              mobileNumber: values.customerMobileNumber
            },
            props
          );
        }
      }
    } else {
      props.setErrorMessage({
        errors: {
          message: <IntlMessages id="Please enter details to add new contact" />
        }
      });
      props.setErrorModal(false);
      props.setErrorModal(true);
    }

    handleSubmit();
  };
  const addNewContact = async (data, props) => {
    //
    let historyProps = props.history.location.state;

    try {
      const responseNewCustomer = await newCustomer({
        name: data.name,
        phoneNumber: data.mobileNumber.toString(),
        countryCode: "91",
        businessId: props.currentBusinessName.id
      });
      if (responseNewCustomer) {
        if (responseNewCustomer.errors) {
          props.setErrorModal(false);
          props.setErrorMessage({
            errors: {
              message: responseNewCustomer.errors.message
            }
          });
          props.setErrorModal(true);
          props.setLoader(false);
        } else {
          //
          // props.BusinessList();
          props.fetchCustomer(props.currentBusinessName.id);
          // setCustomerArr(currentData => currentData, response.data.customer)
          props.toggle(!props.basicAlert);
          props.notify("New contact created successfully !", "success", 5000);

          props.setLoader(false);
        }
      }
    } catch (e) {
      //
      props.notify(e, "error", 5000);
      props.setLoader(false);
      if (e.response) {
        props.setErrorMessage(e.response.data);
        props.setErrorModal(false);
        props.setErrorModal(true);
        // notify.show(err.response.data.errors.message, 'error', 5000)
      } else {
        props.notify(e, "error", 6000);
        // setErrorMessage(e);
        props.setErrorModal(false);
        props.setErrorMessage({
          errors: {
            message: e
          }
        });
        props.setErrorModal(true);
      }
    }
  };
  return (
    <Modal style={customStyles} isOpen={props.basicAlert} toggle={props.toggle}>
      <>
        <form className="" onSubmit={e => handleModalSubmit(e)}>
          <ModalHeader toggle={props.toggle}>
            <IntlMessages id={"Add new customer"} />
          </ModalHeader>

          <ModalBody style={{ paddingBottom: 0 }}>
            <div>
              {props.errorModal && props.errorMessage ? (
                <AlertError errorMessage={props.errorMessage} />
              ) : (
                ""
              )}
              <div className="form-group">
                <InputIntl
                  placeholderid="Name"
                  type={"text"}
                  className="form-control react-form-input"
                  id="customerName"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Error field="customerName" style={errorStyle} />
              </div>
              <div className="form-group">
                <InputIntl
                  placeholderid="Mobile number"
                  type="number"
                  className="form-control react-form-input"
                  id="customerMobileNumber"
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <Error field="customerMobileNumber" style={errorStyle} />
              </div>
            </div>
          </ModalBody>
          <ModalFooter style={{ display: "block", paddingTop: 10 }}>
            <div className="form-group">
              {props.loader ? (
                <Loader />
              ) : (
                <button className="btn btn-button" type="submit" style={button}>
                  <IntlMessages id="Submit" />
                </button>
              )}
            </div>
          </ModalFooter>
        </form>
      </>
    </Modal>
  );
};
export default AddContact;
