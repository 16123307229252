import React, { useState, useEffect } from "react";
import sortBy from "lodash/sortBy";
import IntlMessages from "util/intlMessages";
import LatestActivityWrapper from "./settings";
import {
  people1,
  people2,
  people3,
  profile,
  currency,
  privacy,
  aboutus,
  reminders,
  language,
  help,
  share,
  logout,
  greaterThan
} from "../../helper/constant";

// import ShimmerLoader from "./shimmer/ShimmerLoader";
import moment from "moment";
import { STATIC_BASE_URL } from "helper/services/apiConstants";
const profileImage = {
  borderRadius: "50%",
  border: "1 solid",
  width: 42,
  background: "#0073e6",
  height: 41,
  objectFit: "fill"
};
const InCap = {
  lineHeight: 2,
  fontSize: 20,
  color: "white",
  textAlign: "center"
};

//https: thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
const SettingsLayout = props => {
  useEffect(() => {
    updateCurrencySymbol();
  }, [props.currency]);
  const [loader, setLoader] = useState(false);

  const [onMouseHover, setOnMouseHover] = useState(false);

  function onHoverStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
    document.getElementById(div).style.cursor = "pointer";
    // e.target.style.background = "#f5f5f5";
  }
  function onMouseLeave(div) {
    document.getElementById(div).style.background = "#fff";
    // e.target.style.background = "#fff";
  }

  const updateCurrencySymbol = () => {
    //

    switch (props.currency) {
      case "INR": {
        props.setCurrencySymbol("fa fa-rupee-sign");
        //
        break;
      }
      case "DOLLAR": {
        props.setCurrencySymbol("fa fa-dollar-sign");
        //
        break;
      }
      case "EURO": {
        props.setCurrencySymbol("fa fa-euro-sign");
        break;
      }
      case "POUND": {
        props.setCurrencySymbol("fa fa-pound-sign");
        break;
      }
      case "YEN": {
        props.setCurrencySymbol("fa fa-yen-sign");
        break;
      }
      default: {
      }
    }
  };

  return (
    <LatestActivityWrapper className="fill-height">
      <div className="card-body">
        {/* {
        {/* {props.loader ? (
          <ShimmerLoader props={props} />
        )  */}
        <div>
          {/* <p className="text-muted text-left"> </p> */}
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={1}
            id={`divqw${1}`}
            onMouseEnter={() => onHoverStyle(`divqw${1}`)}
            onMouseLeave={() => onMouseLeave(`divqw${1}`)}
            onClick={props.toggleCurrencyModal}
          >
            <div>
              <img
                style={{
                  borderRadius: "50%",

                  width: 42,
                  height: 35,
                  objectFit: "contain"
                }}
                src={currency}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10   flex-1"
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <p className=" fs-16 demi-flex-start ">
                <IntlMessages id="Currency" />
              </p>
              <small className="fs-12 text-left"></small>
            </div>
            <div>
              <div className="" key={1}>
                <div style={{ marginTop: 1 }}>
                  <small>
                    <i className={props.currencySymbol}></i>
                  </small>
                  <small> </small>
                  <small style={{ paddingRight: 10 }}>
                    {props.currency ? (
                      <IntlMessages id={props.currency} />
                    ) : (
                      "Fetching"
                    )}{" "}
                  </small>
                  <img src={greaterThan} height="18" />
                </div>
              </div>
            </div>
          </div>
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={3}
            id={`divqw${3}`}
            onMouseEnter={() => onHoverStyle(`divqw${3}`)}
            onMouseLeave={() => onMouseLeave(`divqw${3}`)}
            onClick={props.toggleRemindersModal}
          >
            <div>
              <img
                style={{
                  borderRadius: "50%",

                  width: 42,
                  height: 35,
                  objectFit: "contain"
                }}
                src={reminders}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10   flex-1"
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <IntlMessages id="Reminders" />
            </div>
            <div>
              <div key={1} className="">
                <div>
                  <small>
                    <img src={greaterThan} height="18" />
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={4}
            id={`divqw${4}`}
            onMouseEnter={() => onHoverStyle(`divqw${4}`)}
            onMouseLeave={() => onMouseLeave(`divqw${4}`)}
            onClick={props.toggleLanguageModal}
          >
            <div>
              <img
                style={{
                  borderRadius: "50%",

                  width: 42,
                  height: 35,
                  objectFit: "contain"
                }}
                src={language}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10 flex-1 "
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <IntlMessages id="Language" />
            </div>
            <div>
              <div className="" key={1}>
                <div style={{ marginTop: 1 }}>
                  <small style={{ paddingRight: 10 }}>
                    {props.language ? (
                      <IntlMessages id={props.language} />
                    ) : (
                      "Fetching"
                    )}
                  </small>
                  <img src={greaterThan} height="18" />
                </div>
              </div>
            </div>
          </div>
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={5}
            id={`divqw${5}`}
            onMouseEnter={() => onHoverStyle(`divqw${5}`)}
            onMouseLeave={() => onMouseLeave(`divqw${5}`)}
            onClick={() => window.open(`${STATIC_BASE_URL}#contact`, "_blank")}
          >
            <div>
              <img
                style={{
                  borderRadius: "50%",

                  width: 42,
                  height: 35,
                  objectFit: "contain"
                }}
                src={help}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10 flex-1 "
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <IntlMessages id="Help" />
            </div>
            <div>
              <div className="" key={1}>
                <div>
                  <small>
                    <img src={greaterThan} height="18" />
                  </small>
                </div>
              </div>
            </div>
          </div>{" "}
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={6}
            id={`divqw${6}`}
            onMouseEnter={() => onHoverStyle(`divqw${6}`)}
            onMouseLeave={() => onMouseLeave(`divqw${6}`)}
          >
            <div>
              <img
                style={{
                  borderRadius: "50%",

                  width: 42,
                  height: 35,
                  objectFit: "contain"
                }}
                src={share}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10 flex-1 "
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <IntlMessages id="Share" />
            </div>
            <div>
              <div className="" key={1}>
                <div>
                  <small>
                    <img src={greaterThan} height="18" />
                  </small>
                </div>
              </div>
            </div>
          </div>{" "}
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={7}
            id={`divqw${7}`}
            onMouseEnter={() => onHoverStyle(`divqw${7}`)}
            onMouseLeave={() => onMouseLeave(`divqw${7}`)}
            onClick={() => window.open(`${STATIC_BASE_URL}#about`, "_blank")}
          >
            <div>
              <img
                style={{
                  borderRadius: "50%",

                  width: 42,
                  height: 35,
                  objectFit: "contain"
                }}
                src={aboutus}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10 flex-1 "
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <IntlMessages id="About us" />
            </div>
            <div>
              <div className="" key={1}>
                <div>
                  <small>
                    <img src={greaterThan} height="18" />
                  </small>
                </div>
              </div>
            </div>
          </div>{" "}
          <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={8}
            id={`divqw${8}`}
            onMouseEnter={() => onHoverStyle(`divqw${8}`)}
            onMouseLeave={() => onMouseLeave(`divqw${8}`)}
            onClick={() => window.open(`${STATIC_BASE_URL}#contact`, "_blank")}
          >
            <div>
              <img
                style={{
                  borderRadius: "50%",

                  width: 42,
                  height: 35,
                  objectFit: "contain"
                }}
                src={privacy}
                alt="s"
              />
            </div>

            {/* <img className="img-sm" src={profile} alt="profile" /> */}
            <div
              className=" pb-0 mlr-10 flex-1 "
              style={{ textAlign: "left", marginTop: 5 }}
            >
              <IntlMessages id="Privacy policy" />
            </div>
            <div>
              <div className="" key={1}>
                <div>
                  <small>
                    <img src={greaterThan} height="18" />
                  </small>
                </div>
              </div>
            </div>
          </div>{" "}
          {/*    <div
            // style={onMouseHover ? onHoverStyle : {}}
            className="flex-x ptb-15 mb-6 border-bottom "
            key={9}
            id={`divqw${ 9}`}
            onMouseEnter={() => onHoverStyle(`divqw${ 9}`)}
            onMouseLeave={() => onMouseLeave(`divqw${ 9}`)}
          >
            <div>
              <img
                style={{
                  borderRadius: "50%",

                  width: 42,
                  height:35,
                  objectFit: "contain",
                }}
                src={logout}
                alt="s"
              />
            </div>

        
            <div
              className=" pb-0 mlr-10 flex-1 "
              style={{ textAlign: "left", marginTop: 5 }}
            >
              Logout
            </div>
            <div>
              <div className="" key={1}>
                <div>
                  <small>
                    <img src={greaterThan} height="20" />
                  </small>
                </div>
              </div>
            </div>
          </div>{" "}
        */}
        </div>
      </div>
    </LatestActivityWrapper>
  );
};

export default SettingsLayout;
