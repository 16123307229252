import React, { useState, useEffect } from "react";
import sortBy from "lodash/sortBy";
import moment from "moment";
// import "moment/locale/hi";
// import "moment/locale/gu";
// import "moment/locale/en-u";
import LatestActivityWrapper from "./customerContact";
import { people1, people2, people3, profile } from "./../../helper/constant";
import ShimmerLoader from "./shimmer/ShimmerLoader";
import IntlMessages from "util/intlMessages";
import { amountFormatter } from "helper/methods";

const profileImage = {
  borderRadius: "50%",
  border: "1 solid",
  width: 42,
  background: "#0073e6",
  height: 41,
  objectFit: "fill",
  textAlign: "center"
};
const InCap = {
  lineHeight: 2,
  fontSize: 20,
  color: "white",
  textAlign: "center"
};

//https: thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
const CustomerContact = props => {
  const [loader, setLoader] = useState(false);
  const [onMouseHover, setOnMouseHover] = useState(false);
  let filteredArray = [];
  var dateArr = props.customerData.filter(item => item.transaction.length > 0);
  var noDateArr = props.customerData.filter(
    item => item.transaction.length == 0
  );
  dateArr.sort(function(a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return (
      new Date(b.transaction[0].updatedAt) -
      new Date(a.transaction[0].updatedAt)
    );
  });
  var finalArr = dateArr.sort(function(a, b) {
    // Turn your strings into dates, and then subtract them
    // to get a value that is either negative, positive, or zero.
    return (
      new Date(b.transaction[0].updatedAt) -
      new Date(a.transaction[0].updatedAt)
    );
  });
  //console.log("f", finalArr);
  finalArr.push.apply(finalArr, noDateArr);
  //console.log(finalArr);

  function onHoverStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
    document.getElementById(div).style.cursor = "pointer";
    // e.target.style.background = "#f5f5f5";
  }
  function onMouseLeave(div) {
    document.getElementById(div).style.background = "#fff";
    // e.target.style.background = "#fff";
  }
  // let ans = getData(props.customerData);
  // //console.log("concat", filteredArray);
  const updateDateLanguage = date => {
    // props.dateLocalization();
    moment.locale(props.language);
    return moment(date).fromNow();
  };
  return (
    <LatestActivityWrapper className="fill-height col-sm-12 col-md-12 col-lg-12 col-xs-12">
      <div
        className="card-body"
        // style={{ width: "710px" }}
      >
        {/* {console.log("filtered array", finalArr)} */}
        {props.loader ? (
          <ShimmerLoader props={props} />
        ) : finalArr.length > 0 ? (
          <div>
            <p className="text-muted text-left">
              {finalArr.length} <IntlMessages id="Customers" />
            </p>
            {finalArr.map((customer, i) => {
              return (
                <div
                  style={{ paddingLeft: "2px" }}
                  // style={onMouseHover ? onHoverStyle : {}}
                  className="flex-x ptb-15 mb-6 border-bottom "
                  key={i}
                  id={`divqw${i}`}
                  onMouseEnter={() => onHoverStyle(`divqw${i}`)}
                  onMouseLeave={() => onMouseLeave(`divqw${i}`)}
                  onClick={() =>
                    props.showCustomerTransaction(
                      customer.id,
                      localStorage.getItem("Briefbook:currentBusiness"),
                      customer
                    )
                  }
                >
                  {customer.customerMeta.length > 0 ? (
                    <div>
                      <img
                        style={{
                          borderRadius: "50%",
                          // border: "1px solid ",
                          width: 42,
                          height: 41,
                          objectFit: "contain"
                        }}
                        src={customer.customerMeta.map(url => {
                          return url.url;
                        })}
                        alt="profile"
                      />
                    </div>
                  ) : (
                    <div style={profileImage}>
                      <span style={InCap}>
                        {customer.name.charAt(0).toUpperCase()}
                      </span>
                    </div>
                  )}
                  {/* <img className="img-sm" src={profile} alt="profile" /> */}
                  <div
                    className=" pb-8 mlr-10 flex-1"
                    style={{ textAlign: "left" }}
                  >
                    <p
                      className=" fs-15 demi-flex-start "
                      style={{ wordWrap: "break-word" }}
                      // style={{ marginBottom: -3 }}
                    >
                      {customer.name}
                    </p>
                    <small
                      className="fs-12 text-left"
                      key={moment(new Date(customer.createdAt)).fromNow()}
                    >
                      {customer.transaction.length > 0
                        ? updateDateLanguage(
                            new Date(customer.transaction[0].updatedAt)
                          )
                        : /*  moment(
                            new Date(customer.transaction[0].updatedAt)
                          ).fromNow() */

                          "-"}
                    </small>
                  </div>
                  <div>
                    {customer.transaction?.length > 0 ? (
                      customer.transaction.map(balance =>
                        balance.balance != null && balance.balance > 0 ? (
                          <div style={{ display: "grid" }} key={i}>
                            <small
                              style={{
                                color: "green",
                                fontSize: 18,
                                textAlign: "center",
                                paddingRight: 0
                              }}
                              key={balance.id}
                            >
                              <i className={props.currency}></i>
                              {/* {balance.balance.toString().replace("-", "")} */}
                              {amountFormatter(balance.balance)}
                            </small>
                            <small>
                              {" "}
                              <IntlMessages id={"You will give"} />{" "}
                            </small>
                          </div>
                        ) : balance.balance != null && balance.balance <= 0 ? (
                          <div style={{ display: "grid" }} key={i}>
                            <small
                              style={{
                                color: "red",
                                fontSize: 18,
                                textAlign: "center"
                              }}
                              key={balance.id}
                            >
                              <i className={props.currency}></i>
                              {amountFormatter(balance.balance)}
                            </small>
                            <small>
                              <IntlMessages id={"You will get"} />{" "}
                            </small>
                          </div>
                        ) : (
                          <div key={i} className="pt-15">
                            {" "}
                            <small
                              className=""
                              style={{ fontSize: 18, marginRight: "20px" }}
                            >
                              <i className={props.currency}></i>0
                            </small>
                            <small className="pt-20"></small>
                          </div>
                        )
                      )
                    ) : (
                      <div>
                        <div className="pt-15">
                          {" "}
                          <small
                            className="pr-18 pt-10"
                            style={{ fontSize: 18 }}
                          >
                            <i className={props.currency}></i>0
                          </small>
                          <small className="pt-20"></small>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <p
            className="fs-15  px15 board-list-title text-muted"
            style={{
              margin: 50
            }}
          >
            Add customer to record transaction(s)
          </p>
        )}
      </div>
    </LatestActivityWrapper>
  );
};

export default CustomerContact;
