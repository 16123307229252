import React, { Fragment } from "react";
import { injectIntl, FormattedMessage } from "react-intl";

// const InjectMassage = (props) => <FormattedMessage {...props} />;

const InjectMassage = (props) => {
  return (
    <FormattedMessage
      {...props}
      children={(message) => <Fragment>{message}</Fragment>}
    />
  );
};

export default injectIntl(InjectMassage, {
  withRef: false,
});
