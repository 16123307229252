import React, { useState, useEffect } from "react";
import countBy from "lodash/countBy";
import IntlMessages from "util/intlMessages";
import LatestActivityWrapper from "./TransactionList";
import { amountFormatter } from "helper/methods";
import ShimmerLoader from "../shimmer/ShimmerLoader";
import moment from "moment";
const profileImage = {
  borderRadius: "50%",
  border: "1 solid",
  width: 42,
  background: "#0073e6",
  height: 41,
  objectFit: "fill"
};
const InCap = {
  lineHeight: 2,
  fontSize: 20,
  color: "white",
  textAlign: "center"
};

//https: thomaskekeisen.de/en/blog/array-date-sort-lodash-momentjs/
const TransactionList = props => {
  const [loader, setLoader] = useState(false);
  const [totalTransaction, setTotalTransaction] = useState(0);
  const [customerTransactionList, setCustomerTransactionList] = useState(null);

  useEffect(() => {
    setTransactionList();
    doHandleUserLanguage();
    return () => {
      doMakeEnglishLanguageDefault();
    };
  }, [props.transaction]);

  const doMakeEnglishLanguageDefault = () => {
    import("moment").then(() => {});
  };
  const doHandleUserLanguage = () => {
    if (props.LanguageSwitcher.language.locale == "en") {
      return;
    }

    if (props.LanguageSwitcher.language.locale == "gu") {
      import("moment/locale/gu").then(() => {});
    }

    if (props.LanguageSwitcher.language.locale == "hi") {
      import("moment/locale/hi").then(() => {});
    }
  };

  const setTransactionList = () => {
    setTotalTransaction(
      props.transaction.singleTransaction &&
        props.transaction.singleTransaction.filter(check => {
          if (check.isDeleted === false) return check;
        }).length
    );
    if (props.transaction) {
      if (props.transaction.length > 0) {
        setCustomerTransactionList(props.transaction);
      }
    }
  };

  function onHoverStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
    document.getElementById(div).style.cursor = "pointer";
    // e.target.style.background = "#f5f5f5";
  }
  function onMouseLeave(div) {
    document.getElementById(div).style.background = "#fff";
    // e.target.style.background = "#fff";
  }
  // let ans = getData(props.customerData);
  // //console.log("concat", filteredArray);
  return (
    <LatestActivityWrapper className="fill-height">
      <div className="card-body">
        {/* {console.log("Transactionsssss", props.transaction)} */}
        {props.loader ? (
          <ShimmerLoader props={props} />
        ) : props.transaction ? (
          <div>
            {/* <div className="flex-x ptb-15 mb-6 border-bottom">
              <div
                className=" pb-8 mlr-10 flex-1"
                style={{ textAlign: "left" }}
              >
                <p className=" fs-18 bold-text demi-flex-start ">
                  {props.transaction.balance > 0
                    ? "You will give "
                    : "You will get"}
                </p>
              </div>
              <div style={{ display: "grid" }}>
                <small
                  style={{
                    color: props.transaction.balance > 0 ? "green" : "red",
                    fontSize: 18,
                    textAlign: "center",
                  }}
                  // key={balance.id}
                >
                  <i className={props.currency}></i>
                  {props.transaction.balance &&
                    props.transaction.balance.toString().replace("-", "")}
                </small>
              </div>
            </div> */}
            <p className="text-muted text-left">
              {totalTransaction > 0 ? (
                <>
                  {totalTransaction} <IntlMessages id="Transaction" />
                </>
              ) : (
                ""
              )}
            </p>
            {props.transaction.singleTransaction &&
            totalTransaction > 0 &&
            props.transaction.singleTransaction.length > 0 ? (
              props.transaction.singleTransaction.map((transaction, i) => {
                if (transaction.isDeleted == false) {
                  return (
                    <div
                      // style={onMouseHover ? onHoverStyle : {}}
                      className="flex-x ptb-15 mb-6 border-bottom "
                      key={i}
                      id={`divqw${i}`}
                      onMouseEnter={() => onHoverStyle(`divqw${i}`)}
                      onMouseLeave={() => onMouseLeave(`divqw${i}`)}
                      onClick={() => {
                        props.SingleTransaction(
                          transaction,
                          props.transaction.id
                        );
                        props.history.push("/transaction-status", {
                          transaction: transaction,
                          currency: props.currency,
                          business: props.history.location.state.business,
                          parentTransactionId: props.transaction.id,
                          page: props.location.state.page
                        });
                      }}
                    >
                      <div
                        className=" pb-8 mlr-10 flex-1"
                        style={{ textAlign: "left" }}
                      >
                        <p
                          className=" fs-15 demi-flex-start "
                          style={{ wordWrap: "break-word" }}
                        >
                          {/* {transaction.amount} */}

                          {moment(new Date(transaction.date)).format(
                            " Do MMM YY [At] h:mm A"
                          )}
                        </p>
                        <small
                          style={{
                            wordBreak: "break-word"
                          }}
                          className="fs-12 text-left text-muted"
                          // key={moment(new Date(customer.createdAt)).fromNow()}
                        >
                          {transaction.notes ? (
                            transaction.notes
                          ) : (
                            <IntlMessages id="No notes added" />
                          )}
                        </small>
                        <small className="ml-10">
                          {transaction.singleTransactionMeta &&
                          transaction.singleTransactionMeta.length > 0 ? (
                            <i
                              className="fa fa-paperclip text-muted"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            ""
                          )}
                        </small>
                      </div>
                      {transaction.direction === "INBOUND" ? (
                        <div style={{ display: "grid" }} key={i}>
                          <small style={{ fontWeight: 800 }}>
                            <IntlMessages id="You got" />{" "}
                          </small>
                          <small
                            style={{
                              color: "green",
                              fontSize: 18,
                              textAlign: "center"
                            }}
                            // key={balance.id}
                          >
                            <i className={props.currency}></i>
                            {amountFormatter(transaction.amount)}
                            {/* {transaction.amount.toString().replace("-", "")} */}
                          </small>
                        </div>
                      ) : (
                        <div style={{ display: "grid" }} key={i}>
                          <small style={{ fontWeight: 800 }}>
                            <IntlMessages id="You gave" />{" "}
                          </small>
                          <small
                            style={{
                              color: "red",
                              fontSize: 18,
                              textAlign: "center"
                            }}
                            // key={balance.id}
                          >
                            <i className={props.currency}></i>
                            {amountFormatter(transaction.amount)}
                            {/* {transaction.amount.toString().replace("-", "")} */}
                          </small>
                        </div>
                      )}
                    </div>
                  );
                }
              })
            ) : (
              <p
                className="text-muted  "
                style={{
                  margin: 50,
                  wordWrap: "break-word"
                }}
              >
                {props.LanguageSwitcher &&
                props.LanguageSwitcher.language.languageId === "english" ? (
                  <>
                    <IntlMessages id="Initiate your first transaction with" />{" "}
                    {props.customerName}{" "}
                  </>
                ) : (
                  <>
                    {props.customerName}{" "}
                    <IntlMessages id="Initiate your first transaction with" />{" "}
                  </>
                )}
              </p>
            )}
          </div>
        ) : (
          <p className="fs-20 bold-text px15 board-list-title text-muted">
            <IntlMessages id="No transaction" />
          </p>
        )}
        {/* TODO: You got AND You gave BUTTON */}
        <div className="roe-footer"></div>
      </div>
    </LatestActivityWrapper>
  );
};

export default TransactionList;
