import React, { Component, useState, useEffect } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InputIntl from "components/Placeholder/Placeholder";
// import "moment/locale/hi";
// import "moment/locale/gu";
import moment from "moment";
import IntlMessages from "util/intlMessages";
import Notifications, { notify } from "react-notify-toast";
import { Progress } from "react-sweet-progress";
import Loader from "../components/loader/Loader";
import { appLogo } from "../helper/constant";
import customerListAction from "redux/customerList/actions";
//customer
import CustomerContact from "components/Customer/CustomerContact";
// import AlertError from "../components/Customer/error/AlertError";
import enhancer from "./pages/authentication/enhancer/newCustomerContactEnhancer";
import BalanceSheet from "components/Customer/BalanceSheet";
import AddContact from "../components/Customer/newCustomerContact";

//Business
import BusinessAlertError from "../components/Business/error/AlertError";
import ListBusiness from "../components/Business/listBusiness/ListBusiness";
import AddBusiness from "../components/Business/addBusiness/AddBusiness";
import BusinessWidget from "../components/Business/BusinessWidget";

//service
import {
  newCustomer,
  getCustomerList
} from "../helper/services/customerService";
import { amountFormatter } from "helper/methods";

import { fetchCurrency } from "../helper/services/settingsService";
import { getUser } from "../helper/services/authentication";

import { fetchBusinessList as getBusinessList } from "../helper/services/businessService";

// every functionality should be in a component
//  modal for Add new customer contact should be in a new component and call this component instead of using code in a same file
import SelectCustomerAction from "redux/customer/actions";
import { getCurrentLanguage } from "settings/languageConfig";
import languageActions from "redux/languageSwitcher/actions";
import currencyAction from "redux/currency/actions";
const { setCurrency } = currencyAction;
const { setCustomers } = customerListAction;
const { selectCustomer } = SelectCustomerAction;
const { changeLanguage } = languageActions;
const Home = props => {
  ////
  const [changecurrentBusiness, setChangeCurrentBusiness] = useState(null);
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [currentBusinessName, setCurrentBusiness] = useState(null);
  const [businessmodal, setBusinessmodal] = useState(false);
  const [addbusinessmodal, setAddBusinessmodal] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [tooltipOpenBusinessList, settoolTipOpenBusinessList] = useState(false);
  const [tooltipOpen, settTooltipOpen] = useState(false);
  const [basicAlert, setBasicAlert] = useState(false);
  const [businessListArray, setBusinessListArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [BusinessLoader, setBusinessLoader] = useState(false);
  const [customerArr, setCustomerArr] = useState([]);
  const [getTotalGive, setTotalGive] = useState(0);
  const [getTotalGot, setTotalGot] = useState(0);
  const [errorModal, setErrorModal] = useState(false);

  const [customerlistLoader, setCustomerListLoader] = useState(false);
  const [addBusinesserrorModal, setAddBusinesserrorModal] = useState({
    status: false,
    errorMessage: ""
  });
  const [errorMessage, setErrorMessage] = useState(null);
  const [hover, setHover] = useState(false);
  const [searchContact, setSearchContact] = useState(false);
  const [searchContactQueryResult, setSearchContactQueryResult] = useState(
    null
  );

  useEffect(() => {
    // checkUser();
    if (
      props.auth.user?.language?.toString() ===
      props.LanguageSwitcher.languageid
    ) {
    } else {
      props.changeLanguage(props.auth.user.language.toLowerCase());
      getCurrentLanguage(props.auth.user.language.toLowerCase());
    }
    BusinessList(false, true);
    getCurrency();
  }, []);

  // const checkUser = async () => {
  //   try {
  //     const response = await getUser();
  //     if (response) {
  //       // dateLocalization(response.user.language);
  //       props.changeLanguage(response.user.language.toLowerCase());
  //       getCurrentLanguage(response.user.language.toLowerCase());
  //     }
  //   } catch (e) {
  //
  //   }
  // };
  const {
    values,
    handleChange,
    handleBlur,
    errors,
    touched,
    submitCount
  } = props;
  const toggleBasicAlert = () => {
    setBasicAlert(!basicAlert);
  };

  const toggleAddNewContact = () => {
    setBasicAlert(!basicAlert);
    setErrorMessage("");
    setErrorModal(false);
  };
  const errorStyle = {
    color: "red",
    justifyContent: "center",
    fontSize: 15
  };
  const Error = props => {
    const field1 = props.field;
    if ((errors[field1] && touched[field1]) || submitCount > 0) {
      return (
        <span className={props.class ? props.class : "error-msg"}>
          {errors[field1]}
        </span>
      );
    } else {
      return <span />;
    }
  };
  const button = {
    fontSize: "16px",
    textTransform: "capitalize",
    boxShadow: "none!important",
    width: "100%",
    borderRadius: "2px",
    fontWeight: "500",
    background: "#0073e6",
    color: " white",
    paddingLeft: "10px",
    textAlign: "center"
  };
  const customStyles = {
    paddingTop: "15%",
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)"
    }
  };
  const showCustomerTransaction = (customerid, businessid, data) => {
    // redirect to customer Transaction
    props.setCustomers({ businessId: businessid, customers: data });
    props.selectCustomer({ customer: data });
    props.history.push("/customer-transaction", {
      customerid,
      businessid,
      currencySymbol: currencySymbol,
      customerData: data,
      business: currentBusinessName,
      page: "Home"
    });
  };
  const searchFilter = query => {
    /* fetching the input and checking if exist in customerlistarray */
    let filteredContacts;
    if (!query) {
      // setSearchContact(false);
      setSearchContactQueryResult(null);
    } else {
      filteredContacts = customerArr.filter(customer => {
        return customer.name.toLowerCase().indexOf(query.toLowerCase()) !== -1;
      });
      // setSearchContact(true);
      setSearchContactQueryResult(filteredContacts);
    }

    /* To display content of array of search filter what I can do is to pass this array to Contact Component */
  };
  const updateCurrencySymbol = (currency = currency) => {
    switch (currency) {
      case "INR": {
        setCurrencySymbol("fa fa-rupee-sign");
        //
        break;
      }
      case "DOLLAR": {
        setCurrencySymbol("fa fa-dollar-sign");
        //
        break;
      }
      case "EURO": {
        setCurrencySymbol("fa fa-euro-sign");
        break;
      }
      case "POUND": {
        setCurrencySymbol("fa fa-pound-sign");
        break;
      }
      case "YEN": {
        setCurrencySymbol("fa fa-yen-sign");
        break;
      }
      default: {
      }
    }
  };

  const getCurrency = async () => {
    const auth = JSON.parse(localStorage.getItem("persist:root"));
    const LocalDBtoken = JSON.parse(auth.auth).accessToken;
    if (LocalDBtoken) {
      const currencyResponse = await fetchCurrency();

      if (currencyResponse) {
        //
        updateCurrencySymbol(currencyResponse);
        props.setCurrency({ currency: currencyResponse });
      }
    } else {
      const propsToken = props.history.location.state.token;
      const currencyResponse = await fetchCurrency(propsToken);

      if (currencyResponse) {
        //
        updateCurrencySymbol(currencyResponse);
        props.setCurrency({ currency: currencyResponse });
      }
    }
  };

  const BusinessList = async (name = false, isLastUpdatedAt = false) => {
    setCustomerListLoader(true);
    const auth = JSON.parse(localStorage.getItem("persist:root"));
    const LocalDBtoken = JSON.parse(auth.auth).accessToken;
    const currentbusinessDb = localStorage.getItem("Briefbook:currentBusiness");
    //
    if (LocalDBtoken) {
      const responseBusinessList = await getBusinessList();
      //
      if (responseBusinessList) {
        let businessArr = responseBusinessList.business;

        if (businessArr?.length > 0) {
          businessArr = businessArr.sort((a, b) => {
            return new Date(b.updatedAt) - new Date(a.updatedAt);
          });
          ////
          setBusinessListArray(businessArr);
          if (currentbusinessDb && currentbusinessDb !== undefined) {
            businessArr.map(data => {
              if (name && name == data.name) {
                localStorage.setItem("Briefbook:currentBusiness", data.id);
                setCurrentBusiness({
                  id: data.id,
                  name: data.name
                });
              } else {
                if (data.id == parseInt(currentbusinessDb)) {
                  setCurrentBusiness({
                    id: data.id,
                    name: data.name
                  });
                }
              }
            });
            fetchCustomer(parseInt(currentbusinessDb));
          } else {
            setCurrentBusiness({
              id: businessArr[0].id,
              name: businessArr[0].name
            });
            fetchCustomer(businessArr[0].id);
          }
        }
      }
    } else {
      const propsToken = props.history.location.state.token;

      const responseBusinessList = await getBusinessList(propsToken);
      //
      if (responseBusinessList) {
        const businessArr = responseBusinessList.business;
        ////
        if (businessArr.length > 0) {
          ////
          setBusinessListArray(businessArr);
          if (currentbusinessDb) {
            businessArr.map(data => {
              if (data.id == parseInt(currentbusinessDb)) {
                setCurrentBusiness({
                  id: data.id,
                  name: data.name
                });
              }
            });
            //
            fetchCustomer(parseInt(currentbusinessDb));
          } else {
            setCurrentBusiness({
              id: businessArr[0].id,
              name: businessArr[0].name
            });
            fetchCustomer(businessArr[0].id);
          }
        }
      }
    }
    // if (
    //   currentBusinessName &&
    //   refresh !== true &&
    //   currentbusinessDb !== currentBusinessName.id
    // ) {
    //   try {
    //     const responseBusinessList = await getBusinessList();
    //     if (responseBusinessList) {
    //
    //       const businessArr = responseBusinessList.business;
    //       setBusinessListArray(businessArr);
    //     }
    //     fetchCustomer(currentbusinessDb);
    //   } catch (e) {
    //
    //     notify.show(e, "error", 5000);
    //   }
    // } else {
    //   try {
    //
    //     if (refresh !== true && propData && propData.business.length > 0) {
    //
    //       const businessArr = propData.business;
    //       setBusinessListArray(businessArr);
    //       setCurrentBusiness({
    //         id: businessArr[0].id,
    //         name: businessArr[0].name,
    //       });
    //       localStorage.setItem(
    //         "Briefbook:currentBusiness",
    //         businessArr[0].id
    //       );
    //       fetchCustomer(businessArr[0].id);
    //     } else {
    //
    //       const responseBusinessList = await getBusinessList();
    //       //
    //       if (responseBusinessList) {
    //         const businessArr = responseBusinessList.business;
    //         ////
    //         if (businessArr.length > 0) {
    //           ////
    //           setBusinessListArray(businessArr);
    //           if (currentbusinessDb) {
    //             businessArr.map((data) => {
    //               if (data.id == parseInt(currentbusinessDb)) {
    //                 setCurrentBusiness({
    //                   id: data.id,
    //                   name: data.name,
    //                 });
    //               }
    //             });
    //
    //             fetchCustomer(parseInt(currentbusinessDb));
    //           } else {
    //             setCurrentBusiness({
    //               id: businessArr[0].id,
    //               name: businessArr[0].name,
    //             });
    //             fetchCustomer(businessArr[0].id);
    //           }
    //         }
    //       }
    //     }
    //   } catch (error) {
    //
    //   }
    // }

    //
  };

  /* get total of got and give  */
  const getSumTotalTransaction = customer => {
    /*TODO
 here first we will check if transaction is null than store null value 
than if not null check if value is greater than 0 ,than store in gottotal
else if its less than 0 than givetotal variable

*/
    //
    let totalgivevalue = 0;
    let totalgotvalue = 0;
    if (customer.length > 0) {
      customer.map(customer => {
        if (customer.transaction[0]) {
          if (customer.transaction[0].balance > 0) {
            //
            //   "customer transactions balance greater than 0 give ",
            //   customer.transaction[0].balance
            // );
            totalgivevalue += customer.transaction[0].balance;
          }

          if (customer.transaction[0].balance < 0) {
            //
            totalgotvalue += customer.transaction[0].balance;
          }
        }

        setTotalGive(totalgivevalue);
        setTotalGot(totalgotvalue);
      });
    } else {
      setTotalGive(0);
      setTotalGot(0);
      //here not storing value as its undefined and undefined means it has 0 value
      //
    }
  };
  const fetchCustomer = async id => {
    setCustomerListLoader(true);
    if (!id || id == undefined || id == NaN) {
    }
    let historyProps = props.history.location.state;
    try {
      const responseCustomer = await getCustomerList(
        id,
        props.auth.accessToken
      );
      //
      if (responseCustomer) {
        //

        getSumTotalTransaction(responseCustomer.customers);
        setCustomerArr(responseCustomer.customers);
        setCustomerListLoader(false);
        props.setCustomers({
          businessId: id,
          customers: responseCustomer.customers
        });

        localStorage.setItem("Briefbook:currentBusiness", id);
      }
      if (!responseCustomer) {
        //
        getSumTotalTransaction(responseCustomer.customers);
        // setCustomerArr(responseCustomer.customers);
        setCustomerListLoader(false);
      }
    } catch (err) {
      console.error(err);
      setCustomerListLoader(false);
    }
  };
  const notifyStatus = (message, status, timer) => {
    notify.show(message, status, timer);
  };
  const ToggleBusinessModal = () => setBusinessmodal(!businessmodal);
  const ToggleAddBusinessModal = () => setAddBusinessmodal(!addbusinessmodal);
  const changeBusiness = business => {
    setCustomerListLoader(true);
    setLoader(true);
    setCurrentBusiness({
      id: business.id,
      name: business.name
    });
    localStorage.setItem("Briefbook:currentBusiness", business.id);

    fetchCustomer(business.id);
    setBusinessmodal(!businessmodal);
    setLoader(false);
    setCustomerListLoader(false);
  };
  // const dateLocalization = () => {
  //   moment.locale(props.LanguageSwitcher.language.locale);
  // };
  const ViewReportRedirect = () => {
    props.history.push("/view-report", {
      page: "Home"
    });
  };

  return (
    <div>
      <div className="container">
        <Notifications />
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />

          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
            <BusinessWidget
              currentBusinessName={currentBusinessName}
              businessListArray={businessListArray}
              ToggleBusinessModal={ToggleBusinessModal}
              BusinessList={BusinessList}
              appLogo={appLogo}
            />
            <div className="roe-card-style roe-shadow-2 whitelight mb-10">
              <BalanceSheet
                ViewReportRedirect={() => ViewReportRedirect()}
                currency={props.currencyAction.symbol}
                section1={{
                  title: <IntlMessages id={"You will get"} />,

                  amount: getTotalGot ? getTotalGot : "0"
                }}
                section2={{
                  title: <IntlMessages id={"You will give"} />,
                  amount: getTotalGive ? getTotalGive : "0"
                }}
              ></BalanceSheet>
            </div>
            <div className="roe-card-style roe-shadow-2 whitelight">
              <div className="roe-card-header pb-0">
                <div>
                  {/* <input
                    type={"text"}
                    className="fs-14 medium-text plr-10 form-control react-form-input"
                    placeholder={"Search Customer"}
                    id="contactSearch"
                    onBlur={handleBlur}
                    onChange={(e) => searchFilter(e.target.value)}
                  /> */}
                  <InputIntl
                    type={"text"}
                    className="fs-14 medium-text plr-10 form-control react-form-input"
                    placeholderid={"Search Customers"}
                    id="contactSearch"
                    onBlur={handleBlur}
                    onChange={e => searchFilter(e.target.value)}
                  />
                </div>
              </div>

              <div
                align="center"
                className="roe-card-body"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <CustomerContact
                  showCustomerTransaction={(cid, bid, cdata) =>
                    showCustomerTransaction(cid, bid, cdata)
                  }
                  language={props.LanguageSwitcher.language.locale}
                  currency={props.currencyAction.symbol}
                  currentBusinessName={currentBusinessName}
                  customerData={
                    searchContactQueryResult !== null
                      ? searchContactQueryResult
                      : customerArr
                  }
                  loader={customerlistLoader}
                  // function to Redirect to customer transaction
                />
                <AddContact
                  loader={loader}
                  setLoader={setLoader}
                  errorModal={errorModal}
                  setErrorModal={setErrorModal}
                  errorMessage={errorMessage}
                  setErrorMessage={setErrorMessage}
                  // addNewContact={(data, props) => addNewContact(data, props)}
                  notify={(message, status, timer) =>
                    notifyStatus(message, status, timer)
                  }
                  BusinessList={BusinessList}
                  fetchCustomer={fetchCustomer}
                  currentBusinessName={currentBusinessName}
                  basicAlert={basicAlert}
                  setBasicAlert={setBasicAlert}
                  toggle={toggleAddNewContact}
                  {...props}
                />

                <ListBusiness
                  businessmodal={businessmodal}
                  toggle={ToggleBusinessModal}
                  businessListArray={businessListArray}
                  appLogo={appLogo}
                  // changecurrentBusiness={changecurrentBusiness}
                  currentBusinessName={currentBusinessName}
                  changeBusiness={business => {
                    changeBusiness(business);
                  }}
                  setAddBusinessmodal={setAddBusinessmodal}
                  ToggleAddBusinessModal={ToggleAddBusinessModal}
                ></ListBusiness>
                <AddBusiness
                  toggle={ToggleAddBusinessModal}
                  addbusinessmodal={addbusinessmodal}
                  Error={Error}
                  setAddBusinessmodal={setAddBusinessmodal}
                  BusinessList={() => BusinessList()}
                  setCurrentBusiness={setCurrentBusiness}
                  changeBusiness={business => changeBusiness(business)}
                  notify={(message, status, timer) =>
                    notifyStatus(message, status, timer)
                  }
                  {...props}
                />
                {/* position: fixed;
bottom: 50px;    right: 0px;    
margin: auto;    
left: 0px;    
background-color: #0073e6;    
color: rgb(255, 255, 255);    
border: 1px solid transparent;    
border-radius: 28px;    
display: block;   
filter: drop-shadow(0px 1px 5px grey); */}
              </div>
            </div>
            <button
              className=""
              style={{
                width: "100%",
                padding: "9px 0px",
                marginTop: "24px",
                borderRadius: "50px",
                border: "none",
                backgroundColor: "#0073e6",
                color: "rgb(255, 255, 255)"
              }}
              onMouseOver={() => setHover(true)}
              onMouseOut={() => setHover(false)}
              onClick={toggleBasicAlert}
            >
              <i
                className="fa fa-plus pt-0 pr-1"
                style={{
                  paddingRight: "10px",
                  paddingRight: " 4px !important"
                }}
              />

              <IntlMessages id={"Add new customer"} />
            </button>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />
        </div>
        <div></div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    ...state
  };
};

export default compose(
  withRouter,
  enhancer,
  connect(mapStateToProps, {
    setCustomers,
    selectCustomer,
    changeLanguage,
    setCurrency
  })
)(Home);
