import React, { useEffect, useState } from "react";
import { Table, Tooltip } from "reactstrap";
import { compose } from "redux";
import { connect } from "react-redux";
import IntlMessages from "util/intlMessages";
import Notifications, { notify } from "react-notify-toast";
import Loader from "components/loader/Loader";
import Loadmodal from "components/modalloader/Loadmodal";
import moment from "moment";
import DatePicker from "react-datepicker";
import { fetchSummaryToPDF } from "helper/services/transactionReport";
import { ReportWrapper } from "./reportwrapper.style";
import { fetchSummary } from "helper/services/transactionReport";
import { amountFormatter, calculateTotalAmount } from "helper/methods";

const ViewReport = props => {
  const [loader, setLoader] = useState(false);
  const [isDownloadLoader, setDownloadLoader] = useState(false);
  const [trialBalanceData, setTrialBalanceData] = useState(null);
  const [selectedDateDiv, setSelectedDateDive] = useState({
    today: true,
    yesterday: false,
    week: false
  });
  const [toggleDateSelection, setToggleDateSelection] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState(null);
  const [startDate, setStartDate] = useState(new Date());

  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    setCurrencySymbol(props.currencyAction.symbol);

    getSummaryRecords(new Date(), new Date());
  }, []);

  const getSummaryRecords = async (startDate, endDate) => {
    setLoader(true);
    const getData = await fetchSummary(
      startDate,
      endDate,
      props.customerListAction.businessId
    );
    if (getData) {
      if (getData.errors) {
        setLoader(false);
        notify.show(getData.errors.message, "error");
      } else {
        setTrialBalanceData(getData);
        setLoader(false);
      }
    }
  };
  const gettodaydate = () => {
    const today = moment(new Date()).toString();
    // document.getElementById("todaydiv").className = "date-selected";
    setSelectedDateDive({ today: true, yesterday: false, week: false });
    setStartDate(today);
    setEndDate(today);
    getSummaryRecords(today, today);
  };
  const getYesterDayDate = () => {
    setSelectedDateDive({ today: false, yesterday: true, week: false });
    const yesterday = moment(new Date())
      .subtract(1, "Days")
      .toString();
    setStartDate(yesterday);
    setEndDate(yesterday);
    // document.getElementById("yesterdaydiv").className = "date-selected";
    getSummaryRecords(yesterday, yesterday);
  };
  const getWeekDate = () => {
    setSelectedDateDive({ today: false, yesterday: false, week: true });
    var startOfWeek = moment()
      .startOf("isoWeek")
      .toString();
    var endOfWeek = moment()
      .endOf("isoWeek")
      .toString();
    setStartDate(startOfWeek);
    setEndDate(endOfWeek);
    // document.getElementById("weekdiv").className = "date-selected";
    getSummaryRecords(startOfWeek, endOfWeek);
  };
  const StartdateInput = React.forwardRef(({ value, onClick }, ref) => (
    <label onClick={onClick} ref={ref} style={{ cursor: "pointer" }}>
      {value}
      <i className="fa fa-calendar ml-2 text-muted"></i>
    </label>
  ));
  const EnddateInput = React.forwardRef(({ value, onClick }, ref) => (
    <label onClick={onClick} ref={ref} style={{ cursor: "pointer" }}>
      {value}
      <i className="fa fa-calendar ml-2 text-muted"></i>
    </label>
  ));
  const saveFile = async blob => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "TinyLedgersummary.PDF";
    a.click();
    window.URL.revokeObjectURL(a.href);
  };
  const downloadPDF = async () => {
    setDownloadLoader(true);
    const summaryResponse = await fetchSummaryToPDF(
      startDate.toString(),
      endDate.toString(),
      props.customerListAction.businessId
    );
    if (summaryResponse.error) {
	console.log(summaryResponse,"errororrr");
    } else {
      // with blob genrate pdf
      const blob = new Blob([summaryResponse], { type: "application/pdf" });
	console.log(blob,"blobbbbbb");
      saveFile(blob);
      setDownloadLoader(false);
    }
  };
  return (
    <div>
      <div className="container">
        {/* <Notifications /> */}
        <ReportWrapper>
          <Notifications />
          <div className="row">
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
              <Loadmodal loader={isDownloadLoader} />
              <div className="">
                <section>
                  <nav
                    style={{
                      marginBottom: 10
                    }}
                    className="custom-breadcumb"
                    aria-label="breadcrumb"
                  >
                    <ol className="breadcrumb">
                      <li className="breadcumb-color breadcrumb-item">
                        <a
                          id={32}
                          style={{
                            color: "gray",
                            cursor: "pointer"
                          }}
                          onMouseEnter={() => {
                            document.getElementById(32).style.textDecoration =
                              "underline";
                          }}
                          onMouseLeave={() => {
                            document.getElementById(32).style.textDecoration =
                              "none";
                          }}
                          onClick={() => props.history.goBack()}
                        >
                          {/* {props.SelectCustomerAction?.customer.name}'s */}
                          {props.location.state &&
                          props.location.state.page &&
                          props.location.state.page == "account" ? (
                            <IntlMessages id="sidebar.Account" />
                          ) : (
                            <IntlMessages id="sidebar.Home" />
                          )}
                        </a>
                      </li>
                      <li
                        className="active breadcrumb-item"
                        style={{
                          color: "#0073e6",
                          wordWrap: "break-word"
                        }}
                        aria-current="page"
                      >
                        <span>
                          <IntlMessages id="Trial balance" />
                        </span>
                      </li>
                    </ol>
                  </nav>
                </section>

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12  ">
                  {toggleDateSelection && toggleDateSelection ? (
                    <div
                      className={
                        "row   mb-15 roe-card-style pa-5 roe-shadow-2 whitelight"
                      }
                    >
                      <div
                        className={"col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-2"}
                      >
                        <div className="pa-2">
                          <label style={{ marginRight: 10 }}>
                            <IntlMessages id="Start date" />:
                          </label>
                          <DatePicker
                            placeholder="Start date"
                            selectsStart
                            // className="custom-datepicker"
                            dateFormat="dd/MM/yyyy"
                            selected={
                              startDate ? new Date(startDate) : new Date()
                            }
                            onChange={date => setStartDate(date)}
                            startDate={new Date(startDate)}
                            endDate={
                              endDate ? new Date(endDate) : new Date(startDate)
                            }
                            customInput={<StartdateInput />}
                          />
                        </div>
                      </div>
                      <div
                        className={
                          "col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-2  "
                        }
                      >
                        <div className="pa-2">
                          <label style={{ marginRight: 10 }}>
                            <IntlMessages id="End date" />: :
                          </label>
                          <DatePicker
                            selectsEnd
                            // className=""
                            dateFormat="dd/MM/yyyy"
                            placeholder="End date"
                            selected={endDate ? new Date(endDate) : new Date()}
                            onChange={date => setEndDate(date)}
                            startDate={new Date(startDate)}
                            endDate={
                              endDate ? new Date(endDate) : new Date(startDate)
                            }
                            minDate={new Date(startDate)}
                            customInput={<EnddateInput />}
                          />
                        </div>
                      </div>
                      <div
                        className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mtb-3 text-center mt-2"
                        align="center"
                      >
                        <button
                          className=" button-show"
                          onClick={() => {
                            getSummaryRecords(startDate, endDate);
                          }}
                        >
                          <IntlMessages id="Show" />
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className={"row mb-10 "}>
                      <div
                        className={`col-lg-4 col-md-4 col-sm-4 col-xs-4  ${
                          selectedDateDiv.today
                            ? "date-selected"
                            : "border-solid"
                        } text-center`}
                        id="todaydiv"
                        onClick={() => gettodaydate()}
                      >
                        <span>
                          <IntlMessages id="Today" />
                        </span>
                      </div>
                      <div
                        className={`col-lg-4 col-md-4 col-sm-4 col-xs-4  ${
                          selectedDateDiv.yesterday
                            ? "date-selected"
                            : "border-solid"
                        } text-center`}
                        id="yesterdaydiv"
                        onClick={() => getYesterDayDate()}
                      >
                        <span>
                          <IntlMessages id="Yesterday" />
                        </span>
                      </div>
                      <div
                        className={`col-lg-4 col-md-4 col-sm-4 col-xs-4  ${
                          selectedDateDiv.week
                            ? "date-selected"
                            : "border-solid"
                        } text-center`}
                        id="weekdiv"
                        onClick={() => getWeekDate()}
                      >
                        <span>
                          {" "}
                          <IntlMessages id="This Week" />
                        </span>
                      </div>
                    </div>
                  )}
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center "
                    style={{
                      marginBottom: 7
                    }}
                  >
                    <span className="text-muted ">
                      {toggleDateSelection ? (
                        <>
                          <IntlMessages id="Select from existing options" />{" "}
                          {" : "}
                        </>
                      ) : (
                        <>
                          {" "}
                          <IntlMessages id="Select date range" />
                          {" : "}
                        </>
                      )}
                      <i
                        className="fa fa-calendar"
                        onClick={() =>
                          setToggleDateSelection(!toggleDateSelection)
                        }
                        style={{ cursor: "pointer" }}
                        title="click to select date range"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div className="text-center  mtb-10 flex-x">
                <Button
                  className="c-btn ma-5 no-border c-outline-report"
                  dataStyle="expand-right"
                  onClick={() => {
                    setTodayLoader(!todayloader);
                    setYesterDayLoader(false);
                    setThisWeekLoader(false);
                    getSummaryRecords(
                      gettodaydate().toString(),
                      gettodaydate().toString()
                    );
                  }}
                >
                  TODAY
                </Button>{" "}
                {todayloader && <Loader height={22} width={20} />}
                <Button
                  className="c-btn ma-5 no-border c-outline-report"
                  dataStyle="expand-right"
                  onClick={() => {
                    setTodayLoader(false);
                    setYesterDayLoader(true);
                    setThisWeekLoader(false);
                    
                    getSummaryRecords(getYesterDayDate(), getYesterDayDate());
                  }}
                >
                  YESTERDAY
                </Button>{" "}
                {yesterdayloader && <Loader height={22} width={20} />}
                <Button
                  className="c-btn ma-5 no-border c-outline-report"
                  dataStyle="expand-right"
                  onClick={() => {
                    setTodayLoader(false);
                    setYesterDayLoader(false);
                    setThisWeekLoader(true);
                    var date = getWeekDate();
                    getSummaryRecords(date.startOfWeek, date.endOfWeek);
                  }}
                >
                  THIS WEEK
                </Button>{" "}
                {thisweekloader && <Loader height={22} width={20} />}
              </div> */}
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />
            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
              <div className="roe-card-style pa-5 roe-shadow-2 whitelight mb-10">
                <div className="roe-card-header">
                  {" "}
                  <IntlMessages id="Trial balance" />
                </div>
                <div className="roe-card-body">
                  {loader ? (
                    <Loader />
                  ) : (
                    <>
                      {trialBalanceData &&
                      trialBalanceData?.allTransaction?.length > 0 ? (
                        <Table striped borderless responsive>
                          <thead
                            style={{
                              backgroundColor: "#c1d6e8"
                            }}
                          >
                            <tr>
                              <th>
                                {" "}
                                <IntlMessages id="Account" />
                              </th>
                              <th>
                                {" "}
                                <IntlMessages id="Received" />
                              </th>
                              <th>
                                {" "}
                                <IntlMessages id="Paid" />
                              </th>
                              <th>
                                <IntlMessages id="Balance" />
                              </th>
                            </tr>
                          </thead>
                          <tbody
                            style={{
                              overflow: "scroll",
                              overflowX: "scroll",
                              height: "100px"
                            }}
                          >
                            {trialBalanceData &&
                              trialBalanceData.newData &&
                              trialBalanceData.newData.map((data, i) => {
                                return (
                                  <tr key={i * 5522}>
                                    <th scope="row">
                                      {data.account.toLocaleString()}
                                    </th>
                                    <td
                                      style={{
                                        color: "green"
                                      }}
                                    >
                                      <i className={currencySymbol} />
                                      {/* {data.taken.toLocaleString()} */}
                                      {amountFormatter(data.taken)}
                                    </td>
                                    <td
                                      style={{
                                        color: "red",
                                        width: "7rem"
                                      }}
                                    >
                                      <i className={currencySymbol} />
                                      {/* {data.given.toLocaleString()} */}
                                      {amountFormatter(data.given)}
                                    </td>
                                    <td
                                      style={{
                                        color:
                                          Number(data.total) < 0
                                            ? "red"
                                            : "green",
                                        width: "7rem"
                                      }}
                                    >
                                      <i className={currencySymbol} />

                                      {amountFormatter(data.total)}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                          <tfoot
                            style={{
                              backgroundColor: "#c1d6e8"
                            }}
                          >
                            <tr>
                              <th>
                                <IntlMessages id="Total" />
                              </th>
                              <td
                                style={{
                                  color: "green"
                                }}
                              >
                                {trialBalanceData &&
                                trialBalanceData.totalCreditAmount ? (
                                  <>
                                    <i className={currencySymbol} />
                                    {amountFormatter(
                                      trialBalanceData.totalCreditAmount
                                    )}
                                    {/* {trialBalanceData.totalCreditAmount.toLocaleString()} */}
                                  </>
                                ) : (
                                  <>
                                    <i className={currencySymbol} />
                                    {"0"}
                                  </>
                                )}
                              </td>
                              <td
                                style={{
                                  color: "red"
                                }}
                              >
                                {trialBalanceData &&
                                trialBalanceData.totalPayableAmount ? (
                                  <>
                                    <i className={currencySymbol} />
                                    {/* {trialBalanceData.totalPayableAmount.toLocaleString()} */}
                                    {amountFormatter(
                                      trialBalanceData.totalPayableAmount
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <i className={currencySymbol} />
                                    {"0"}
                                  </>
                                )}
                              </td>
                              <td
                                style={{
                                  color:
                                    calculateTotalAmount(
                                      trialBalanceData.totalCreditAmount,
                                      trialBalanceData.totalPayableAmount
                                    ) < 0
                                      ? "red"
                                      : "green"
                                }}
                              >
                                {trialBalanceData &&
                                trialBalanceData.netBalance ? (
                                  <>
                                    <i className={currencySymbol} />
                                    {/* {trialBalanceData.netBalance.toLocaleString()} */}
                                    {amountFormatter(
                                      trialBalanceData.netBalance
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <i className={currencySymbol} />
                                    {"0"}
                                  </>
                                )}
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      ) : (
                        <div className="text-muted text-center pa-25">
                          <IntlMessages id="No transaction" />
                        </div>
                      )}
                    </>
                  )}
                </div>
                <div className="roe-footer">
                  <button
                    className="btn btn-button  btn-md card sticky-bottom-right roe-card-style"
                    style={{
                      height: 38,
                      width: 159,
                      boxShadow:
                        "0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%) !important",
                      position: "fixed",
                      textAlign: "center",
                      alignItems: "center",
                      bottom: 30,
                      left: 0,
                      right: 180,
                      margin: "auto",
                      backgroundColor: "#0073e6",
                      color: "#ffff",
                      border: "1px solid",
                      borderRadius: "8px",
                      display: "block"
                    }}
                    onClick={() => downloadPDF()}
                  >
                    {/* <i
              className="fa fa-plus pt-0 pr-1"
              // style={{
              //   padding: " 6px 2px",
              // }}
            /> */}
                    <IntlMessages id="Download PDF" />
                  </button>
                  <button
                    className="btn btn-button btn-md card  sticky-bottom-right roe-card-style"
                    style={{
                      height: 38,
                      width: 170,
                      textAlign: "center",
                      alignItems: "center",
                      boxShadow:
                        "0 0 0 0 rgb(90 113 208 / 11%), 0 4px 16px 0 rgb(167 175 183 / 33%) !important",
                      position: "fixed",
                      bottom: 30,
                      right: 0,
                      margin: "auto",
                      left: 168,
                      backgroundColor: "#0073e6",
                      color: "#ffff",
                      border: "1px solid",
                      borderRadius: "8px",
                      display: "block"
                    }}
                    onClick={() => {
                      props.history.push("/detailed-statement", {
                        trialBalanceData: trialBalanceData,
                        startDate,
                        endDate,
                        page: props.location.state.page
                      });
                    }}
                  >
                    <IntlMessages id="Detailed  statement" />
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />
          </div>
        </ReportWrapper>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default compose(connect(mapStateToProps, null))(ViewReport);
