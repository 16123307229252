import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { amountFormatter } from "helper/methods";
import ShimmerLoader from "components/Customer/shimmer/ShimmerLoader";
import Loader from "components/loader/Loader";
import IntlMessages from "util/intlMessages";
import {
  getCollections,
  getExportCollection
} from "helper/services/accountServics";
import intlMessages from "util/intlMessages";
const Collections = props => {
  const [collectionData, setCollectionData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [isloader, setIsLoader] = useState(false);
  useEffect(() => {
    fetchCollections();
  }, []);
  const saveFile = async blob => {
    const a = document.createElement("a");
    a.href = URL.createObjectURL(blob);
    a.download = "TenyLedgerCollection.PDF";
    a.click();
    window.URL.revokeObjectURL(a.href);
  };

  const fetchCollections = async () => {
    setLoader(true);
    const collectionResponse = await getCollections(
      props.customerListAction.businessId
    );
    if (collectionResponse) {
      if (collectionResponse.collection) {
        setLoader(false);
        setCollectionData(collectionResponse.collection);
      }
      if (collectionResponse.errors) {
      }
    }
  };

  const DownloadCollection = async () => {
    setIsLoader(true);
    const collection = await getExportCollection(
      props.customerListAction.businessId
    );
    if (collection) {
      if (collection.errors) {
      } else {
        // var a = document.createElement("a"); //Create <a>
        // a.href = "data:PDF;base64," + collection.collection; //Image Base64 Goes here
        // a.download = "TenyLedgerCollection.PDF"; //File name Here
        // a.click(); //Downloaded file
        const blob = new Blob([collection], { type: "application/pdf" });
        saveFile(blob);
        setIsLoader(false);
      }
    }
  };
  function onHoverStyle(div) {
    document.getElementById(div).style.background = "#f5f5f5";
    // document.getElementById(div).style.cursor = "pointer";
    // e.target.style.background = "#f5f5f5";
  }
  function onMouseLeave(div) {
    document.getElementById(div).style.background = "#fff";
    // e.target.style.background = "#fff";
  }

  const profileImage = {
    borderRadius: "50%",
    border: "1 solid",
    width: 42,
    background: "#0073e6",
    height: 41,
    objectFit: "fill",
    textAlign: "center"
  };
  const InCap = {
    lineHeight: 2,
    fontSize: 20,
    color: "white",
    textAlign: "center"
  };
  return (
    <div>
      <div className="container">
        {/* <Notifications /> */}
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />

          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
            <section className="mt-2">
              <nav
                style={{
                  marginBottom: 10
                }}
                className="custom-breadcumb"
                aria-label="breadcrumb"
              >
                <ol className="breadcrumb">
                  <li className="breadcumb-color breadcrumb-item">
                    <a
                      id={32}
                      style={{
                        color: "gray",
                        cursor: "pointer"
                      }}
                      onMouseEnter={() => {
                        document.getElementById(32).style.textDecoration =
                          "underline";
                      }}
                      onMouseLeave={() => {
                        document.getElementById(32).style.textDecoration =
                          "none";
                      }}
                      onClick={() => props.history.goBack()}
                    >
                      {/* {props.SelectCustomerAction?.customer.name}'s */}
                      <IntlMessages id="sidebar.Account" />
                    </a>
                  </li>
                  <li
                    className="active breadcrumb-item"
                    style={{
                      color: "#0073e6",
                      wordWrap: "break-word"
                    }}
                    aria-current="page"
                  >
                    <span>
                      <IntlMessages id="Collections" />
                    </span>
                  </li>
                </ol>
              </nav>
            </section>
            <div className="roe-card-style roe-shadow-2 whitelight mb-10">
              <div className="roe-card-header">
                <div className="row">
                  <div className="col-lg-11 col-md-11 col-sm-11 col-xs-12">
                    {" "}
                    <IntlMessages id="Collections" />
                  </div>
                  <div className="col-lg-1 col-md-1 col-sm-1 col-xs-12">
                    {collectionData && collectionData.length > 0 ? (
                      isloader && isloader ? (
                        <Loader height="25px" width="20px" />
                      ) : (
                        <i
                          className="fa fa-download"
                          onClick={() => DownloadCollection()}
                        ></i>
                      )
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="roe-card-body">
                <div>
                  <p className="text-muted text-left">
                    {collectionData ? collectionData.length : ""}{" "}
                    <IntlMessages id="Collections" />
                  </p>
                  {loader ? (
                    <ShimmerLoader />
                  ) : (
                    collectionData &&
                    collectionData.map((collection, i) => {
                      return (
                        <div
                          // style={onMouseHover ? onHoverStyle : {}}
                          className="flex-x ptb-15 mb-6 border-bottom "
                          key={i}
                          id={`divqw${i}`}
                          onMouseEnter={() => onHoverStyle(`divqw${i}`)}
                          onMouseLeave={() => onMouseLeave(`divqw${i}`)}
                        >
                          {collection.customer.customerMeta.length > 0 ? (
                            <div>
                              <img
                                style={{
                                  borderRadius: "50%",
                                  // border: "1px solid ",
                                  width: 42,
                                  height: 41,
                                  objectFit: "contain"
                                }}
                                src={collection.customer.customerMeta.map(
                                  url => {
                                    return url.thumbnail;
                                  }
                                )}
                                alt="profile"
                              />
                            </div>
                          ) : (
                            <div style={profileImage}>
                              <span style={InCap}>
                                {collection.customer.name
                                  .charAt(0)
                                  .toUpperCase()}
                              </span>
                            </div>
                          )}
                          {/* <img className="img-sm" src={profile} alt="profile" /> */}
                          <div
                            className=" pb-8 mlr-10 flex-1"
                            style={{ textAlign: "left" }}
                          >
                            <p
                              className=" fs-15 demi-flex-start "
                              style={{ wordWrap: "break-word" }}
                              // style={{ marginTop: 8 }}
                            >
                              {collection.customer.name}
                            </p>
                            <small
                              className="fs-12 text-left"
                              // key={moment(new Date(customer.createdAt)).fromNow()}
                            >
                              {collection.customer.phoneNumber}
                            </small>
                          </div>
                          <div>
                            <div style={{ display: "grid" }} key={i}>
                              <small
                                style={{
                                  color:
                                    collection.balance &&
                                    collection.balance.length > 0
                                      ? "green"
                                      : "red",
                                  fontSize: 16,
                                  textAlign: "center"
                                }}
                                key={collection.id}
                              >
                                <i className={props.currencyAction.symbol}></i>
                                {amountFormatter(collection.balance)}
                                {collection.balance &&
                                collection.balance.length > 0 ? (
                                  " "
                                ) : (
                                  <>
                                    {" "}
                                    <IntlMessages id="Due" />
                                  </>
                                )}
                              </small>
                              <small></small>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    ...state
  };
};

export default compose(connect(mapStateToProps, null))(Collections);
