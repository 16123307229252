const SelectCustomerAction = {
  setSelectedCustomer: "SET_SELECTED_CUSTOMER",
  unsetselectedCustomer: "UNSET_SELECTED_CUSTOMER",

  selectCustomer: (data) => {
    return {
      type: SelectCustomerAction.setSelectedCustomer,
      customer: data.customer,
    };
  },
  removecustomer: () => {
    return {
      type: SelectCustomerAction.unsetselectedCustomer,
      customer: null,
    };
  },
};

export default SelectCustomerAction;
