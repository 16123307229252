import React from "react";
import {
  StockSectionWrapper,
  StockInfoWrapper
} from "./transactionheader.style";

const TransactionHeader = ({
  section2,
  currency,
  SelectCustomerAction,
  customerProfile
}) => {
  return (
    <StockInfoWrapper className=" roe-card-style roe-shadow-2 whitelight pa-25 flex">
      <UserProfile
        currency={currency}
        SelectCustomerAction={SelectCustomerAction}
        customerProfile={customerProfile}
      />
      <div className="vertical-hr" />
      <StockSection {...section2} currency={currency} amountColor="green" />
    </StockInfoWrapper>
  );
};

const UserProfile = ({ SelectCustomerAction, customerProfile }) => (
  <StockSectionWrapper className="stock-section">
    <div className="section-container">
      <div className="section-3">
        <div
          className=" text-center"
          style={{
            cursor: "pointer"
          }}
          onClick={() => customerProfile()}
        >
          {SelectCustomerAction.customer.customerMeta.length > 0 ? (
            <>
              <img
                className="profile-image"
                src={
                  SelectCustomerAction.customer &&
                  SelectCustomerAction.customer.customerMeta.map(url => {
                    return url.url;
                  })
                }
                alt="profile"
              />
            </>
          ) : (
            <span
              className="inline-flex"
              style={{
                marginTop: 4
              }}
            >
              <span className="profile">
                <span className="inCap">
                  {SelectCustomerAction.customer.name.charAt(0).toUpperCase()}
                </span>
              </span>
              <span
                style={{
                  lineHeight: 2,
                  paddingLeft: 5
                }}
              ></span>
            </span>
          )}
          <span> {SelectCustomerAction.customer.name}</span>{" "}
        </div>
      </div>
    </div>
  </StockSectionWrapper>
);

const StockSection = ({
  title,
  amount,
  description,
  direction,
  percent,
  amountColor,
  currency,
  amountInNumber
}) => (
  <StockSectionWrapper className="stock-section">
    <div className="section-container">
      {amount && (
        <div className="section-3">
          <p
            className="fs-17 text-center"
            style={{ color: Number(amountInNumber) < 0 ? "red" : "green" }}
          >
            <i className={currency} />
            <span className="pl-1">{amount.toString().replace("-", "")}</span>
          </p>
          <p className="fs-17 text-center">{title}</p>
          {/* <p className="grey--text">{description}</p> */}
        </div>
      )}
    </div>
  </StockSectionWrapper>
);

export default TransactionHeader;
