import singleTransactionAction from "./actions";

const initState = {
  transaction: null,
};

export default function rootReducer(state = initState, action) {
  switch (action.type) {
    case singleTransactionAction.SET_SINGLE_TRANSACTION:
      return {
        ...state,
        transaction: action.transaction,
        parentId: action.parentId,
      };
    case singleTransactionAction.UNSET_SINGLE_TRANSACTION:
      return {
        ...state,
        transaction: null,
        parentId: null,
      };
    default:
      return state;
  }
}
