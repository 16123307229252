import React, { Component, useState, useEffect } from "react";
import { connect } from "react-redux";
import AccountList from "../components/Account/AccountList";
import ListCurrency from "../components/Settings/ListCurrency";
import LanguageModal from "../components/Settings/Language/Language";
import RemindersList from "../components/Settings/Reminders/RemindersList";
import IntlMessages from "util/intlMessages";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { ListGroup, ListGroupItem } from "reactstrap";
import {
  fetchBusinessList as getBusinessList,
  newBusiness,
} from "../helper/services/businessService";
import { selected, Notselected } from "../helper/constant";
import AlertError from "../components/Customer/error/AlertError";
import Loader from "../components/loader/Loader";
import Notifications, { notify } from "react-notify-toast";
import { getCustomerList } from "helper/services/customerService";
const Account = (props) => {
  const [totalCustomers, setTotalCustomer] = useState(null);
  const [customerslist, setCustomersList] = useState(null);
  useEffect(() => {
    fetchCustomerList();
  }, []);
  const fetchCustomerList = async () => {
    try {
      const responseCustomer = await getCustomerList(
        props.customerListAction.businessId
      );
      if (responseCustomer) {
        if (responseCustomer.customers) {
          setCustomersList(responseCustomer.customers);
          setTotalCustomer(responseCustomer.customers.length);
        }
        if (responseCustomer.errors) {
        }
      }
    } catch (e) {}
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12" />
          <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12 ">
            <div className="roe-card-style roe-shadow-2 whitelight">
              <div className="roe-card-header pb-0">
                <IntlMessages id="sidebar.Account" />
              </div>
              <div className="roe-card-body pb-0">
                {/* settings listings */}
                <AccountList
                  totalCustomers={totalCustomers}
                  customerList={customerslist}
                  // toggleCurrencyModal={toggleCurrencyModal}
                  // currency={currency}
                  // language={language}
                  // currencySymbol={currencySymbol}
                  // setCurrencySymbol={setCurrencySymbol}
                  // reminders={remindersModal}
                  // toggleRemindersModal={toggleRemindersModal}
                  // toggleLanguageModal={toggleLanguageModal}
                  {...props}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12"></div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps, null)(Account);
