import handleError from "./handleErrors";
import axios from "axios";
import {
  BASE_URL,
  collection,
  summary,
  backup,
  exportCollection
} from "./apiConstants";
import getHeader from "./getHeaders";
import handleResponse from "./handleResponse";

export function getCollections(id) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };

  return fetch(BASE_URL + collection + id, requestOptions)
    .then(handleResponse)
    .then(response => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return response;
    });
}

export function getBalance(id) {
  const requestOptions = {
    method: "GET",
    headers: getHeader()
  };

  return fetch(BASE_URL + summary + id, requestOptions)
    .then(handleResponse)
    .then(response => {
      // store user details and jwt token in local storage to keep user logged in between page refreshes

      //props.login
      return response;
    });
}
export async function generateBackup(id) {
  // const requestOptions = {
  //   method: "GET",
  //   headers: getHeader(),
  // };

  // return fetch(BASE_URL + backup + id, requestOptions)
  //   .then(handleResponse)
  //   .then((response) => {
  //     // store user details and jwt token in local storage to keep user logged in between page refreshes

  //     //props.login
  //     return response;
  //   });
  let api = BASE_URL + backup + id;
  const response = await axios.get(api, {
    headers: getHeader(),
    responseType: "arraybuffer"
  });
  return response?.data;
}

export async function getExportCollection(id) {
  // const requestOptions = {
  //   method: "GET",
  //   headers: getHeader(),
  // };
  let api = BASE_URL + exportCollection + id;
  const response = await axios.get(api, {
    headers: getHeader(),
    responseType: "arraybuffer"
  });
  return response.data;
  // return fetch(BASE_URL + exportCollection + id, requestOptions)
  //   .then(handleResponse)
  //   .then((response) => {
  //     // store user details and jwt token in local storage to keep user logged in between page refreshes

  //     //props.login
  //     return response;
  //   });
}
